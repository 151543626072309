import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am5geodata_indiaLow from "../../components/Charts/IndiaMapJSON/india.json";
// import "./Funnel.css";

am4core.useTheme(am4themes_animated);

// const Chart = (props) => {
//   const data1 = props.data;
//   //   const data = [
//   //     {
//   //       code: "IN-TG",
//   //       value: 2850,
//   //     },
//   //     {
//   //       code: "IN-KA",
//   //       value: 5000,
//   //     },
//   //     {
//   //       code: "IN-KL",
//   //       value: 200,
//   //     },
//   //     {
//   //       code: "IN-AP",
//   //       value: 12000,
//   //     },
//   //     {
//   //       code: "IN-TN",
//   //       value: 6700,
//   //     },
//   //   ];
//   console.log(data1, "........................");

//   useEffect(() => {
//     let chart = am4core.create("chartdiv", am4maps.MapChart);

//     try {
//       chart.geodata = am5geodata_indiaLow;
//     } catch (e) {
//       chart.raiseCriticalError(
//         new Error(
//           'Map geodata could not be loaded. Please download the latest <a href="https://www.amcharts.com/download/download-v4/">amcharts geodata</a> and extract its contents into the same directory as your amCharts files.'
//         )
//       );
//     }

//     chart.projection = new am4maps.projections.Miller();

//     // let title = chart.chartContainer.createChild(am4core.Label);
//     // title.text = "Life expectancy in the World";
//     // title.fontSize = 20;
//     // title.paddingTop = 30;
//     // title.align = "center";

//     let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
//     let polygonTemplate = polygonSeries.mapPolygons.template;
//     polygonTemplate.tooltipText = "{name}: {value.value}";
//     polygonTemplate.fill = am4core.color("#D3D3D3");
//     polygonSeries.useGeodata = true;
//     polygonSeries.heatRules.push({
//       property: "fill",
//       target: polygonSeries.mapPolygons.template,
//       min: am4core.color("#fcb92c"),
//       max: am4core.color("#ff7c00"),
//     });

//     // add heat legend
//     let heatLegend = chart.chartContainer.createChild(am4maps.HeatLegend);
//     heatLegend.valign = "bottom";
//     heatLegend.series = polygonSeries;
//     heatLegend.width = am4core.percent(100);
//     heatLegend.orientation = "horizontal";
//     heatLegend.padding(30, 30, -10, 30);
//     heatLegend.valueAxis.renderer.labels.template.fontSize = 10;
//     heatLegend.valueAxis.renderer.minGridDistance = 40;
//     heatLegend.baseColor = am4core.color("#ffffff");

//     polygonSeries.mapPolygons.template.events.on("over", (event) => {
//       handleHover(event.target);
//     });

//     polygonSeries.mapPolygons.template.events.on("hit", (event) => {
//       handleHover(event.target);
//     });

//     function handleHover(mapPolygon) {
//       if (!isNaN(mapPolygon.dataItem.value)) {
//         heatLegend.valueAxis.showTooltipAt(mapPolygon.dataItem.value);
//       } else {
//         heatLegend.valueAxis.hideTooltip();
//       }
//     }

//     polygonSeries.mapPolygons.template.events.on("out", (event) => {
//       heatLegend.valueAxis.hideTooltip();
//     });

//     // life expectancy data
//     polygonSeries.data = data1;

//     // excludes Antarctica

//     return () => {
//       if (data1) {
//         chart.dispose();
//       }
//     };
//   }, [data1]);

//   return <div id="chartdiv" style={{ width: "100%", height: "350px" }}></div>;
// };

// export default Chart;

// import React, { useEffect } from "react";
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4maps from "@amcharts/amcharts4/maps";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const IndiaMap = ({ data }) => {
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.geodata = am5geodata_indiaLow;
    chart.projection = new am4maps.projections.Miller();

    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.data = data;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";

    // Set default fill color to grey for states with no value
    polygonTemplate.fill = am4core.color("#D3D3D3");

    // Set fill colors based on the value of the state
    polygonSeries.heatRules.push({
      property: "value",
      target: polygonTemplate,
      min: am4core.color("#D3D3D3"),
      max: am4core.color("#0000FF"),
    });

    let polygonSeriesTemplate = polygonSeries.mapPolygons.template;
    polygonSeriesTemplate.stroke = am4core.color("#FFFFFF");
    polygonSeriesTemplate.strokeOpacity = 0.5;
    polygonSeriesTemplate.fillOpacity = 0.8;

    chart.zoomControl = new am4maps.ZoomControl();

    let hoverState = polygonTemplate.states.create("hover");
    hoverState.properties.fill = am4core.color("#FF0000");

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
};

export default IndiaMap;

// import React, { useEffect, useRef } from "react";
// import * as am5 from "@amcharts/amcharts5";
// import * as am5map from "@amcharts/amcharts5/map";
// import am5geodata_indiaLow from "../../components/Charts/IndiaMapJSON/india.json";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// const Chart = (props) => {
//   const Data = props.data?.map((item) => {
//     return {
//       id: item.code,
//       value: item.value,
//     };
//   });
//   console.log(Data, "........................");
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const root = am5.Root.new(chartRef.current);

//     root.setThemes([am5themes_Animated.new(root)]);

//     const chart = root.container.children.push(
//       am5map.MapChart.new(root, {
//         panX: "rotateX",
//         panY: "none",
//         projection: am5map.geoAlbersUsa(),
//         layout: root.horizontalLayout,
//       })
//     );

//     const polygonSeries = chart.series.push(
//       am5map.MapPolygonSeries.new(root, {
//         geoJSON: am5geodata_indiaLow,
//         valueField: "value",
//         calculateAggregates: true,
//       })
//     );

//     polygonSeries.mapPolygons.template.setAll({
//       tooltipText: "{name}: {value}",
//     });

//     polygonSeries.set("heatRules", [
//       {
//         target: polygonSeries.mapPolygons.template,
//         dataField: "value",
//         min: am5.color(0xff621f),
//         max: am5.color(0x661f00),
//         key: "fill",
//       },
//     ]);

//     polygonSeries.mapPolygons.template.events.on("pointerover", function (ev) {
//       heatLegend.showValue(ev.target.dataItem.get("value"));
//     });

//     polygonSeries.data.setAll(Data);

//     const heatLegend = chart.children.push(
//       am5.HeatLegend.new(root, {
//         orientation: "vertical",
//         startColor: am5.color(0xff621f),
//         endColor: am5.color(0x661f00),
//         startText: "Lowest",
//         endText: "Highest",
//         stepCount: 5,
//       })
//     );

//     heatLegend.startLabel.setAll({
//       fontSize: 12,
//       fill: heatLegend.get("startColor"),
//     });

//     heatLegend.endLabel.setAll({
//       fontSize: 12,
//       fill: heatLegend.get("endColor"),
//     });

//     polygonSeries.events.on("datavalidated", function () {
//       heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
//       heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
//     });

//     return () => {
//       root.dispose();
//     };
//   }, [Data]);

//   return (
//     <div
//       id="chartdiv"
//       ref={chartRef}
//       style={{ width: "100%", height: "500px" }}
//     />
//   );
// };

// export default Chart;
