import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button } from "reactstrap";
import "./Master.css";

const Master = () => {
  const master = useSelector((state) => state.master.setList.body);
  const [zonealHead, setZonalHead] = useState([]);
  const [reginalHead, setRegionalHead] = useState([]);
  const [stateHead, setStateHead] = useState([]);
  const [salesOfficer, setSalesOfficer] = useState([]);

  const handleMaster = () => {
    if (master) {
      for (let i = 0; i < master.regional_mapping.length; i++) {
        if (master.regional_mapping[i].role_id === 19) {
          const list = master.regional_mapping[i].data.map(
            (item) => item.label
          );
          setRegionalHead(list);
        }
        if (master.regional_mapping[i].role_id === 18) {
          const list = master.regional_mapping[i].data.map(
            (item) => item.label
          );
          setZonalHead(list);
        }
        if (master.regional_mapping[i].role_id === 16) {
          const list = master.regional_mapping[i].data.map(
            (item) => item.label
          );
          setStateHead(list);
        }
        if (master.regional_mapping[i].role_id === 22) {
          const list = master.regional_mapping[i].data.map(
            (item) => item.label
          );
          setSalesOfficer(list);
        }
      }
    }
  };

  useEffect(() => {
    if (master === undefined) {
      setZonalHead([]);
      setRegionalHead([]);
      setStateHead([]);
      setSalesOfficer([]);
    } else {
      handleMaster();
    }
  }, [master]);

  return (
    <div className="container-fluid">
      <div className="row pt-3">
        {zonealHead.length !== 0 ? (
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div>
              <Label>Zonal Head</Label>
              <div className="w-auto">
                {zonealHead.map((item) => (
                  <button className="btn btn-outline-warning p-1 m-1">
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {reginalHead.length !== 0 ? (
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div>
              <Label>Regional Head</Label>
              <div className="w-auto">
                {reginalHead.map((item) => (
                  <button className="btn btn-outline-warning p-1 m-1">
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {stateHead.length !== 0 ? (
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div>
              <Label>State Head</Label>
              <div className="w-auto">
                {stateHead.map((item) => (
                  <button className="btn btn-outline-warning p-1 m-1">
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {salesOfficer.length !== 0 ? (
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div>
              <Label>Sales Officer</Label>
              <div className="w-auto">
                {salesOfficer.map((item) => (
                  <button className="btn btn-outline-warning p-1 m-1">
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Master;
