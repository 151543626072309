import ReactSpinner from "react-bootstrap-spinner";

import "bootstrap/dist/css/bootstrap.css";

export default function Spinner() {
  return (
    <div
      style={{ height: "200px" }}
      className="d-flex justify-content-center p-5 h-100"
    >
      <ReactSpinner type="grow" color="warning" size="5" />
    </div>
  );
}
export function SpinnerSmall() {
  return (
    <div className="d-flex justify-content-center p-2">
      <ReactSpinner type="grow" color="warning" size="1.3" />
    </div>
  );
}
