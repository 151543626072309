import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toggleRightSidebar } from "../store/actions";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import UserActivityTable from "../components/Table/UserActivityTable";
import { useDispatch, useSelector } from "react-redux";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import Service from "../services/activity.service";
import Pagination from "../components/Table/Pagination";
import Spinner from "../components/Loader/Spinner";
import Master from "../components/Master/Master";

const UserActivity = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role_id = user?.role_id;
  const user_id = user?.user_id;
  const master = useSelector((state) => state.master.setList.body);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenFirstmonth, setIsOpenFirstmonth] = useState(false);
  const [nameMobile, setNameMobile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const currentDate = new Date();
  // currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(initialDateRange);

  const body = {
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    role_id: role_id,
    name_mobile: nameMobile,
    offset: currentPage,
    limit: pageSize,
    user_id: user_id,
    regional_mapping: master?.regional_mapping,
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };
  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenFirstmonth);
  };
  const submitDates = async () => {
    handleDateRangeClose();
    setLoading(true);
    await Service.GetActivity(body).then((res) => {
      setData(res?.data);
      setLoading(false);
    });
  };

  const Reset = () => {
    setNameMobile("");
    setDateRange(initialDateRange);
    body.start_date = moment(initialStartDate).format("YYYY-MM-DD");
    body.end_date = moment(currentDate).format("YYYY-MM-DD");
    body.name_mobile = "";
    body.regional_mapping = master?.regional_mapping;
    submitDates();
  };
  function toggleRightbar() {
    props.toggleRightSidebar();
  }
  useEffect(() => {
    submitDates();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <CardTitle className="font-size-18 mb-3">User Activity</CardTitle>
          <Row>
            <Col md={12}></Col>

            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="mt-1">
                      <h6>Search</h6>
                      <Input
                        type="search"
                        placeholder="Phone no. / Name"
                        id="search_name_mobile"
                        value={nameMobile}
                        onChange={(e) => {
                          setNameMobile(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    {isOpenFirstmonth === false ? (
                      <div>
                        <label className="form-label">Select Dates: </label>
                        <button
                          className="form-select"
                          onClick={handleFirstMonthButtonState}
                        >
                          {`${new Date(body.start_date).toLocaleString(
                            "default",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )} - ${new Date(body.end_date).toLocaleString(
                            "default",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}`}
                        </button>
                      </div>
                    ) : (
                      <div className="customDateOverlay">
                        <label className="form-label">Select Dates: </label>
                        <div className="">
                          <DateRange
                            editableDateInputs={true}
                            // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                            onChange={handledateRange}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                            shouldCloseOnSelect={true}
                            maxDate={currentDate}
                            minDate={new Date(moment("2021-01-01"))}
                            onClose={handleDateRangeClose}
                            className="dateRangeWrapper"
                          />
                          <div>
                            <Button
                              className="btn-danger float-end mt-1"
                              onClick={handleFirstMonthButtonState}
                            >
                              Close
                            </Button>
                            <Button
                              className="btn-warning  margin-right mt-1 float-end"
                              onClick={() => submitDates()}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 w-auto">
                    {user.role_id !== 22 ? (
                      <div className="margin-top d-flex">
                        <button
                          color="none"
                          onClick={toggleRightbar}
                          // type="button"
                          className="advance-filter-btn"
                        >
                          Team Filter
                          <i className="ri-filter-2-fill mx-1"></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 ">
                    <div className="margin-top d-flex float-end ">
                      <Button
                        className="btn-warning  margin-right"
                        onClick={() => submitDates()}
                      >
                        Search
                      </Button>
                      <Button
                        className="btn btn-danger btn-secondary"
                        onClick={() => Reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Master />{" "}
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  {loading === true ? (
                    <Spinner />
                  ) : (
                    <>
                      <h4 className="card-title pt-4">
                        {`Redemption List ${moment(body.start_date).format(
                          "MMM DD, YYYY"
                        )} - ${moment(body.end_date).format("MMM DD, YYYY")}`}
                      </h4>
                      {/*  */}
                      <UserActivityTable data={data} loading={loading} />
                      <div className="d-flex justify-content-center ">
                        <Pagination
                          page={currentPage}
                          setPage={setCurrentPage}
                          totalPages={totalPages}
                        />
                      </div>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

// export default UserActivity;
const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(UserActivity)
);
