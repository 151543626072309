import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const GetEtransferList = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;
  // body.user_id = 1234567890;
  // body.role_id = 5;

  // console.log("get transfer  list=-=-=-=-=-=-=-=-=-=-=-=-", body);

  return await axiosApi
    .post(API_URL + "etransfer/view-etransfer", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      } else if (response.data.status === "fail") {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const exportETransfer = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "etransfer/export-view-etransfer", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const getDisplayStatus = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return await axiosApi
    .post(API_URL + "constant/dashboard-status-list", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data.data,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};

export default {
  GetEtransferList,
  exportETransfer,
  getDisplayStatus,
};
