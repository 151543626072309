import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  CardTitle,
  FormGroup,
  Input,
} from "reactstrap";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import './rewardsCatlogue.css';
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import "bootstrap/dist/css/bootstrap.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Service from '../../services/catalogue.service';
import CatalogueModal from './CatalogueModal';
import ReportModal from '../../components/Modal/ReportModal';
import DownloadModal from './DownloadModal';
import RewardsFetch from './RewardsFetch';
import { useSelector } from 'react-redux';


const Cataloue = ({ openModal }) => {

  const [totalCount, settotalCount] = useState(0);
  const [rewardType, setrewardType] = useState("");
  const [rewardName, setrewardName] = useState("");
  const [rewardCode, setrewardCode] = useState("");
  const [pointsRange, setpointsRange] = useState("");
  const [loading, setLoading] = useState(false);
  const [minPoints, setminPoints] = useState(null);
  const [maxPoints, setmaxPoints] = useState(null);
  const [resetCounter, setresetCounter] = useState(0);
  const [points, setpoints] = useState("");
  const [slabValue, setSlabValue] = useState("");
  const [pageData, setPagedata] = useState([]);
  const [totalPages, settotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [product, setProduct] = useState();
  const [disableDownload, setDisableDownload] = useState(false);
  const [modal, setModal] = useState(false);
  const [reportName, setReportName] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.user_id;
  const role = user?.role_id;
  const pageSize = 12;
  const toggleModalSec = async () => {
    setModal(!modal);
    setReportName("");
  };



  const toggleModal = (product) => {
    setProduct(product)
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    if(isModalOpen){
      setIsModalOpen(false);
    }
  }

  let currentPage = 1;

  // const programList = [
  //   { label: "Select Program", value: "" },
  //   { label: "RLP", value: "rlp" },
  //   { label: "ERLP", value: "erlp" },
  // ];

  const rewardTypeList = [
    { label: "Select Reward Type ", value: "" },
    { label: "Physical Gift", value: "DELIVER" },
    { label: "E-Voucher", value: "VOUCHER" },
  ];

  const pointsRangeMap = {
    "0 - 500": { min_points: 0, max_points: 500 },
    "501 - 1000": { min_points: 501, max_points: 1000 },
    "1001 - 10000": { min_points: 1001, max_points: 10000 },
    "10001 - 100000": { min_points: 10001, max_points: 100000 },
    "100001 - 1000000": { min_points: 100001, max_points: 1000000 },
    "1000001 - 1500000": { min_points: 1000001, max_points: 1500000 },
  };

  const pointsRangeList = [
    { label: "Select Points Range", value: "" },
    { label: "0 - 500", value: "0 - 500" },
    { label: "501 - 1000", value: "501 - 1000" },
    { label: "1001 - 10000", value: "1001 - 10000" },
    { label: "10001 - 100000", value: "10001 - 100000" },
    { label: "100001 - 1000000", value: "100001 - 1000000" },
    { label: "1000001 - 1500000", value: "1000001 - 1500000" },
  ];

  const slabList = [
    { label: "Select Slab", value: "" },
    { label: "SLAB_03", value: "SLAB_03" },
    { label: "SLAB_05", value: "SLAB_05" },
    { label: "SLAB_07", value: "SLAB_07" },
    { label: "SLAB_01", value: "SLAB_01" },
  ];

  const Search = () => {
    fetchPageData();
  };
  const Reset = async () => {
    
    setpointsRange("");
    setrewardType("");
    setrewardName("");
    setminPoints("");
    setmaxPoints("");
    setrewardCode("");
    setSlabValue("");
    setresetCounter((prev) => prev + 1);
  };

  useEffect(async () => {
    await fetchPageData();
  }, [resetCounter]);


  const fetchPageData = async () => {
    setLoading(true);
    // let pointsFilter = { min_points: 0, max_points: 1000000000 };
    let body = {
      ...(rewardName && { rewardname: rewardName }),
      
      ...(points && { points: points }),
      ...(rewardType && { type: rewardType }),
      ...(slabValue && { slab: slabValue }),
      ...(pointsRange && pointsRangeMap[pointsRange]),
      ...(rewardCode && { rewardcode: rewardCode?.trim() }),
      offset: currentPage,
      limit: 12,
    };
    Service.GetRewardList(body)
      .then((data) => {
        if (data?.data?.code == 200) {
          setPagedata(data?.data?.data);
          settotalPages(data?.data?.pagination?.totalPages);
          settotalCount(data?.data?.pagination?.totalCount);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          autoClose: 2000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    currentPage = 1;
  };


const handlePage = (event) => {
  const buttonElement = event.target; // Your event target
  if (buttonElement.tagName === "BUTTON") {
    const pageNumber = buttonElement.textContent;
    currentPage = pageNumber;
    fetchPageData();
  }
};

let bodyFilters = {
  user_id: user_id,
  role_id: role,
  rewardname: rewardName,
  points: points,
  type: rewardType,
  slab: slabValue,
  rewardcode: rewardCode?.trim(),
  report_name : reportName,
  // minipoint, max slab reward code reward name reward type
}

const handleReward = async () => {
  // setCountRefresh(false);
  setModal(false);
  setDisableDownload(true);
  toast.success("Preparing your file", {
    autoClose: 2000,
  });
  setReportName("");
  await RewardsFetch.exportRewards(bodyFilters).then((res) => {
    setTimeout(() => {
      if (res) {
        toast.success("File downloaded successfully", {
          autoClose: 2000,
        });
      } else {
        toast.error("Error downloading file", {
          autoClose: 2000,
        });
      }
    }, 2000);
  });
};


  return (
    <React.Fragment>
        <div className="page-content"  >
          <div className="container-fluid">
          <Row>
              <Col md={12} >
                <Card className="mb-3" >
                  <CardHeader>
                  <div className="row table_top">
                      <Col lg={4} md={4} sm={5} xs={6}>
                        <CardTitle className="font-size-18">
                          Rewards Catalogue
                        </CardTitle>
                      </Col>
                      <Col
                        lg={7}
                        md={7}
                        sm={6}
                        xs={5}
                        className="total-count-class"
                      >
                        <div className="d-flex justify-content-end">
                          <span style={{ float: "left", paddingRight: "5px" }}>
                            Total count:
                          </span>
                          {totalCount === null ? (
                            <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                          ) : (
                            totalCount
                          )}
          
                        </div>
                      </Col>
                      {/* {role === 16 || role === 22 ? null : ( */}
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModalSec}
                            disabled={disableDownload}
                          />{" "}
                          <DownloadModal
                            isOpen={modal}
                            toggle={toggleModalSec}
                            modalHeader="Rewards Report"
                            handleAPI={handleReward}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    {/* )} */}
                  </div>
                  <div>
                          
                  <Row className="mt-3">
                        {/* <Col md={2}>
                          <h6>Program</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={programType}
                              onChange={(e) => setprogramType(e.target.value)}
                            >
                              {programList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col md={3}>
                          <h6>Reward Type</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={rewardType}
                              onChange={(e) => setrewardType(e.target.value)}
                            >
                              {rewardTypeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <h6>Reward</h6>
                          <Input
                            type="search"
                            placeholder="Reward Name"
                            id="search_reward"
                            value={rewardName}
                            onChange={(e) => {
                              setrewardName(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <h6>Reward Code</h6>
                          <Input
                            type="search"
                            placeholder="Reward Code"
                            id="search_reward_code"
                            value={rewardCode}
                            onChange={(e) => {
                              setrewardCode(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <h6>Points Range</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={pointsRange}
                              onChange={(e) => {
                                setpointsRange(e.target.value);
                              }}
                            >
                              {pointsRangeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={12} className="mt-4">
                          <Button
                            className="btn btn-danger btn-secondary float-end"
                            onClick={() => {
                              Reset();
                            }}
                            style={{ width: "74px" }}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            className="btn-warning float-end mx-2"
                            onClick={() => {
                              Search();
                            }}
                            disabled={loading === true ? true : false}
                          >
                            Search
                          </Button>{" "}
                        </Col>
                      </Row>
                  </div>
                  </CardHeader>
                </Card>
              </Col>
          </Row>
          <Row>
          {isModalOpen && <CatalogueModal products={product} closeModal={closeModal} isOpen={isModalOpen} toggle={toggleModal} />}

            <Row className="mx-auto" style={{display: "flex",justifyContent: "space-between"}}>
            {/* <div className="row"> */}
            
                    {loading === true ? (
                        <Spinner />
                        
                      ) : (
              <>
              
              {
                pageData.map((products) => {
                  return (
                    <Col key={products.id} style={{cursor: 'pointer', width: '340px'}} className="card col-lg-4 col-md-6 col-12 " onClick={()=>toggleModal(products)}>
                      <div className="image-container mx-auto">
                        <img
                          src={products.image}
                          alt={products.rewardname}
                        />
                        </div>
                        <div>
                        <h2 className="card-title">{products.rewardname}</h2>
                        <label className="label">
                          Reward Code : {products.rewardcode}
                        </label>
                        <label className="label">
                          Program : {products.is_rlp === 1 ? "RLP" : "ERLP"}
                        </label>
                        <label className="label">
                          Reward Type : {products.reward_type}
                        </label>
                        <label className="label">Points : {products.points}</label>
                      </div>
                      <label className="label">Description : <span className="card-text card-text-scroll">
                        {products.description}
                      </span> </label>
                                           
                    </Col>
                  )
                })
              }
            </>
                      )
                    }
                      {/* </div> */}
          </Row>

          {totalCount === null ? (
                     <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                     ) : (
              <>
              {totalCount === 0 ? null : (
                <div className="d-flex justify-content-center ">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      hidePrevButton
                      hideNextButton
                      onClick={handlePage}
                    />
                  </Stack>
                </div>
              )}
              </>
                     )}
          </Row>
          </div>
        </div>
        {/* <CatalogueModal isOpen={isModalOpen} toggle={toggleModal} /> */}

    </React.Fragment>
  )
}

export default Cataloue