// import React, { useEffect, useRef, useState } from "react";
// import * as am5 from "@amcharts/amcharts5";
// import * as am5percent from "@amcharts/amcharts5/percent";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import Spinner from "../Loader/Spinner";

// const ChartComponent = (props) => {
//   const { data } = props;
//   const { loading } = props;
//   const [hoveredCategory, setHoveredCategory] = useState(null);

//   const categoryClassName = (category) => {
//     return category === hoveredCategory ? "hovered" : "";
//   };

//   const Series = Object.entries(data).map(([key, value]) => {
//     return {
//       // category: key,
//       category: `${
//         key === "download"
//           ? "Download"
//           : key === "registered"
//           ? "Registered"
//           : key === "doc_verified"
//           ? "Doc Verified"
//           : key === "fin_transfered"
//           ? "Fin Transfered"
//           : key === "dbt_verified"
//           ? "Dbt Verified"
//           : "Regular Transfered"
//       }`,
//       value: value,
//       legendLabelText: "{category}",
//       legendValueText: "{value}",
//     };
//   });
//   const chartRef = useRef(null);

//   useEffect(() => {
//     // Initialize amCharts

//     const root = am5.Root.new(chartRef.current);
//     root.setThemes(am5themes_Animated);

//     const chart = root.container.children.push(
//       am5percent.SlicedChart.new(root, {
//         layout: root.verticalLayout,
//         paddingTop: 40,
//       })
//     );

//     const series = chart.series.push(
//       am5percent.FunnelSeries.new(root, {
//         alignLabels: true,
//         orientation: "horizontal",
//         valueField: "value",
//         categoryField: "category",
//         pointerOrientation: "horizontal",
//         legendLabelText: "{category}",
//         legendValueText: "{value}",
//         labels: {
//           text:
//             ("text",
//             "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})"),
//           className: (label) => categoryClassName(label.dataItem.category),
//           events: {
//             onHover: (event) => {
//               const hoveredItem = event.target.dataItem;
//               setHoveredCategory(hoveredItem.category);
//             },
//             onHoverOut: () => {
//               setHoveredCategory(null);
//             },
//           },
//         },
//       })
//     );

//     series.data.setAll(Series);
//     series.appear();

//     const legend = chart.children.push(
//       am5.Legend.new(root, {
//         centerX: am5.p50,
//         x: am5.p50,
//         marginTop: 0,
//         marginBottom: 15,
//         paddingTop: 25,
//         pointerOrientation: "horizontal",
//         legendValueText: "({value})",
//         legendLabelText: {
//           text:
//             ("text",
//             "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})"),
//           className: (label) => categoryClassName(label.dataItem.category),
//           events: {
//             onHover: (event) => {
//               const hoveredItem = event.target.dataItem;
//               setHoveredCategory(hoveredItem.category);
//             },
//             onHoverOut: () => {
//               setHoveredCategory(null);
//             },
//           },
//         },
//       })
//     );

//     legend.data.setAll(series.dataItems);

//     chart.appear(1000, 100);

//     return () => {
//       if (root) {
//         root.dispose();
//       }
//     };
//   }, []);

//   return (
//     <div
//       id="chartdiv"
//       style={{ width: "100%", height: "500px", marginTop: "20px" }}
//       ref={chartRef}
//     ></div>
//   );
// };

// export default ChartComponent;

import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const ChartComponent = (props) => {
  const { data } = props;
  const chartRef = useRef(null);
  const Series = Object.entries(data).map(([key, value]) => {
    return {
      // category: key,
      name: `${
        key === "download"
          ? "Download"
          : key === "registered"
          ? "Registered"
          : key === "doc_verified"
          ? "Doc Verified"
          : key === "fin_transfered"
          ? "Fin Transfered"
          : key === "dbt_verified"
          ? "Dbt Verified"
          : key === "doc_submitted"
          ? "Doc Submitted"
          : "Regular Transfered"
      }`,
      value: value,
    };
  });
  useEffect(() => {
    const chart = am4core.create(chartRef.current, am4charts.SlicedChart);

    chart.data = Series;

    let series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.step = 2;
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.alignLabels = true;
    series.orientation = "horizontal";
    series.bottomRatio = 1;
    // series.labelsContainer.width = 200;

    series.labelsContainer.height = 200;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    // chart.legend.margin(5, 5, 20, 50);
    // chart.legend.labels.template.maxWidth = 95;
    // chart.legend.labels.template.truncate = true;
    // chart.legend.paddingBottom = 200;
    // chart.legend.contentAlign = "middle";
    // chart.legend.fontSize = 12;
    // chart.legend.itemContainers.template.paddingTop = 260;

    // chart.legend.parent = chart.chartContainer;
    // chart.legend.contentAlign = "middle";
    // chart.appear(1000, 500);
    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <div
      id="chartdiv"
      style={{
        width: "100%",
        height: "550px",
        marginTop: "20px",
        // margin: "20px",
        // marginBottom: "20px",
      }}
      ref={chartRef}
    ></div>
  );
};

export default ChartComponent;
