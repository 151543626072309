import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card, Input } from "reactstrap";
import profile from "../assets/images/users/profile.png";
import profileService from "../services/profile.service";
import "./profile.css";

const Profile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("");
  const [state, setState] = useState([]);

  const FetchUserProfile = async () => {
    const user = await profileService.GetUserProfile();
    setName(user.data.name);
    setEmail(user.data.email_id);
    setMobile(user.data.mobile);
    setState(user.data.stateName);
    setRole(user.data.role);
  };
  useEffect(() => {
    FetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <Row>
              <Col lg={4}>
                <div className="d-flex justify-content-center m-5">
                  <img
                    className="rounded-circle me-1"
                    src={profile}
                    alt="Header Avatar"
                    height={200}
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="d-flex m-5 flex-column">
                  <div className="d-flex w-100">
                    <div className="pt-3 w-100 margin-right">
                      <label>Name </label>
                      <Input value={name} disabled />
                    </div>
                    <div className="pt-3 w-100">
                      <label>Role</label>
                      <Input value={role} disabled />
                    </div>
                  </div>
                  <div className="d-flex w-100">
                    <div className="pt-3 w-100 margin-right">
                      <label>Email</label>
                      <Input value={email} disabled />
                    </div>

                    <div className="pt-3 w-100">
                      <label>Mobile</label>
                      <Input value={mobile} disabled />
                    </div>
                  </div>

                  <div className="pt-3">
                    <label>State: </label>
                    <div className="form-control bg-body">
                      {state.join(", ")}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
