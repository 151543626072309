import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Button } from "reactstrap";

//Import Breadcrumb
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import getSegment from "../../services/segment.service";
import "./category.css";

import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Loader/Spinner";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import SegmentCards from "../../components/Card/SegmentCards";

const Segment = () => {
  const [data, setData] = useState([]);
  const loading = useSelector((state) => state.loading.loading);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(moment().startOf("month")),
      endDate: new Date(moment()),
      key: "selection",
    },
  ]);
  const [segment, setSegment] = useState("DEO");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", Label: "Select" },
  ]);

  // eslint-disable-next-line no-unused-vars

  let body = {
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    segment: segment,
  };

  const handleSegment = (event) => {
    setSegment(event.target.value);
    // body.segment = JSON.stringify(event.target.value);
  };

  const handleDateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const handleSubmit = async () => {
    dispatch(showLoader());
    const segment_data = await getSegment.GetMLPTOP(body);
    setData(segment_data.data);
    dispatch(hideLoader());
  };

  const Reset = () => {
    body.start_date = new Date(moment().startOf("month"));
    body.end_date = new Date(moment());
    body.segment = "DEO";
    handleSubmit();
  };

  const handleButtonState = () => {
    setIsOpen(!isOpen);
  };

  const GetSegmentList = async () => {
    const options = await getSegment.GetSegmentList();
    if (options) {
      setSegmentOption(options.data);
    }
  };

  useEffect(() => {
    GetSegmentList();
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <div className="mb-4 mt-3">
          {isOpen === false ? (
            <div>
              <Button
                className="border btn w-50 btn-warning"
                onClick={handleButtonState}
              >
                Select 1st Month Date Range
              </Button>
            </div>
          ) : (
            <div>
              <DateRange
                editableDateInputs={true}
                onChange={handleDateRange}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                shouldCloseOnSelect={true}
                maxDate={new Date()}
              />
              <Button className="btn-danger" onClick={handleButtonState}>
                Close
              </Button>
            </div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Select Segment: </label>
          <select
            class="form-select w-50"
            aria-label="Default select example"
            value={segment}
            onChange={handleSegment}
          >
            {segmentOption.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3">
          <div className="float-lg-end mb-3">
            <Button className="btn-warning mx-3" onClick={() => handleSubmit()}>
              Search
            </Button>
            <Button
              className="btn btn-danger btn-secondary"
              onClick={() => Reset()}
            >
              Reset
            </Button>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <h4 className="card-title pt-4">Segment Data</h4>

        {/* {loading === true ? <Spinner /> : <SegmentTable data={data} />} */}
        {loading === true ? <Spinner /> : <SegmentCards data={data} />}
      </CardBody>
    </Card>
  );
};

export default Segment;
