import React, { Component } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import auth from "../../../services/auth.service";

//i18n
import { withNamespaces } from "react-i18next";

// users
import profile from "../../../assets/images/users/profile.png";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  handleLogout() {
    auth.logout();
  }
  render() {
    let user_name = "";
    let email = "";
    let role = "";
    let state = "";
    if (localStorage.getItem("user")) {
      const obj = JSON.parse(localStorage.getItem("user"));
      // const uNm = obj.email.split("@")[0];
      // username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
      user_name = obj.name;
      email = obj.email;
      role = obj.role;
      state = obj.state;
    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <div className="d-flex flex-row">
              <img
                className="rounded-circle header-profile-user me-1"
                src={profile}
                alt="Header Avatar"
                // height="60px"
              />
              <div className="d-flex flex-column">
                <span className="d-none d-xl-inline-block ms-1 text-transform text-info font-size-14 float-start">
                  {role}
                </span>
                <div className="d-flex flex-row">
                  <span className="d-none d-xl-inline-block ms-1 text-transform ">
                    {user_name}
                  </span>
                  <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                </div>
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="/profile">
              <i className="ri-user-line align-middle me-1"></i> {"Profile"}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.tog_standard} className="text-danger">
              <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Row>
          <Col sm={6} md={4} xl={3} className="mt-4">
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                Do you want to logout?
              </ModalHeader>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={this.tog_standard}
                  color="light"
                  className="waves-effect"
                >
                  No
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light btn-warning"
                  onClick={this.handleLogout}
                  href="/Login"
                >
                  Yes
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
