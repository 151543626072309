import axios from "axios";
import { API_URL } from "../Config/config";
import { useHistory } from "react-router-dom";
import authService from "./auth.service";
// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const GetProgramOverview = async (body) => {
  // let history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/program-overview", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }

      // return {
      //   status: {
      //     code: err?.response?.status,
      //     message: err?.response?.data?.message,
      //   },
      // };
    });
};

const GetProgramOverview1 = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/program-overview-section", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);

      // return {
      //   status: false,
      // };
    });
};

const GetCashOverview = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/cash-overview", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: {
      //     code: err.response.status,
      //     message: err.response.data.message,
      //   },
      // };
    });
};

const GetEarningOverview = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/earning", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: {
      //     code: err?.response?.status,
      //     message: err?.response?.data?.message,
      //   },
      // };
    });
};

const GetEarningOverviewCount = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/earning-overview", body)
    .then((response) => {
      if (response?.status === 200) {
        return { status: true, data: response?.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetProgramOverviewData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/data", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};
const GetRegularCashTransfer = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/cash", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetRedemptionOverviewDataCount = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/redeemption-overview", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetRedemptionOverviewData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/redeemption", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetRegistrationData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "dashboard/rlp/registration-overview", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

export default {
  GetProgramOverview,
  GetRegularCashTransfer,
  GetProgramOverviewData,
  GetCashOverview,
  GetRedemptionOverviewDataCount,
  GetRedemptionOverviewData,
  GetRegistrationData,
  GetEarningOverviewCount,
  GetEarningOverview,
  GetProgramOverview1,
};
