import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import CustomModal from "../Modal/CustomModal";
import getSegment from "../../services/segment.service";
import moment from "moment";

const ViewElectricianTable = ({ data = [] }) => {
  console.log(data, "//////////////////////");
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);

  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };
  const fetchProfile = async (userId) => {
    const user_id = userId;
    setUserId(user_id);
    const params = { user_id: user_id };
    const response = await getSegment.GetUserDetails(params);

    if (response?.status === true) {
      setModalData(response.data);
    }
  };
  // Map each object in the data array to a table row
  const rows = data?.map((item, index) => (
    <tr key={index}>
    <td>
      <i
        className="fas fa-external-link-alt icon-table"
        onClick={async () => {
          await fetchProfile(item.user_id);
          profileToggleModal();
        }}
        title="View More"
      />
    </td>

    <td>{item.app_status ? item.app_status : "N/A"}</td>
    <td>{item.state ? item.state : "N/A"}</td>
    <td>{item.district ? item.district : "N/A"}</td>
    <td>{item.city ? item.city : "N/A"}</td>
    <td>
      {item.first_name || item.last_name
        ? item.first_name + " " + item.last_name
        : "N/A"}
    </td>
    <td>{item.mobile ? item.mobile : "N/A"}</td>
    <td align="right">
      {item.points_summary ? item.points_summary.total_cash : "N/A"}
    </td>
    <td align="right">
      {item.points_summary ? item.points_summary.balance_cash : "N/A"}
    </td>
    <td align="right">
      {item.points_summary ? item.points_summary.burn_cash : "N/A"}
    </td>
    <td>
      {item.status1 ? item.status1.dashboard_display_status : "Pending"}
    </td>
    <td>
      {item.status2 ? item.status2.dashboard_display_status : "Pending"}
    </td>
    <td>
      {item.dbt_reg_date
        ? moment.unix(item.dbt_reg_date).format("DD-MM-YYYY")
        : "N/A"}
    </td>
    <td>{item.bank_name ? item.bank_name : "N/A"}</td>
    <td>{item.account_holder_name ? item.account_holder_name : "N/A"}</td>
    <td>{item.account_number ? item.account_number : "N/A"}</td>
    <td>{item.ifsc ? item.ifsc : "N/A"}</td>

    {/* Add more cells for each property in the object */}
  </tr>
  ));
  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
              <th></th>
                <th>App Status</th>
                <th>State</th>
                <th>District</th>
                <th>City</th>
                <th>Electrician Name</th>
                <th>Phone No</th>
                <th>Earned Cash</th>
                <th>Balance Cash</th>
                <th>Used Cash</th>
                <th>KYC Status</th>
                <th>DBT Status</th>
                <th>App Reg Date</th>
                <th>Bank Name</th>
                <th>Account Holder Name</th>
                <th>Account Number</th>
                <th>IFSC Code</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="12" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3}>
          <CustomModal
            isOpen={profileModal}
            toggle={profileToggleModal}
            user_id={userId}
            onConfirm={() => console.log("Confirmed!")}
            confirmLabel="Confirm"
            data={modalData}
          >
          </CustomModal>
        </Col>
      </Row>
    </>
  );
};

export default ViewElectricianTable;
