import React, { useState } from 'react';
import {API_URL} from '../../Config/config';


function UploadTable({data = []}) {

return (
  <>
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr className="bg-yellow-500 text-black">
            <th>Name</th>
            <th>Type</th>
            <th>Status</th>
            <th>Total</th>
            <th>Success</th>
            <th>Failed</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.length == 0 ? (
            <>
              <tr>
                <td colSpan={7} style={{textAlign:'center'}}>No Data Found</td>
              </tr>
            </>
          ) : (
            data.map((item, index) => (
              <tr key={item._id}>
                <td>{item.file_name}</td>
                <td>{item.type}</td>
                <td>{item.status}</td>
                {/* <td>{item.total > 0 ? 
                    <a
                        href={item.path ? (API_URL + item.path) : ''}
                        download={item.file_name}
                        target="_blank"
                        rel="noreferrer"
                    >{item.total}</a> : 
                    item.total
                    }
                </td> */}
                <td>{item.total}</td>
                <td>{item.success}</td>
                <td>{item.fail}</td>
                <td>{new Date(item.creation_date).toLocaleDateString()}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  </>
);
}

export default UploadTable;