import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { withNamespaces } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import Select from "react-select";
import "./Electrician.css";
import ViewMechanicTable from "../../components/Table/ViewElectricianTable";
import Service from "../../services/Electrician.service";
import ReportModal from "../../components/Modal/ReportModal";
import viewTransactionService from "../../services/viewTransaction.service";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange, DateRangePicker } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import ViewElectricianTable from "../../components/Table/ViewElectricianTable";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import Stack from "@mui/material/Stack";
// import Master from "../../components/Master/Master";

const ViewElectrician = () => {
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [docStatus, setDocStatus] = useState("");
  const [nameMobile, setNameMobile] = useState("");
  const [acNumber, setACNumber] = useState("");
  const [reportName, setReportName] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(moment(new Date('2023-10-01')).toDate()),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const currentDate = new Date(); // current date
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialStartDate = moment(new Date('2023-10-01')).toDate();
  const [kycNumber, setKycNumber] = useState("");
  const [appStatus, setAppStatus] = useState("");
  const initialDateRange = [
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });
  const [appOptions, setAppOptions] = useState([
    { value: "", label: "Select App Status" },
    { value: "app_register_user", label: "App Register User" },
    // { value: "NO", label: "Non-App User" },
    { value: "active_user", label: "Active User" },
    { value: "inactive_user", label: "Inactive User" },
    // { value: "total_enrolled", label: "Total Enrolled" },
    { value: "app_download_user", label: "Total Download" },
  ]);
  const [documentOptions, setDocumentOptions] = useState([
    { value: "", label: "Select Document Status" },
    { value: "partial_doc", label: "Partial Document" },
    { value: "both_doc", label: "Document Submited" },
    { value: "verification_pending", label: "Verification Pending" },
    { value: "escalated_doc", label: "Escalated" },
    { value: "dbt_verified", label: "DBT Verified" },
    { value: "doc_verified", label: "Document Verified" },
  ]);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  //   const master = useSelector((state) => state.master.setList.body);
  const user_id = user?.user_id;
  const role = user?.role_id;
  const loading = useSelector((state) => state.loading.loading);

  // Modified Body data
  let bodyFilters = {
    role_id: role,
    user_id: user_id,
    // regional_mapping: master?.regional_mapping,
    limit: pageSize,
    offset: currentPage,
    document_filter: docStatus.value,
    app_filter: appStatus.value,
    mobile: nameMobile,
    acc_number: acNumber,
    state_id: state?.value,
    district_id: district?.value,
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
  };
  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      bodyFilters.mobile = nameMobile;
    }
  }
  if (appStatus.length > 0) {
    bodyFilters.app_filter = appStatus;
  }
  if (state.length > 0) {
    bodyFilters.state_id = stateId;
  }
  if (district.length > 0) {
    bodyFilters.district_id = districtId;
  }
  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    bodyFilters.start_date = dateFilters.start_date;
    bodyFilters.end_date = dateFilters.end_date;
  }
  if (docStatus.length > 0) {
    bodyFilters.document_filter = docStatus;
  }
  const toggleModal = async () => {
    setModal(!modal);
    setReportName("");
  };
  const submit = async () => {
    fetchData();
  };

  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };
  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };

  const fetchData = async () => {
    setCurrentPage(1);
    dispatch(showLoader());
    const response = await Service.GetElectricianList(bodyFilters);
    console.log(response, "electrician");
    if (response && response.status === true) {
      setData(response.data.data);
      setTotalCount(response.data.count);
      setTotalPages(response.data.pagination.totalPages);
      dispatch(hideLoader());
    } else {
      setData([]);
      setTotalCount([]);
      setTotalPages([]);
      dispatch(hideLoader());
    }
  };

  const handleReset = async () => {
    setDocStatus("");
    setAppStatus("app_register_user");
    setNameMobile("");
    setDistrict("");
    setDistrictId([]);
    setDistrictOptions([]);
    setState("");
    setstateOptions([]);
    setStateId([]);
    setACNumber("");
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    bodyFilters = {
      limit: pageSize,
      offset: currentPage,
    };
    bodyFilters.app_filter = "app_register_user";
    setDateRange(initialDateRange);
    fetchData();
  };
  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };
  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };
  const [modal, setModal] = useState(false);
  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };
  const submitDates = async () => {
    // setLoading(true);
    setIsOpenFirstmonth(false);
    fetchData();
    // setLoading(false);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await viewTransactionService.getDistricts(params);
      // if (districtData.status === true) {
      //   setData(transactionList.data.data);
      // } else if (transactionList.status === false) {
      //   setData([]);
      // }
      if (districtData.status === true) {
        const options = districtData.data.map((district) => ({
          district_id: district.id,
          value: district.name,
          label: district.name,
        }));
        setDistrictOptions(options);
      } else if (districtData.status === false) {
        toast.error("No district found.", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };
  const handleExportMechanic = async () => {
    // setCountRefresh(false);
    setModal(false);
    setDisableDownload(true);
    // toast.success("Preparing your file", {
    //   autoClose: 2000,
    // });
    bodyFilters.report_name = reportName;
    setReportName("");
    await viewTransactionService.exportViewMechanics(bodyFilters).then((res) => {
      if (res.code === 200) {
        setDisableDownload(false);
        toast.success(res.message, {
          autoClose: 2000,
        });
      } else {
        setDisableDownload(false);
        toast.error(res.message, {
          autoClose: 2000,
        });
      }
    });
  };
  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        View Electrician
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 16 || role === 22 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />{" "}
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Electrician Report"
                            handleAPI={handleExportMechanic}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <Row>
                  </Row>
                  <Row>
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="appStatus">App Status</Label>
                        <select
                            className="form-select"
                            value={appStatus}
                            onChange={(e) => setAppStatus(e.target.value)}
                          >
                            {appOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="docStatus">Document Status</Label>
                        <select
                            className="form-select"
                            value={docStatus}
                            onChange={(e) => setDocStatus(e.target.value)}
                          >
                            {documentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                      </FormGroup>
                    </Col>

                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="nameMobile">Name/Mobile</Label>
                        <Input
                          type="text"
                          id="nameMobile"
                          name="nameMobile"
                          value={nameMobile}
                          onChange={(e) => setNameMobile(e.target.value)}
                          placeholder="Enter Name/Mobile"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="acNumber">AC Number</Label>
                        <Input
                          type="text"
                          id="acNumber"
                          name="acNumber"
                          value={acNumber}
                          onChange={(e) => setACNumber(e.target.value)}
                          placeholder="Enter AC Number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col sm="6" md="6" xl="3">
                    <h6>State Name</h6>
                    <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={state}
                            onFocusCapture={fetchGetStates}
                            onChange={handleStateChanges}
                          >
                            <option value="">Select State</option>
                            {stateOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                    </Col>
                    <Col sm="6" md="6" xl="3" >
                    <h6>District Name</h6>
                      <FormGroup>
                      <select
                            className="form-select"
                            value={district}
                            onFocusCapture={fetchGetDistricts}
                            onChange={handleDistrictChanges}
                          >
                            <option value="">Select District</option>
                            {districtOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" xl="3">
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Dates</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {dateFilters.start_date && dateFilters.end_date
                                ? `${new Date(
                                dateFilters.start_date
                              ).toLocaleString("default", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })} - ${new Date(
                                dateFilters.end_date
                              ).toLocaleString("default", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}` : "select date range" }
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2023-10-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end me-2 mt-1"
                                onClick={submitDates}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                  </Row>
                  <Row className="float-end">
                  <Col lg={3} className="w-100">
                  <Button
                        className="btn btn-danger btn-secondary float-end"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        className="btn-warning float-end mx-2"
                        onClick={fetchData}
                      >
                        Submit
                      </Button>
                    </Col>
                      </Row>
                </CardHeader>
                <CardBody>
                <div className="container-fluid">
                    <div className="row">
                    {loading === true ? (
                        <Spinner />
                      ) : (
                        <ViewElectricianTable data={data} loading={loading} />
                      )}
                  {totalCount === null ? (
                     <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                     ) : (
                       <>
                    {totalCount === 0 ? null : (
                            <div className="d-flex justify-content-center ">
                              <Stack spacing={2}>
                                <Pagination
                                  count={totalPages}
                                  hidePrevButton
                                  hideNextButton
                                  onClick={handlePage}
                                />
                              </Stack>
                            </div>
                          )}
                    </>
                    )}
                  </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewElectrician;
