import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import "../../pages/ViewTransaction/ViewTransaction.css";
import CustomModal from "../Modal/CustomModal";
import CustomModalRlp from "../Modal/CustomModalRlp";
import viewTransactionService from "../../services/viewTransaction.service";
import getSegment from "../../services/segment.service";

const ViewTransactionTable = ({ data = [], userType }) => {
  // const data = props.data;
  const [modal, setModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);
  const [prevSlab, setPrevSlab] = useState("");
  const [currentSlab, setCurrentSlab] = useState("");

  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };
  const fetchProfile = async (userId) => {
    const user_id = userId;
    setUserId(user_id);
    const params = { user_id: user_id };
    let response = null;
    if (userType === "mlp") {
      response = await getSegment.GetUserDetails(params);
    }
    if (userType === "rlp") {
      response = await getSegment.GetRlpUserDetails(params);
    }

    if (response.status === true) {
      setModalData(response.data);
    }
    // await getSegment.GetSlab(params).then((res) => {
    //   if (res?.status === true) {
    //     setCurrentSlab(res.data.data.curr_fin_year.class);
    //     setPrevSlab(res.data.data.last_fin_year.class);
    //   }
    // });
  };

  // Map each object in the data array to a table row
  const rows = data?.map((item, index) => (
    <tr key={index}>
        <td>
          <i
            className="fas fa-external-link-alt icon-table"
            onClick={async () => {
              await fetchProfile(item.user_id);
              profileToggleModal();
            }}
            title="View More"
          />
        </td>
      <td>{item.code_type}</td>
      <td>{item.segment_name ? item.segment_name : "N/A"}</td>
      <td>{item.product ? item.product : "N/A"}</td>
      {/* <td align="right">{item.pack_size ? item.pack_size : "N/A"}</td> */}
      {/* <td>{item.series_name}</td> */}
      {/* {userType == "mlp" ? <td align="right">{item?.sap_code || 'N/A'}</td> : null} */}
      {/* <td align="right">{item.points}</td> */}
      {userType == "rlp" ? <td align="right">{item.points}</td> : null}
      {userType == "mlp" ? <td align="right">{item.cash}</td> : null}
      {/* <td align="right">{item.cash}</td> */}
      <td>{item.mechname}</td>
      <td>{item.phone_no}</td>
      <td>{item.code}</td>
      <th>{item.pincode}</th>
      <th>{item.state}</th>
      <th>{item.district}</th>
      <td>{item.creation_date_of_points}</td>
      {/* Add more cells for each property in the object */}
    </tr>
  ));

  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
                <th></th>
                <th>Code Type</th>
                <th>Segment</th>
                <th>Product</th>
                {/* <th>Pack Size</th> */}
                {/* <th>Series</th> */}
                {/* {userType == "mlp" ? <th>SAP Code</th> : null} */}
                {userType == "rlp" ? <th>Points</th> : null}
                {userType == "mlp" ? <th>Cash</th> : null}
                {/* <th>Cash</th> */}
                <th>Name</th>
                <th>Phone No</th>
                <th>Code</th>
                <th>Pincode</th>
                <th>State</th>
                <th>District</th>
                <th>Date Added</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3}>
          {userType == "rlp" ? (
            <CustomModalRlp
              isOpen={profileModal}
              toggle={profileToggleModal}
              user_id={userId}
              onConfirm={() => console.log("Confirmed!")}
              confirmLabel="Confirm"
              data={modalData}
            >
              {/* <p>Modal Content Here</p> */}
            </CustomModalRlp>
          ) : null}
          {userType == "mlp" ? (
            <CustomModal
              isOpen={profileModal}
              toggle={profileToggleModal}
              user_id={userId}
              onConfirm={() => console.log("Confirmed!")}
              confirmLabel="Confirm"
              data={modalData}
              // prevSlab={prevSlab}
              // currentSlab={currentSlab}
            >
              {/* <p>Modal Content Here</p> */}
            </CustomModal>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default ViewTransactionTable;
