import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";
const CABLE_URL = "https://cableapi.aparpower.com/api/";

//
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const GetTransactionList = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "transaction/view_transactions", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      } else if (response.data.status === "fail") {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const exportViewTransaction = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "transaction/export_view_transactions", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      if (error.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const getStates = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let body = {
    active_parent_id: user.user_id,
  };
  return await axiosApi
    .post(API_URL + "state/getAllStates", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getDistricts = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  console.log(body, ".............");
  body = {
    ...body,
    user_id: user.user_id,
  };
  console.log(body, ".............");
  return await axiosApi
    .post(API_URL + "district/getAllDistrict", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getSegments = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .get(API_URL + "constant/segmentlist", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data.data,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const getTransInfoByUserID = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .post(API_URL + "constant/viewTransById", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data.data,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const exportViewMechanics = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "electrician/export-view-electrician", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const exportViewRetailer = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "electrician/export-view-retailor", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const uploadFile = async (file) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;

  if (!token) {
    console.error('No token found!');
    return;
  }

  axios.defaults.headers.common['Authorization'] = `Bearer SKIP`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('file_name', file.name);

  console.log("file,name", file.name)
  formData.append('user_id', user.user_id.toString());
  console.log("name", user.user_id.toString())
  formData.append('role_id', user.role_id);
  console.log("api----------",API_URL)
  return await axios
  .post(CABLE_URL + 'users/upload-image', formData)
  .then(response => {
    console.log("resposne",response);
    console.log("resposne---------------",response.data);

    if(response.status == '200') {
      return {
        status: true,
        message: response.data.message,
        code: response.data.code,
        data: response.data
      }
    }
  })
    .catch((error) => {
      if (error?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = '/login';
      } 
      
      if (error.response?.data?.status === 'fail') {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code
        };
      }
      console.log("error", error);
      return {
        status: false,
        message: error?.response?.data?.message,
        error
      };
    });
  }

export default {
  GetTransactionList,
  exportViewTransaction,
  getStates,
  getDistricts,
  getSegments,
  getTransInfoByUserID,
  exportViewMechanics,
  exportViewRetailer,
  uploadFile
};
