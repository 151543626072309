import React from "react";
import { Pie } from "react-chartjs-2";
import { CardTitle } from "reactstrap";
import Spinner from "../Loader/Spinner";

const PieChart = ({ data = [] }) => {
  const backgroundColor = [
    { "AN090CL01": "#f982c6" },
    { "SH090CL01": "#f47c75" },
  ];

  const Data = {
    labels: data?.trade,
    datasets: [
      {
        data: data?.count,
        backgroundColor: backgroundColor
          .filter((item) => data?.trade?.includes(Object.keys(item)[0]))
          .map((item) => Object.values(item)[0]),
        hoverBackgroundColor: backgroundColor
          .filter((item) => data?.trade?.includes(Object.keys(item)[0]))
          .map((item) => Object.values(item)[0]),
        hoverBorderColor: "#fff",
      },
    ],
  };

  const noData = {
    labels: ["No Data"],
    datasets: [
      {
        data: [360],
        backgroundColor: ["#e5e5e5"],
        // hoverBorderColor: "#fff",
      },
    ],
  };

  return (
    <React.Fragment>
      {!data ? (
        <Spinner />
      ) : data?.trade?.length === 0 ? (
        <div style={{ height: "313px" }}>
          <Pie width={474} height={260} data={noData} />
        </div>
      ) : (
        <Pie width={474} height={260} data={Data} />
      )}
    </React.Fragment>
  );
};

export default PieChart;
