import { Link } from "@mui/material";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

// import logo from "../assets/images/eni-login2.png";
import logo from "../../assets/images/eni-login2.png";
import Service from "../../services/support.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set } from "lodash";

const Support = () => {
  const [userName, serUserName] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleNameChange = (event) => {
    const { value } = event.target;
    const regex = /^[A-Za-z]+$/; // regular expression to accept only characters
    if (value === "" || regex.test(value)) {
      serUserName(value);
    } else {
      serUserName("");
    }
  };

  function handleNamePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(text)) {
      serUserName(text);
    }
  }
  function handleMobilePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const regex = /^\d{10}$/;
    if (regex.test(text)) {
      setMobileNumber(text);
    }
  }
  const handleNamePress = (event) => {
    if (event.target.value.length > 50) {
    }

    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  };

  const handleMobilePress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const handleMobileChange = (event) => {
    const value = event?.target.value;
    if (value) setMobileNumber(value);
  };

  const handleEmailChange = (event) => {
    const value = event?.target.value;
    if (value) setEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      user_mobile: mobileNumber,
      user_name: userName,
      user_email: email,
      user_request_data: message,
    };
    console.log("data", data);
    // dispatch(addUser(data));
    // history.push("/users");
    if (
      data.user_name !== "" ||
      data.user_mobile !== "" ||
      data.user_email !== "" ||
      data.user_request_data !== ""
    ) {
      await Service.submitSupport(data)

        .then((response) => {
          console.log("response", response);
          if (response?.code === 200) {
            // setSuccessMessage(response.data.message);
            // setErrorMessage("");
            // setSuccess(true);
            // setFailure(false);
            // setLoader(false);
            serUserName("");
            setMobileNumber("");
            setEmail("");
            setMessage("");

            toast.success("Your Query has been submitted successfully");
            window.location.reload();
          } else {
            // setSuccessMessage("");
            // setErrorMessage(response.data.message);
            // setSuccess(false);
            // setFailure(true);
            // setLoader(false);
            toast.error("Something went wrong");
            //   window.location.reload();
          }
        })
        .catch((error) => {
          console.log("error", error);
          // setSuccessMessage("");
          // setErrorMessage(error.response.data.message);
          // setSuccess(false);
          // setFailure(true);
          toast.error("Something went wrong");
          // window.location.reload();
        });
    } else {
      toast.error("Please fill all the fields");
    }

    // setLoader(false);
    // setSuccess(false);
    // setFailure(false);
    // setSuccessMessage("");
    // setErrorMessage("");
    // setUserName("");
    // setMobileNumber("");
    // setEmail("");
    // setMessage("");
    // history.push("/login");
    // window.location.reload();
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          {/* <Row className="g-0">
            <Col lg={12}> */}
          <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div>
                    <Card>
                      <CardBody>
                        <div className="text-center">
                          <div>
                            <Link to="" className="logo">
                              <img src={logo} height="160" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            Welcome to Support Section !
                          </h4>
                          <p className="text-muted">
                            Please Submit your Query.
                          </p>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-4">
                            {" "}
                            <Label>
                              Name{" "}
                              <span className="required text-danger">*</span>
                            </Label>
                            <AvForm className="">
                              <FormGroup className="">
                                <AvField
                                  name="userName"
                                  // label="Name"
                                  type="text"
                                  value={userName}
                                  placeholder="Enter Name"
                                  onChange={handleNameChange}
                                  onKeyPress={handleNamePress}
                                  onPaste={handleNamePaste}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Name is required",
                                    },
                                  }}
                                />
                              </FormGroup>
                            </AvForm>
                          </div>
                          <div className="col-md-4">
                            <Label>
                              Mobile Number{" "}
                              <span className="required text-danger">*</span>
                            </Label>
                            <AvForm className="">
                              <FormGroup className="">
                                <AvForm>
                                  <AvField
                                    name={"mobileNumber"}
                                    //   label="Mobile Number"
                                    type="text"
                                    value={mobileNumber}
                                    placeholder="Enter Mobile Number"
                                    onChange={(event) =>
                                      handleMobileChange(event)
                                    }
                                    onKeyPress={handleMobilePress}
                                    onPaste={handleMobilePaste}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage:
                                          "Mobile number is required",
                                      },
                                      //   pattern: {
                                      //     value: /^[0-9]{10}$/,
                                      //     errorMessage: mobileError,
                                      //   },
                                    }}
                                  />
                                </AvForm>
                              </FormGroup>
                            </AvForm>
                          </div>
                          <div className="col-md-4">
                            <Label>
                              Email{" "}
                              <span className="required text-danger">*</span>
                            </Label>
                            <AvForm className="">
                              <FormGroup className="">
                                <AvField
                                  name="email"
                                  // label="email"
                                  type="text"
                                  value={email}
                                  placeholder="Enter email"
                                  onChange={(event) => handleEmailChange(event)}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Email is required",
                                    },
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                      title: "lnvalid Email Address",
                                    },
                                  }}
                                />
                              </FormGroup>
                            </AvForm>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <Label>
                              Message{" "}
                              <span className="required text-danger">*</span>
                            </Label>
                            <AvForm className="">
                              <FormGroup className="">
                                <AvField
                                  name="message"
                                  // label="Message"
                                  type="textarea"
                                  value={message}
                                  placeholder="Enter Message"
                                  onChange={(event) =>
                                    setMessage(event.target.value)
                                  }
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Message is required",
                                    },
                                  }}
                                />
                              </FormGroup>
                            </AvForm>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <Button
                              color="warning"
                              className="w-md waves-effect waves-light float-end"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Support;
