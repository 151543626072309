import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const EditZoneHead = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>Edit-ZoneHead</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditZoneHead;
