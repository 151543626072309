import React, { useEffect, useState } from "react";
import Service from "../../services/dashboard.service";
import { CardTitle } from "reactstrap";
import "./ProgramOverView.css";
import Spinner, { SpinnerSmall } from "../Loader/Spinner";
import IndiaMap from "../Charts/DbtRedemtionChart";
import PieChart from "../Charts/PieCharts";
import moment from "moment";

const EarningOverview = (props) => {
  const data = props.data;
  const earnOverview = props.earnOverview;
  const loading = props.loading;
  const [section, setSection] = useState("point_earn");
  const [user_countMap, setCountMap] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapHide, setMapHide] = useState(true);
  const [sevenDays, setSevenDays] = useState([]);
  const [loader, setLoader] = useState(false);

  const body = {
    section: section,
    start_date: data.start_date,
    end_date: data.end_date,
    user_id: data.user_id,
  };

  const EarnOverviewDataCount = async () => {
    await Service.GetEarningOverviewRlp(body).then((res) => {
      setSevenDays(res?.data?.last_days);
    });
  };

  const handleCardClick = async (section) => {
    setLoader(true);
    setMapHide(false);
    setSection(section);
    body.section = section;
    await Service.GetEarningOverviewRlp(body).then((res) => {
      setMapData(res.data?.map_data);
      setCountMap(res.data?.sub_trade_data);
      setSevenDays(res.data?.last_days);
      setLoader(false);
    });
  };

  useEffect(() => {
    EarnOverviewDataCount();
  }, [data.start_date, data.end_date]);

  const handleClose = () => {
    setMapHide(true);
  };
  const earnCards = [
    {
      section: "point_earn",
      title: "Points Earned",
      count: earnOverview?.point_earn?.count,
      userCount: earnOverview?.point_earn?.user_count,
      // Average: earnOverview?.point_earn?.avg,
    },

    {
      section: "point_burn",
      title: "Points Burned",
      count: earnOverview?.point_burn?.count,
      userCount: earnOverview?.point_burn?.user_count,
      // Average: earnOverview?.point_burn?.avg,
    },

    {
      section: "expired_points",
      title: "Expired(Point)",
      count: earnOverview?.expired_points?.count,
      userCount: earnOverview?.expired_points?.user_count,
      // Average: earnOverview?.expired_points?.avg,
    },
    {
      section: "point_balance",
      title: "Point Balance",
      count: earnOverview?.point_balance?.count,
      userCount: earnOverview?.point_balance?.user_count,
      // Average: earnOverview?.point_balance?.avg,
    },

    // {
    //   section: "liability_points",
    //   title: "Liability(Point)",
    //   count: earnOverview?.liability_points?.count,
    //   userCount: earnOverview?.liability_points?.user_count,
    //   Average: earnOverview?.liability_points?.avg,
    // },
  ];
  return (
    <div className="mt-3">
      <CardTitle className="text-black-50 font-size-18">
        Earning ({moment(body.start_date).format("DD-MM-YYYY")} -{" "}
        {moment(body.end_date).format("DD-MM-YYYY")})
      </CardTitle>

      <div className="d-flex flex-wrap  justify-content-start">
        {earnCards.map((card) => (
          <div
            className="col-lg-2 col-md-12 col-sm-12 me-1 mb-3"
            key={card.section}
            style={{ width: "305px" }}
          >
            <div
              className={`${
                section === card.section ? "bg-soft-info" : ""
              } card pe`}
              onClick={() => handleCardClick(card.section)}
            >
              <CardTitle className="px-3 pt-3">{card.title}</CardTitle>
              {loading === true ? (
                <SpinnerSmall />
              ) : (
                <div className="mx-3 ">
                  <span className="d-flex">
                    <span
                      className="card-text font-size-16"
                      title={
                        card.section.includes("cash")
                          ? `Cash Count ${card.count}`
                          : `Points Count ${card.count}`
                      }
                    >
                      {card.count === undefined ? 0 : card.count}
                    </span>
                    <span
                      className="card-text font-size-14 "
                      title={`User Count ${card.userCount}`}
                    >
                      ({card.userCount === undefined ? 0 : card.userCount})
                    </span>
                  </span>

                  <hr></hr>
                  <div className="d-flex col-column">
                    {/* <p className="card-text text-black-50 ">Last 7 Days:</p>
                    <p
                      className="card-text text-success mb-n1 mx-2"
                      title={
                        card.section.includes("cash")
                          ? `Average Cash Count ${card.Average?.avg_cash}`
                          : `Average Points Count ${card.Average?.avg_cash}`
                      }
                    >
                      {`Avg: ${
                        card.Average?.avg_cash === null
                          ? 0
                          : card.Average?.avg_cash
                      }`}
                    </p>
                    <p
                      className="text-black-50 card-text mb-n1"
                      title={`Average User Count ${card.Average?.avg_count}`}
                    >
                      {`(${
                        card.Average?.avg_count === null
                          ? 0
                          : card.Average?.avg_count
                      })`}
                    </p>
                    {section === card?.section && loader === false && sevenDays?.length > 0 ? (
                      <ul className="list " style={{ width: "240px" }}>
                        {sevenDays?.length !== 0 ? (
                          <>
                            {" "}
                            {sevenDays?.map((day) => {
                              const formattedDate = moment(day.date).format(
                                "DD-MM-YYYY"
                              );
                              return (
                                <>
                                  <li className="font-size-12" key={day.date}>
                                    {`${formattedDate} : ${day.day} ${day.cash}
                              (${day.count})`}
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : null}
                      </ul>
                    ) : null} */}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {mapHide === false ? (
        <>
          <div className="row mt-3 d-flex">
            <div className="col-md-12 col-md-12">
              <button
                className="btn btn-danger w-auto float-end"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
          <div className="row mt-3 d-flex justify-content-center">
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise{" "}
                  {section === "point_earn"
                    ? "Points Earned"
                    : section === "cash_earn"
                    ? "Cash Earned"
                    : section === "point_burn"
                    ? "Points Burned"
                    : section === "cash_burn"
                    ? "Cash Burned"
                    : section === "expired_points"
                    ? "Expired(Point)"
                    : section === "point_balance"
                    ? "Point Balance"
                    : section === "cash_balance"
                    ? "Cash Balance"
                    : section === "liability_points"
                    ? "Liability(Point)"
                    : section === "liability_cash"
                    ? "Liability(Cash)"
                    : ""}
                </CardTitle>
                {mapData === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={mapData} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EarningOverview;
