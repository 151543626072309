import axios from "axios";
import { API_URL } from "../Config/config";
import { useHistory } from "react-router-dom";
import authService from "./auth.service";

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
  },
});


const uploadCodeFile = async (body) => {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return await axiosApi
      .post(API_URL + "code-upload", body)
      .then((response) => {
        if (response.data.status === "success") {
          return { status: true, data: response.data.message };
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          authService.autoLogout();
          window.location.href = "/login";
        }else {
          throw err;
        }
      });
  };

  const getUploadCodeFile = async (params) => {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return await axiosApi
      .get(API_URL + "code-upload",{params})
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          return { status: true, data: response.data };
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          authService.autoLogout();
          window.location.href = "/login";
        }else {
          throw err;
        }
      });
  };
  export default { 
    uploadCodeFile,
    getUploadCodeFile
  }