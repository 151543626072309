import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_indiaLow from "../../components/Charts/IndiaMapJSON/india.json";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart = (props) => {
  const data = props.data?.map((item) => {
    return {
      id: item.id,
      value: item.value,
    };
  });

  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);
    var myTheme = am5.Theme.new(root);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "none",
        panY: "none",
        // minZoomLevel: "none",
        maxZoomLevel: "1",
        // projection: am5map.geoAlbersUsa(),
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_indiaLow,
        valueField: "value",
        calculateAggregates: true,
      })
    );
    polygonSeries.data.setAll(data?.length > 0 ? data : []);
    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}: {value}",
      fill: am5.color("#D3D3D3"), // Set default fill color to grey
    });
    root.setThemes([am5themes_Animated.new(root)]);

    // polygonSeries.mapPolygons.template.setAll({
    //   tooltipText: "{name}: {value}",
    // });
    // Configure polygon appearance
    polygonSeries.set("heatRules", [
      {
        target: polygonSeries.mapPolygons.template,
        dataField: "value",
        min: am5.color("#fcb92c"),
        max: am5.color("#ff7c00"),
        key: "fill",
        colorSteps: 10,
      },
    ]);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div
      id="chartdiv"
      ref={chartRef}
      style={{ width: "100%", height: "313px", paddingTop: "50px" }}
    />
  );
};

export default Chart;
