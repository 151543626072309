import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toggleRightSidebar } from "../../store/actions";

import {
  // ToastContainer,
  toast,
} from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import "./ViewTransaction.css";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import ViewTransactionTable from "../../components/Table/ViewTransactionTable";
import viewTransactionService from "../../services/viewTransaction.service";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReportModal from "../../components/Modal/ReportModal";
import Master from "../../components/Master/Master";

const ViewTransactions = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);

  function toggleRightbar() {
    props.toggleRightSidebar();
  }

  const [selectedUserType, setSelectedUserType] = useState(null);
  const user_id = user ? user.user_id : null;
  const role = user ? user.role_id : null;
  const loading = useSelector((state) => state.loading.loading);
  // const countLoading = useSelector((state) => state.loading.loading);
  const [countLoading, setCountLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date(); // current date
  currentDate.setDate(currentDate.getDate() - 1);
  const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  const initialStartDate = moment(yesterday).subtract(89, "days").toDate(); // subtract 90 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(new Date('2023-10-01')).toDate()),
      endDate: new Date(),
      key: "selection",
    },
    // {
    //   startDate: new Date(moment(currentDate)),
    //   endDate: new Date(moment(currentDate)),
    //   key: "selection",
    // },
  ];

  const [disableDownload, setDisableDownload] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [codeType, setCodeType] = useState("");
  let [userType, setUserType] = useState("mlp");
  let [valueType, setValueType] = useState("mlp");

  const [cashAndPoint, setCashAndPoint] = useState("");
  const [nameMobile, setNameMobile] = useState("");
  const [reportName, setReportName] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const [segment, setSegment] = useState("");
  const [segmentOptions, setSegmentOptions] = useState([
    { value: "Select", Label: "Select" },
  ]);
  const [segmentId, setSegmentId] = useState([]);

  const [modal, setModal] = useState(false);

  const [codeTypeOptions, setCodeTypeOptions] = useState([
    { value: "", label: "Select Code Type" },
    { value: "COUPON", label: "COUPON" },
    { value: "BONUS", label: "BONUS" },
  ]);
  const [userTypeOptions, setUserTypeOptions] = useState([
    { value: "mlp", label: "Electrician" },
    { value: "rlp", label: "Retailer" },
  ]);
  const [cashPointOptions, setCashPointOptions] = useState([
    { value: "", label: "Select Cash/Points" },
    { value: "CASH", label: "CASH" },
    { value: "POINTS", label: "POINTS" },
  ]);

  //modified Body data
  let bodyFilters = {
    regional_mapping: master ? master.regional_mapping : null,
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    limit: pageSize,
    offset: currentPage,
    user_type: selectedUserType?.value,
  };
  let countFilters = {
    regional_mapping: master ? master.regional_mapping : null,
    limit: pageSize,
    offset: 1, //default value
  };

  if (codeType.length > 0) {
    bodyFilters.code_type_name = codeType;
    countFilters.code_type_name = codeType;
  }
  if (valueType.length > 0) {
    bodyFilters.user_type = valueType;
    countFilters.user_type = valueType;
  }
  if (cashAndPoint.length > 0) {
    bodyFilters.cash_and_point = cashAndPoint;
    countFilters.cash_and_point = cashAndPoint;
  }
  if (state.length > 0) {
    bodyFilters.state_id = stateId;
    countFilters.state_id = stateId;
  }
  if (district.length > 0) {
    bodyFilters.district_id = districtId;
    countFilters.district_id = districtId;
  }
  if (segment.length > 0) {
    bodyFilters.segment_id = segmentId;
    countFilters.segment_id = segmentId;
  }
  if (nameMobile.length > 0) {
    bodyFilters.name_mobile = nameMobile;
    countFilters.name_mobile = nameMobile;
  }
  if (dateRange.length > 0) {
    bodyFilters.start_date = moment(dateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    bodyFilters.end_date = moment(dateRange[0].endDate).format("YYYY-MM-DD");
    countFilters.start_date = moment(dateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    countFilters.end_date = moment(dateRange[0].endDate).format("YYYY-MM-DD");
  }

  //handleFunction
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    bodyFilters.start_date = moment(item.selection.startDate).format(
      "YYYY-MM-DD"
    );
    bodyFilters.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };

  const handleExportTransaction = async () => {
    // setCountRefresh(false);
    setModal(false);
    setDisableDownload(true);
    // toast.success("Preparing your file", {
    //   autoClose: 2000,
    // });
    bodyFilters.count_refresh = false;
    bodyFilters.report_name = reportName;
    setReportName("");
    const transactionList = await viewTransactionService.exportViewTransaction(
      bodyFilters
    );

    if (transactionList.code === 200) {
      setDisableDownload(false);
      toast.success(transactionList.message, {
        autoClose: 2000,
      });
    } else {
      setDisableDownload(false);
      toast.error(transactionList.message, {
        autoClose: 2000,
      });
    }
  };

  const handleSubmit = async () => {
    setUserType(valueType);
    console.log("Before resetting valueType:", valueType);
    setCurrentPage(1);
    setIsOpenFirstmonth(false);
    fetchViewTransactionsData();
    fetchViewTransactionsCount();
  };
  const handleReset = async () => {
    setCodeType("");
    setCashAndPoint("");
    setNameMobile("");
    setDistrict("");
    setDistrictId([]);
    setDistrictOptions([]);
    setState("");
    setstateOptions([]);
    setStateId([]);
    setSegment("");
    setSegmentOptions([{ value: "Select", Label: "Select" }]);
    setSegmentId([]);
    setValueType("mlp");
    console.log("Before resetting userType:", userType);
    // setUserType("mlp");
    userType = "mlp";
    valueType = "mlp";
    // setValueType("mlp", () => {
    //   setUserType("mlp");
    // });

    console.log("After resetting userType:", userType);

    bodyFilters = {
      start_date: moment(initialDateRange[0].startDate).format("YYYY-MM-DD"),
      end_date: moment(initialDateRange[0].endDate).format("YYYY-MM-DD"),
      limit: pageSize,
      offset: currentPage,
    };
    countFilters = {
      start_date: moment(initialDateRange[0].startDate).format("YYYY-MM-DD"),
      end_date: moment(initialDateRange[0].endDate).format("YYYY-MM-DD"),
      limit: pageSize,
      offset: 1,
    };
    setDateRange(initialDateRange);
    fetchGetSegment();
    handleSubmit();
  };
  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };
  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };
  const handleSegmentChanges = async (e) => {
    const selectedSegment = segmentOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedSegment) {
      setSegment(selectedSegment.value);
      setSegmentId(selectedSegment.segment_id);
    } else {
      setSegment("");
      setSegmentId("");
    }
  };
  const checkDaysFromDateRange = async (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;
    // if (diffDays > 93) {
    //   toast.error("Please choose a date range of 93 days or less.", {
    //     autoClose: 2000,
    //   });
    //   return false;
    // }
    return true;
  };
  const toggleModal = async () => {
    //  let flag = false;
    // if (flag === false) {
    //   flag = await checkDaysFromDateRange(bodyFilters.start_date, bodyFilters.end_date);
    // }
    // if (flag === true) {
    //   setModal(!modal);
    //   setReportName("");
    // }
    setModal(!modal);
    setReportName("");
  };

  //calling API
  const fetchViewTransactionsData = async () => {
    dispatch(showLoader());

    bodyFilters.count_refresh = false;
    const transactionList = await viewTransactionService.GetTransactionList(
      bodyFilters
    );
    if (transactionList.status === true) {
      setData(transactionList.data.data);
    } else if (transactionList.status === false) {
      setData([]);
    }
    dispatch(hideLoader());
  };
  const fetchViewTransactionsCount = async () => {
    // dispatch(showLoader());
    setCountLoading(true);
    setTotalPages(null);
    setTotalCount(null);
    countFilters.count_refresh = true;
    setDisableSubmit(true);
    await viewTransactionService
      .GetTransactionList(countFilters)
      .then((transactionList) => {
        console.log("transactionList", transactionList);
        if (transactionList?.status === true) {
          setCountLoading(false);
          setTotalPages(transactionList.data.pagination.totalPages);
          setTotalCount(transactionList.data.count);
          setDisableSubmit(false);
        } else if (transactionList?.status === false) {
          setCountLoading(false);
          setTotalPages(0);
          setTotalCount(0);
          setDisableSubmit(false);
        }
      });
    setCountLoading(false);
    // dispatch(hideLoader());
  };
  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData?.data?.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await viewTransactionService.getDistricts(params);
      const options = districtData?.data?.map((district) => ({
        district_id: district.id,
        value: district.name,
        label: district.name,
      }));

      setDistrictOptions(options);
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };
  const fetchGetSegment = async () => {
    const segmentData = await viewTransactionService.getSegments();
    const options = segmentData.data.map((segment) => ({
      segment_id: segment.id,
      value: segment.value,
      label: segment.value,
    }));

    setSegmentOptions(options);
  };

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    fetchGetSegment();
    fetchViewTransactionsData();
  }, [currentPage]);

  useEffect(() => {
    fetchGetSegment();
    fetchViewTransactionsCount();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="mb-3">
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        View Transaction
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        {/* <span
                          className="d-flex justify-content-between"
                          style={{ float: "left", paddingRight: "5px" }}
                        > */}
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {/* </span> */}
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 16 || role === 22 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Transaction Report"
                            handleAPI={handleExportTransaction}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}

                    {/* <div className="total-count-class">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div> */}
                  </div>
                  <div>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>Code Type</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={codeType}
                            onChange={(e) => {
                              setCodeType(e.target.value);
                            }}
                          >
                            {codeTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Cash/Points</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={cashAndPoint}
                            onChange={(e) => {
                              setCashAndPoint(e.target.value);
                            }}
                          >
                            {cashPointOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>State Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={state}
                            onFocusCapture={fetchGetStates}
                            onChange={handleStateChanges}
                          >
                            <option value="">Select State</option>
                            {stateOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>District Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={district}
                            onFocusCapture={fetchGetDistricts}
                            onChange={handleDistrictChanges}
                          >
                            <option value="">Select District</option>
                            {districtOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      {/* <Col md={3}>
                        <h6>Segment</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={segment}
                            // onFocusCapture={fetchGetSegment}
                            // onClick={fetchGetSegment}
                            onChange={handleSegmentChanges}
                          >
                            {segmentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col> */}
                      <Col md={3}>
                        <h6>Search</h6>
                        <Input
                          type="search"
                          placeholder="Phone no. / Name"
                          id="search_name_mobile"
                          value={nameMobile}
                          onChange={(e) => {
                            setNameMobile(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Dates</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {`${new Date(
                                bodyFilters.start_date
                              ).toLocaleString("default", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })} - ${new Date(
                                bodyFilters.end_date
                              ).toLocaleString("default", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}`}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2023-10-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end me-2 mt-1"
                                onClick={handleSubmit}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                      {/* <Col md={2}></Col> */}
                      <Col md={3}>
                        <h6>User Type</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={valueType}
                            onChange={(e) => {
                              // setUserType(e.target.value);
                              setValueType(e.target.value);
                            }}
                          >
                            {userTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3 float">
                      <Col lg={3} className="w-100">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning  float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={disableSubmit}
                        >
                          Search
                        </Button>{" "}
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <div className="bg-body pb-3 mt-n3">
                  <Master />{" "}
                </div>
                <CardBody className="">
                  <div className="">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <>
                          <h4 className="card-title mb-4">
                            {`Transaction List ${moment(
                              bodyFilters.start_date
                            ).format("MMM DD, YYYY")} - ${moment(
                              bodyFilters.end_date
                            ).format("MMM DD, YYYY")}`}
                          </h4>
                          <ViewTransactionTable
                            data={data}
                            userType={userType}
                          />
                        </>
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          <div className="d-flex justify-content-center ">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPages}
                                hidePrevButton
                                hideNextButton
                                onClick={handlePage}
                              />
                            </Stack>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ViewTransactions)
);
