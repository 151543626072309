import { Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
  Card,
} from 'reactstrap';
import DateRangePicker from "react-daterange-picker";
import moment from "moment";
import { toast } from 'react-toastify';
import Pagination from "@mui/material/Pagination";
import './Upload.css'
import UploadTable from '../../components/Table/UploadTable';
import Stack from "@mui/material/Stack";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import APAR_E_POWER_TEMPLATE from '../../assets/templates/Apar_Shakti_ELP.xlsx';
import APAR_POWER_TEMPLATE from '../../assets/templates/Apar_Shakt_RLP.xlsx';
import UploadService from '../../services/upload.service';

function UploadPage() {
    const [uploadType, setUploadType] = useState('');
    const [file, setFile] = useState(null);
    const [dateLabel, setDateLabel] = useState(
        `Select Date`
      );
    const [value, setValue] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectOptions,setSelectOptions] = useState([
        { value: '', label: 'Select upload type' },
        { value: 'APAR_E_POWER', label: 'APAR E-POWER' },
        { value: 'APAR_POWER', label: 'APAR POWER' },
    ]);
    const [fielNameSearch, setFileNameSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const pageSize = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const templatepath = {
        APAR_POWER : APAR_E_POWER_TEMPLATE,
        APAR_E_POWER : APAR_POWER_TEMPLATE
    };

    const handlePage = (event) => {
        const buttonElement = event.target; // Your event target
        if (buttonElement.tagName === "BUTTON") {
          const pageNumber = buttonElement.textContent;
          console.log(pageNumber); // Output: "2"
          setCurrentPage(pageNumber);
        }
      };

    const onSelectDate = async (value, states) => {
      setValue(value);
      setDateLabel(
        `${moment(value.start._d).format("DD-MM-YYYY")} to ${moment(
          value.end._d
        ).format("DD-MM-YYYY")}`
      );
    };

    const handleFileSelect = (event) => {
        // console.log(event?.target?.files[0]);
        if (event?.target?.files[0]) {
            setFile(event.target.files[0]);
        }
    }
    const handleSubmitFile = async() => {
        try {
            setLoading(true);
            if (!uploadType) return toast.info('Please select upload type.');
            if (!file) return toast.info('Please upload file.');
            const formData = new FormData();
            formData.append('type',uploadType);
            formData.append('code',file);
            const body = {
                upload_type : uploadType,
                file : file
            };
            console.log(body);
            const result = await UploadService.uploadCodeFile(formData);
            if (result.status) {
                toast.success('uploaded successfully.');
                await handleSearch();
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message || 'Fail to upload file.')
        }finally{setLoading(false)}
    }

    const handleSearch = async () => {
        try {
            setLoading(true);
            const params = {};
            if (fielNameSearch) {
                params['file_name'] = fielNameSearch;
            }
            if (value) {
                params['start_date'] = moment(value.start._d).format('YYYY-MM-DD');
                params['end_date'] =  moment(value.end._d).format('YYYY-MM-DD');
            }
            params['limit'] = pageSize;
            params['offset'] = currentPage;
            console.log(params);
            const result = await UploadService.getUploadCodeFile(params);
            if (result.status) {
                setData(result.data.data);
                setTotalCount(result.data.totalCount);
                setTotalPages(result.data.totalPages);
            }
        } catch (error) {
            console.log(error);
            setData([]);
            toast.error(error.message || 'Fail to fetch data.');
        }finally{setLoading(false)}
    }

    const handleReset = async() => {
        setFileNameSearch('');
        setValue(null);
        setDateLabel('Select Date');
        setCurrentPage(0);
        setTotalPages(null);
        setTotalCount(0);
        await handleSearch();
    }

    useEffect(async() => {
        await handleSearch();
    },[currentPage])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div
            className="bg-white p-6 rounded-lg"
            style={{ padding: "24px", borderRadius: "10px" }}
          >
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
                alignItems: "center",
              }}
            >
              <h2
                className="text-2xl font-bold text-black"
                style={{ display: "inline" }}
              >
                Uploads
              </h2>
              <a
                    href={templatepath[uploadType]}
                    download={uploadType}
                    target="_blank"
                    rel="noreferrer"
                >
              <Button className="btn btn-warning">Download Template</Button>
              </a>
            </div>

            <div>
              <Row>
                {/* <Col lg={4} md={4}>
                  <Label htmlFor="upload-type">Upload Type</Label>
                  <InputGroup>
                    <select
                      className="form-select"
                      value={uploadType}
                      onChange={(e) => setUploadType(e.target.value)}
                    >
                        {selectOptions.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                  </InputGroup>
                </Col> */}
                <Col lg={4} md={4}>
                  <Label htmlFor="select-file" className="form-label">
                    Select File
                  </Label>
                  <InputGroup>
                    <Input
                      onChange={handleFileSelect}
                      id="select-file"
                      type="file"
                      className="form-control"
                    />
                  </InputGroup>
                </Col>
                <Col lg={4} md={4}>
                  <InputGroup style={{ marginTop: "30px" }}>
                    <Button
                      onClick={handleSubmitFile}
                      className="btn btn-warning"
                      disabled={loading}
                    >
                      Submit File
                    </Button>
                  </InputGroup>
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col lg={4} md={4} sm={4}>
                  <Label htmlFor="file-name">File Name</Label>
                  <InputGroup>
                    <Input
                      value={fielNameSearch}
                      onChange={(e) => setFileNameSearch(e.target.value)}
                      id="file-name"
                      placeholder="Enter file name..."
                    />
                  </InputGroup>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <div className="date-container">
                    <Label htmlFor="select-date">Select Date</Label>
                    <InputGroup>
                      <button
                        id="select-date"
                        className="form-control text-lg-start mb-1"
                        onClick={() => setShowOverlay(true)}
                      >
                        {dateLabel}
                      </button>
                    </InputGroup>
                  </div>
                  {showOverlay && (
                    <div className="overlay">
                      <div className="content">
                        <div className="d-flex flex-column">
                          <DateRangePicker
                            value={value}
                            onSelect={onSelectDate}
                            singleDateRange={true}
                            maximumDate={new Date(moment().endOf("month"))}
                            minimumDate={new Date(moment("2024-04-12"))}
                            rangeColors={["#0A3B93", "#0A3B93", "#0A3B93"]}
                          />
                          <div>
                            <button
                              className="close-button float-end mt-auto mx-3 "
                              onClick={() => setShowOverlay(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col>
                  <InputGroup style={{ marginTop: "30px" }}>
                    <div className="flex justify-end space-x-2">
                      <Button
                        onClick={handleReset}
                        className="btn btn-danger mr-2"
                        style={{ marginRight: "20px" }}
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={handleSearch}
                        className="btn btn-warning mr-2"
                      >
                        Submit
                      </Button>
                    </div>
                  </InputGroup>
                </Col>
              </Row>
            </div>

            <Row style={{ marginTop: "10px" }}>
              <Col>
                { loading ? (<Spinner/>) :
                (<UploadTable data={data}/>)
                }

                {totalCount === null ? (
                  <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                ) : (
                  <>
                    {totalCount === 0 ? null : (
                      <div className="d-flex justify-content-center ">
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPages}
                            hidePrevButton
                            hideNextButton
                            onClick={handlePage}
                          />
                        </Stack>
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UploadPage;
