import React from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Container, Label, FormGroup } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logo from "../assets/images/eni-login.png";

import { useState } from "react";
import { login } from "../actions/auth";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Service from "../services/auth.service";
import { user } from "../Config/config";
import Spinner, { SpinnerSmall } from "../components/Loader/Spinner";
import { set } from "lodash";

function Login(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userName, serUserName] = useState("");
  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [role, setRole] = useState("");
  const [isRoleVisible, setIsRoleVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const isLoggedIn = useSelector((state) => state.auth);

  const body = {
    username: userName,
    role_id: role?.value,
    password: password,
  };
  console.log(body);
  const submit = async () => {
    const login_status = await login(body);
    if (login_status.payload.user.invalid === true) {
      toast.error("Invalid Credentials");
    }
    if (login_status.type === "LOGIN_SUCCESS") {
      toast.success("Login Successful");
    }
    dispatch(login_status);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRoleChange = (e) => {
    console.log(e);
    // setIsPasswordVisible(true);
    setRole(e);
  };

  const handleButtonClick = async () => {
    setLoading(true);
    const body = {
      username: userName,
    };
    await Service.fetchRole(body).then((res) => {
      console.log(res, ";;;;;;;;;;;;;");
      if (res?.status === true) {
        console.log(
          isButtonVisible,
          "isButtonVisible",
          isRoleVisible,
          "isRoleVisible"
        );
        // toast.success(res.data.msg);
        setRoleList(res?.data?.data);
        setIsButtonVisible(false);
        setIsRoleVisible(res?.visible);
        setIsPasswordVisible(true);
        setLoading(false);
      } else if (res?.status === false) {
        setIsButtonVisible(false);
        setIsRoleVisible(false);
        setIsPasswordVisible(false);
        toast.error(res.message);
        setLoading(false);
      } else {
        console.log(
          isButtonVisible,
          "isButtonVisible",
          isRoleVisible,
          "isRoleVisible"
        );
        toast.error(res.message);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (userName.length >= 100) {
      // setIsRoleVisible(false);
      // setIsPasswordVisible(true);
      handleButtonClick();
    }
  }, [userName]);

  useEffect(() => {
    if (isLoggedIn.isLoggedIn === true) {
      history.push("/segment-volume");
    }
  }, [history, isLoggedIn, isLoggedIn.isLoggedIn]);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logo} height="200" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                          <p className="text-muted">
                            Log In to continue to Dashboard.
                          </p>
                        </div>
                        {loading === true ? (
                          <Spinner />
                        ) : (
                          <div className="p-2 mt-5">
                            <AvForm className="form-horizontal">
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userName">User Name</Label>

                                <AvField
                                  name="name"
                                  // maxLength={10}
                                  value={userName}
                                  onChange={(e) => serUserName(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Please enter User Name",
                                    },
                                  }}
                                  placeholder="Enter Mobile/Email"
                                />
                              </FormGroup>
                              {isButtonVisible === true ? (
                                <Button
                                  color="warning"
                                  onClick={handleButtonClick}
                                  className="w-100 waves-effect waves-light"
                                  type="submit"
                                  disabled={userName === "" ? true : false}
                                >
                                  Next
                                </Button>
                              ) : null}
                              {isRoleVisible === true ? (
                                <Select
                                  options={roleList}
                                  value={role}
                                  onChange={(e) => handleRoleChange(e)}
                                  placeholder="Select Role"
                                  noOptionsMessage={() => "No options found"}
                                  isSearchable
                                  className="react-select mb-2 mt-n3"
                                />
                              ) : null}
                              {isPasswordVisible === false ? null : (
                                <>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    {/* <i className="ri-lock-2-line auti-custom-input-icon"></i> */}
                                    <Label htmlFor="userpassword">
                                      Password
                                    </Label>
                                    <AvField
                                      name="password"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      type={showPassword ? "text" : "password"}
                                      className="form-control"
                                      id="userpassword"
                                      placeholder="Enter password"
                                      validate={{
                                        // minLength: {
                                        //   value: invalid,
                                        //   errorMessage:
                                        //     "Please Enter Valid User Name or Password",
                                        // },
                                        required: {
                                          value: true,
                                          errorMessage: "Please enter password",
                                        },
                                      }}
                                    />
                                    {showPassword ? (
                                      <i
                                        onClick={handleTogglePassword}
                                        className="ri-eye-fill auti-custom-input-icon"
                                      />
                                    ) : (
                                      <i
                                        onClick={handleTogglePassword}
                                        className=" ri-eye-off-fill auti-custom-input-icon"
                                      />
                                    )}
                                  </FormGroup>

                                  {/* <div className="form-check">
                                                    <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                    <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                                                </div> */}

                                  <div className="mt-4 text-center">
                                    <Button
                                      color="warning"
                                      onClick={submit}
                                      className="w-100 waves-effect waves-light"
                                      type="submit"
                                      disabled={
                                        userName === "" || password === ""
                                          ? true
                                          : false
                                      }
                                    >
                                      Log In
                                    </Button>
                                  </div>
                                </>
                              )}

                              {/* <div className="mt-4 text-center">
                                                            <Link to="/auth-recoverpw" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                        </div> */}
                            </AvForm>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                {/* <div className="bg-overlay"></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Login;
