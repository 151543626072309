import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

const GetRedemption = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id;

  return await axiosApi
    .post(API_URL + "redemption/dbt-status", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetSODbtStatus = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id;

  return await axiosApi
    .post(API_URL + "redemption/so-dbt-status", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetSOList = async (params) => {
  const user = JSON.parse(localStorage.getItem("user"));

  params.user_id = user.user_id;

  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  // body.user_id = user.user_id;

  return await axiosApi
    .get(API_URL + "user/get-so", { params })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetRewardStatusList = async (params) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  // body.user_id = user.user_id;

  return await axiosApi
    .get(API_URL + "constant/reward-status-list", { params })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetRedemptionList = async (body) => {
  console.log("===========>", 'bqdsjhbqwkjqnhjk');
  const user = JSON.parse(localStorage.getItem("user"));

  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id;

  return await axiosApi
    .post(API_URL + "redemption/view-redemption", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const exportRedemptionList = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id;

  return await axiosApi
    .post(API_URL + "redemption/export-redemption", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
export default {
  GetRedemption,
  GetSODbtStatus,
  GetSOList,
  GetRewardStatusList,
  GetRedemptionList,
  exportRedemptionList,
};
