import React, { useState, useEffect } from "react";
import { Label, Button } from "reactstrap";
import Select from "react-select";

import { connect } from "react-redux";
import {
  hideRightSidebar,
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import masterService from "../../services/master.service";
import { Link } from "react-router-dom";
import action from "../../actions/actions";

import "./rightbar.css";
import authService from "../../services/auth.service";

function RightSidebar(props) {
  const isLoggedIn = useSelector((state) => state.auth);
  const master = useSelector((state) => state.master.setList.body);
  const dispatch = useDispatch();
  const [zoneHead, setZoneHead] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [reginalHead, setRegionalHead] = useState([]);
  const [stateHead, setStateHead] = useState([]);
  const [salesOfficer, setSalesOfficer] = useState([]);
  const [selectedRegionalHead, setSelectedRegionalHead] = useState([]);
  const [selectedStateHead, setSelectedStateHead] = useState([]);
  const [selectedSalesOffier, setSelectedSalesOfficer] = useState([]);

  const [showRegional, setShowRegional] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showSalesOfficer, setShowSalesOfficer] = useState(false);
  // const [user_ids, setUser_Ids] = useState();
  // const [role, setRole] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.role_id;

  const fetchMasterBody = () => {
    const masterList = action.setMaster(body);
    dispatch(masterList);
  };

  const resetMasterBody = () => {
    const masterList = action.resetMaster({});
    dispatch(masterList);
  };

  const setMasterList = () => {
    const Master = master ? master.regional_mapping : [];
    for (let i = 0; i < Master.length; i++) {
      if (Master[i].role_id === 19) {
        setShowRegional(true);
        const list = Master[i].data.map((obj) => ({
          value: obj.value,
          label: obj.label,
          role: 19,
          role_id: "REGIONAL HEAD",
        }));
        setSelectedRegionalHead(list);
      }
      if (Master[i].role_id === 16) {
        setShowState(true);
        const list = Master[i].data.map((obj) => ({
          value: obj.value,
          label: obj.label,
          role: 16,
          role_id: "STATE HEAD",
        }));
        setSelectedStateHead(list);
      }
      if (Master[i].role_id === 18) {
        const list = Master[i].data.map((obj) => ({
          value: obj.value,
          label: obj.label,
          role: 18,
          role_id: "ZONAL HEAD",
        }));
        setSelectedZone(list);
      }
      if (Master[i].role_id === 22) {
        setShowSalesOfficer(true);
        const list = Master[i].data.map((obj) => ({
          value: obj.value,
          label: obj.label,
          role: 22,
          role_id: "SALES_OFFICER",
        }));
        setSelectedSalesOfficer(list);
      }
    }
  };

  const createBody = (selectedArray) => {
    const Array = {
      role: selectedArray.length !== 0 ? selectedArray[0].role : "",
      role_id: selectedArray.length !== 0 ? selectedArray[0].role_id : "",
      user_id: [],
      data: [],
    };

    if (selectedArray.length !== 0) {
      for (let i = 0; i < selectedArray.length; i++) {
        Array.user_id.push(selectedArray[i].value);
        Array.data.push({
          value: selectedArray[i].value,
          label: selectedArray[i].label,
        });
      }
    }

    return Array;
  };

  const body = {
    regional_mapping: [
      createBody(selectedZone),
      createBody(selectedRegionalHead),
      createBody(selectedStateHead),
      createBody(selectedSalesOffier),
    ],
  };

  const fetchMasterList = async (user_ids) => {
    const list = await masterService.GetMasterList(user_ids);
    // console.log(list, "./././././././../");
    // if (list?.status.code === 403) {
    //   // history.push("/login");
    //   console.log("logout");
    //   handleLogout();
    // }
    return list?.data;
  };

  const setInitialSelect = async (user_ids) => {
    const list = await fetchMasterList(user_ids);

    if (role === 18) {
      setRegionalHead(list);
    }
    if (role === 19) {
      setStateHead(list);
    }
    if (role === 16) {
      setSalesOfficer(list);
    }
    if (role === 24) {
      setZoneHead(list);
    }
  };

  const handleZoneChange = async (e) => {
    setSelectedZone(e);
    if (e.length === 0) {
      setShowRegional(false);
    }
    if (e.length !== 0) {
      const regional_user_ids = e.map((obj) => obj.value);
      const regional_head = await fetchMasterList(regional_user_ids);
      setRegionalHead(regional_head);
      setShowRegional(true);
    }
  };

  const handleRegionalChange = async (e) => {
    setSelectedRegionalHead(e);
    if (e.length === 0) {
      setShowState(false);
    }
    if (e.length !== 0) {
      const state_user_ids = e.map((obj) => obj.value);
      const state_head = await fetchMasterList(state_user_ids);
      setStateHead(state_head);
      setShowState(true);
    }
  };

  const handleStateChange = async (e) => {
    if (e.length === 0) {
      setShowSalesOfficer(false);
    }
    if (e.length !== 0) {
      const so_user_ids = e.map((obj) => obj.value);
      const state_head = await fetchMasterList(so_user_ids);
      setSalesOfficer(state_head);
      setShowSalesOfficer(true);
    }
    setSelectedStateHead(e);
  };

  const handleSOChange = (e) => {
    setSelectedSalesOfficer(e);
  };

  const handleLogout = () => {
    authService.logout();
  };

  /**
   * Hides the right sidebar
   */
  function hideRightbar(e) {
    e.preventDefault();
    props.hideRightSidebar();
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px solid #ccc',
      color: state.isSelected ? "#fff" : "#333",
      backgroundColor: state.isSelected ? "yellow" : "#fff",

      "&:hover": {
        backgroundColor: "yellow",
        opacity: 0.5,
      },
      "&:focus": {
        borderColor: "yellow",
        boxShadow: "0 0 0 1px yellow",
      },
      "&:click": {
        borderColor: "yellow",
      },
    }),
    input: (provided) => ({
      ...provided,
      borderColor: "yellow",
      outline: "none",
      boxShadow: "none",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: 4,
      // borderColor: 'grey',
      // '&:hover': {
      //   // borderColor: 'yellow',
      // },
      "&:focus": {
        borderColor: "yellow",
        boxShadow: "0 0 0 1px yellow",
      },
      "&:click": {
        borderColor: "yellow",
      },
    }),
  };

  const reset = (e) => {
    setSelectedRegionalHead([]);
    setSelectedSalesOfficer([]);
    setSelectedStateHead([]);
    setSelectedZone([]);

    if (role === 18) {
      setStateHead([]);
    }
    if (role === 19) {
      setSalesOfficer([]);
    }
    resetMasterBody();
    hideRightbar(e);
  };

  const submit = (e) => {
    fetchMasterBody();
    hideRightbar(e);
  };

  useEffect(() => {
    setMasterList();
  }, []);

  useEffect(() => {
    if (user?.user_id) setInitialSelect([user.user_id]);
  }, []);

  return (
    <>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={hideRightbar}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
              <h5 className="m-0">Advance Filter</h5>
            </div>

            <hr className="my-0" />
            {role === 18 ? (
              <div className="p-4">
                <div className="pt-3">
                  <Label>Regional Head</Label>
                  <Select
                    isMulti
                    options={reginalHead}
                    value={selectedRegionalHead}
                    onChange={(e) => handleRegionalChange(e)}
                    placeholder="Select options"
                    noOptionsMessage={() => "No options found"}
                    isSearchable
                  />
                </div>
                {showState === true ? (
                  <div className="pt-3">
                    <Label>State Head</Label>
                    <Select
                      isMulti
                      options={stateHead}
                      value={selectedStateHead}
                      onChange={handleStateChange}
                      placeholder="Select options"
                      noOptionsMessage={() => "No options found"}
                      isSearchable
                    />
                  </div>
                ) : (
                  <></>
                )}
                {showSalesOfficer === true ? (
                  <div className="pt-3">
                    <Label>Sales Officer</Label>
                    <Select
                      isMulti
                      options={salesOfficer}
                      value={selectedSalesOffier}
                      onChange={handleSOChange}
                      placeholder="Select options"
                      noOptionsMessage={() => "No options found"}
                      isSearchable
                      className=""
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : role === 19 ? (
              <div className="p-4">
                <div className="pt-3">
                  <Label>State Head</Label>
                  <Select
                    isMulti
                    options={stateHead}
                    value={selectedStateHead}
                    onChange={handleStateChange}
                    placeholder="Select options"
                    noOptionsMessage={() => "No options found"}
                    isSearchable
                  />
                </div>
                {showSalesOfficer === true ? (
                  <div className="pt-3">
                    <Label>Sales Officer</Label>
                    <Select
                      isMulti
                      options={salesOfficer}
                      value={selectedSalesOffier}
                      onChange={handleSOChange}
                      placeholder="Select options"
                      noOptionsMessage={() => "No options found"}
                      isSearchable
                      className=""
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : role === 16 ? (
              <div className="p-4">
                <div className="pt-3">
                  <Label>Sales Officer</Label>
                  <Select
                    isMulti
                    options={salesOfficer}
                    value={selectedSalesOffier}
                    onChange={handleSOChange}
                    placeholder="Select options"
                    noOptionsMessage={() => "No options found"}
                    isSearchable
                    className=""
                  />
                </div>
              </div>
            ) : (
              <div className="p-4">
                <div className="pt-3">
                  <Label>Zonal Head</Label>
                  <Select
                    isMulti
                    options={zoneHead}
                    value={selectedZone}
                    onChange={handleZoneChange}
                    placeholder="Select options"
                    noOptionsMessage={() => "No options found"}
                    isSearchable
                    styles={customStyles}
                  />
                </div>
                {showRegional === true ? (
                  <div className="pt-3">
                    <Label>Reginal Head</Label>
                    <Select
                      isMulti
                      options={reginalHead}
                      value={selectedRegionalHead}
                      onChange={handleRegionalChange}
                      placeholder="Select options"
                      noOptionsMessage={() => "No options found"}
                      isSearchable
                    />
                  </div>
                ) : (
                  <></>
                )}
                {showState === true ? (
                  <div className="pt-3">
                    <Label>State Head</Label>
                    <Select
                      isMulti
                      options={stateHead}
                      value={selectedStateHead}
                      onChange={handleStateChange}
                      placeholder="Select options"
                      noOptionsMessage={() => "No options found"}
                      isSearchable
                    />
                  </div>
                ) : (
                  <></>
                )}
                {showSalesOfficer === true ? (
                  <div className="pt-3">
                    <Label>Sales Officer</Label>
                    <Select
                      isMulti
                      options={salesOfficer}
                      value={selectedSalesOffier}
                      onChange={handleSOChange}
                      placeholder="Select options"
                      noOptionsMessage={() => "No options found"}
                      isSearchable
                      className=""
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="p-4 float-end">
              <Button
                className="btn btn-warning mx-3"
                onClick={submit}
                disabled={
                  selectedRegionalHead.length === 0 &&
                  selectedSalesOffier.length === 0 &&
                  selectedStateHead.length === 0 &&
                  selectedZone.length === 0
                }
              >
                Search
              </Button>
              <Button className="btn btn-danger" onClick={reset}>
                Reset
              </Button>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </>
  );
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  hideRightSidebar,
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
})(RightSidebar);
