import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
// import { useSelector, useDispatch } from "react-redux";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

const user = JSON.parse(localStorage.getItem("user"));

const GetUserProfile = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  const user_id = { user_id: user.user_id };

  return await axiosApi
    .post(API_URL + "user/profile", user_id)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

export default {
  GetUserProfile,
};
