import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

// create axios instance with headers
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const GetExpiryList = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;
  // body.user_id = 1234567890;
  // body.role_id = 5;

  return await axiosApi
    .post(API_URL + "expiry/data", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      } else if (response.data.status === "fail") {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        status: false,
      };
    });
};

const exportViewExpiry = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "expiry/export-expiry", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};

export default {
  GetExpiryList,
  exportViewExpiry,
};
