import axios from "axios";
import { success } from "toastr";
import authService from "./auth.service";
import { API_URL } from "../Config/config";
// const API_URL = "http://betaanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const user = JSON.parse(localStorage.getItem("user")) ?? {};
const token = user.token;
if (token) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const getUserList = async (body) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosApi.post(API_URL + "master/getUsers", body);
    if (response.status === 200) {
      return { status: true, data: response.data.data };
    }
    if (response.status === 401) {
      console.log(response, "...............................");
      return { status: false, message: response.data.message };
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 403) {
      // localStorage.removeItem("user");
      authService.autoLogout();
      window.location.href = "/login";
    }
    // else {
    //   return { status: false, message: err };
    // }
  }
};

const getAllUserList = async (body) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosApi.post(API_URL + "master/getAllUser", body);
    if (response.status === 200) {
      return { status: true, data: response.data.data };
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 403) {
      // localStorage.removeItem("user");
      authService.autoLogout();
      window.location.href = "/login";
    }
    //  else {
    //   return { status: false, message: err };
    // }
  }
};

const addUser = async (body) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosApi.post(API_URL + "master/adduser", body);
    console.log(response, "response");
    if (response.status === 200) {
      return { status: true, message: response.data.message };
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 403) {
      // localStorage.removeItem("user");
      authService.autoLogout();
      window.location.href = "/login";
    }
    // else
    // {
    //   return { status: false, message: err };
    // }
  }
};

const getUserById = async (body) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosApi.post(API_URL + "master/getUserById", body);
    if (response.status === 200) {
      return { status: true, data: response.data.data };
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 403) {
      // localStorage.removeItem("user");
      authService.autoLogout();
      window.location.href = "/login";
    }
    // else {
    //   return { status: false, message: err };
    // }
  }
};

const updateUser = async (body) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosApi.post(API_URL + "master/updateUser", body);
    if (response.status === 200) {
      return { status: true, data: response.data.data };
    }
  } catch (err) {
    if (err?.response?.status === 403) {
      // localStorage.removeItem("user");
      authService.autoLogout();
      window.location.href = "/login";
    }
    // else {
    //   return { status: false, message: err };
    // }
  }
};

const cloneUser = async (body) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const token = user.token;
    if (token) {
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return await axiosApi
      .post(API_URL + "master/cloneUser", body)
      .then((response) => {
        console.log(response, "response");
        if (response.status === 200) {
          return { status: true, message: response.data.msg };
        }
      });
  } catch (err) {
    // return { status: false, message: err?.response?.data?.message };
    if (err?.response?.status === 403) {
      // localStorage.removeItem("user");
      authService.autoLogout();
      window.location.href = "/login";
    }
  }
};

export default {
  getUserList,
  getAllUserList,
  addUser,
  getUserById,
  updateUser,
  cloneUser,
};
