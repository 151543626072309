import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { withNamespaces } from "react-i18next";
import { toggleRightSidebar } from "../../store/actions";
import logo from "../../assets/images/eni-login.png";

function Header(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  function toggleMenu() {
    props.toggleMenuCallback();
  }

  function toggleRightbar() {
    props.toggleRightSidebar();
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box bg-light">
              <Link to="/segment-volume" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="60" />
                </span>
              </Link>

              <Link to="/segment-volume" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg mx-4">
                  <img src={logo} alt="" height="60" />
                </span>
              </Link>
            </div>

            <Button
              size="sm"
              color="none"
              type="button"
              onClick={toggleMenu}
              className="px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="ri-menu-2-line align-middle"></i>
            </Button>
          </div>

          <div className="d-flex">
            <ProfileMenu />
            {/* 
            {user.role_id !== 22 ? (
              <div className="dropdown d-inline-block">
                <Button
                  color="none"
                  onClick={toggleRightbar}
                  type="button"
                  className="header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="ri-settings-2-line"></i>
                </Button>
              </div>
            ) : null} */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(Header)
);
