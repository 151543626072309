import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { withNamespaces } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import Select from "react-select";
import "./Retailer.css";
import ViewMechanicTable from "../../components/Table/ViewElectricianTable";
import Service from "../../services/Electrician.service";
import ReportModal from "../../components/Modal/ReportModal";
import XLSXFileUploadModal from "../../components/Modal/XLSXFileUploadModal";
import viewTransactionService from "../../services/viewTransaction.service";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange, DateRangePicker } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import RetailerTable from "../../components/Table/ViewRetailerTable";
import Stack from "@mui/material/Stack";
// import Master from "../../components/Master/Master";

const ViewRetailer = () => {
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [docStatus, setDocStatus] = useState("");
  const [appStatus, setAppStatus] = useState("");
  const [nameMobile, setNameMobile] = useState("");
  const [reportName, setReportName] = useState("");
  const [kycNumber, setKycNumber] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(moment(new Date("2023-10-01")).toDate()),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialStartDate = moment(new Date("2023-10-01")).toDate();
  const initialDateRange = [
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [stateId, setStateId] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.user_id;
  const role = user?.role_id;
  const loading = useSelector((state) => state.loading.loading);
  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });
  const [documentOptions, setDocumentOptions] = useState([
    { value: "", label: "Select Status" },
    { value: "0", label: "ACTIVE" },
    { value: "1", label: "INACTIVE" },
  ]);
  const [appOptions, setAppOptions] = useState([
    { value: "", label: "Select App Status" },
    { value: "app_register_user", label: "App Register User" },
    { value: "app_download_user", label: "Total Download" },
  ]);
  let bodyFilters = {
    role_id: role,
    user_id: user_id,
    // regional_mapping: master?.regional_mapping,
    limit: pageSize,
    offset: currentPage,
    document_filter: docStatus,
    app_filter: appStatus,
    mobile: nameMobile,
    kyc_number: kycNumber,
    state_id: state?.value,
    district_id: district?.value,
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
  };

  if (docStatus.length > 0) {
    bodyFilters.document_filter = docStatus;
  }
  if (appStatus.length > 0) {
    bodyFilters.app_filter = appStatus;
  }
  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      bodyFilters.mobile = nameMobile;
    } else {
      bodyFilters.name = nameMobile;
    }
  }
  console.log("kyc number -------", kycNumber.length, kycNumber);
  if (kycNumber.length > 0) {
    bodyFilters.kyc_number = kycNumber;
  }

  if (state.length > 0) {
    bodyFilters.state_id = stateId;
  }
  if (district.length > 0) {
    bodyFilters.district_id = districtId;
  }
  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    bodyFilters.start_date = dateFilters.start_date;
    bodyFilters.end_date = dateFilters.end_date;
  }

  const [modal, setModal] = useState(false);
  const toggleModal = async () => {
    setModal(!modal);
    setReportName("");
  };
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleUploadModal = async () => {
    setUploadModalOpen(!uploadModalOpen);
  };

  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };
  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };

  const fetchData = async () => {

    dispatch(showLoader());
    const response = await Service.GetRetailerList(bodyFilters);
    console.log(response);
    if (response && response.status === true) {
      setData(response.data.data);
      setTotalCount(response.data.count);
      setTotalPages(response.data.pagination.totalPages);
      dispatch(hideLoader());
    } else {
      setData([]);
      setTotalCount([]);
      setTotalPages([]);
      dispatch(hideLoader());
    }
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };
  const handleExportMechanic = async () => {
    // setCountRefresh(false);
    setModal(false);
    setDisableDownload(true);
    // toast.success("Preparing your file", {
    //   autoClose: 2000,
    // });
    bodyFilters.report_name = reportName;
    setReportName("");
    await viewTransactionService.exportViewRetailer(bodyFilters).then((res) => {
      if (res.code === 200) {
        setDisableDownload(false);
        toast.success(res.message, {
          autoClose: 2000,
        });
      } else {
        setDisableDownload(false);
        toast.error(res.message, {
          autoClose: 2000,
        });
      }
    });
  };
  const handleFileUpload = async (file) => {
    setLoading(true);
    setUploadModalOpen(false);
    const formData = new FormData();
    formData.append("file", file);
    await viewTransactionService
      .uploadFile(file)
      .then((res) => {
        console.log("res", res);
        if (res.data) {
          toast.success(res.data.message, {
            autoClose: 2000,
          });
        } else {
          setDisableDownload(false);

          toast.error(res.message, {
            autoClose: 2000,
          });
        }
      })
      .catch((e) => {
        toast.error(`Error: ${e.message ? e.message : e}`, {
          autoClose: 2000,
        });
        setDisableDownload(false);
      });
    setLoading(false);
  };

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
        const pageNumber = parseInt(buttonElement.textContent, 10);
        console.log("Page number clicked:", pageNumber);
        setCurrentPage(pageNumber);
    }
    console.log("Total pages:", totalPages);
    console.log("Current page:", currentPage);
};

  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await viewTransactionService.getDistricts(params);
      // if (districtData.status === true) {
      //   setData(transactionList.data.data);
      // } else if (transactionList.status === false) {
      //   setData([]);
      // }
      if (districtData.status === true) {
        const options = districtData.data.map((district) => ({
          district_id: district.id,
          value: district.name,
          label: district.name,
        }));
        setDistrictOptions(options);
      } else if (districtData.status === false) {
        toast.error("No district found.", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };
  const handleReset = async () => {
    setDocStatus("");
    setAppStatus("app_register_user");
    setNameMobile("");
    setDistrict("");
    setDistrictId([]);
    setDistrictOptions([]);
    setState("");
    setstateOptions([]);
    setStateId([]);
    setKycNumber("");
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    bodyFilters = {
      limit: pageSize,
      offset: currentPage,
    };
    bodyFilters.app_filter = "app_register_user";
    setDateRange(initialDateRange);
    fetchData();
  };
  const submitDates = async () => {
    // setLoading(true);
    setIsOpenFirstmonth(false);
    fetchData();
    // setLoading(false);
  };
  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        View Retailer
                      </CardTitle>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>

                    {role === 16 || role === 22 ? null : (
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        style={{ width: "10px", marginLeft: "40px" }}
                      >
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />{" "}
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Retailer Report"
                            handleAPI={handleExportMechanic}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}

                    {role === 24 ? (
                      <Col lg={1} md={1} sm={1}>
                        {isLoading ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          <div>
                            {" "}
                            <div
                              className="download-excel"
                              style={{ opacity: disableDownload ? 0.5 : 1 }}
                            >
                              <i
                                title={
                                  disableDownload
                                    ? "Preparing your file"
                                    : "Upload Excel"
                                }
                                className="fa fa-upload"
                                onClick={
                                  disableDownload ? null : toggleUploadModal
                                }
                                disabled={disableDownload}
                              />{" "}
                              {/* <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="File Upload"
                            handleAPI={handleExportMechanic}
                            reportName={reportName}
                            setReportName={setReportName}
                          /> */}
                              <XLSXFileUploadModal
                                isOpen={uploadModalOpen}
                                toggle={toggleUploadModal}
                                modalHeader="File Upload"
                                handleFileUpload={handleFileUpload}
                              />
                            </div>
                          </div>
                        )}
                      </Col>
                    ) : null}
                  </div>
                  <Row>
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="appStatus">App Status</Label>
                        <select
                          className="form-select"
                          value={appStatus}
                          onChange={(e) => setAppStatus(e.target.value)}
                        >
                          {appOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="docStatus">Active/InActive</Label>
                        <select
                          className="form-select"
                          value={docStatus}
                          onChange={(e) => setDocStatus(e.target.value)}
                        >
                          {documentOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="8" xl="3">
                      <FormGroup>
                        <Label for="nameMobile">Name/Mobile</Label>
                        <Input
                          type="text"
                          id="nameMobile"
                          name="nameMobile"
                          value={nameMobile}
                          onChange={(e) => setNameMobile(e.target.value)}
                          placeholder="Enter Name/Mobile"
                        />
                      </FormGroup>
                    </Col>
                    {/* state dropDown */}
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="state">State</Label>
                        <select
                          className="form-select"
                          value={state}
                          onFocusCapture={fetchGetStates}
                          onChange={handleStateChanges}
                        >
                          <option value="">Select State</option>
                          {stateOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm="6" md="6" xl="3">
                      <FormGroup>
                        <Label for="district">District</Label>
                        <select
                          className="form-select"
                          value={district}
                          onFocusCapture={fetchGetDistricts}
                          onChange={handleDistrictChanges}
                        >
                          <option value="">Select District</option>
                          {districtOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" xl="3">
                      {isOpenfirstMonth === false ? (
                        <div>
                          <Label>Select Dates</Label>
                          <button
                            className="form-select"
                            onClick={handleFirstMonthButtonState}
                          >
                            {dateFilters.start_date && dateFilters.end_date
                              ? `${new Date(
                                  dateFilters.start_date
                                ).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })} - ${new Date(
                                  dateFilters.end_date
                                ).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}`
                              : "Select Date Range"}
                          </button>
                        </div>
                      ) : (
                        <div className="customDateOverlay">
                          <label className="form-label">Select Dates: </label>
                          <div className="">
                            <DateRange
                              editableDateInputs={true}
                              onChange={handledateRange}
                              moveRangeOnFirstSelection={false}
                              ranges={dateRange}
                              rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                              shouldCloseOnSelect={true}
                              maxDate={currentDate}
                              minDate={new Date(moment("2023-10-01"))}
                              onClose={handleDateRangeClose}
                            />
                            <Button
                              className="btn-danger float-end mt-1"
                              onClick={handleFirstMonthButtonState}
                            >
                              Close
                            </Button>
                            <Button
                              className="btn-warning float-end me-2 mt-1"
                              onClick={submitDates}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {/* <Col sm="6" md="6" xl="3">
                    <Label>Kyc Number</Label>
                        <Input
                          type="search"
                          placeholder="Kyc Number"
                          id="search_name_mobile"
                          value={kycNumber}
                          onChange={(e) => {
                            setKycNumber(e.target.value);
                          }}
                        />
                      </Col> */}
                  </Row>

                  <Row className="float-end">
                    <Col lg={3} className="w-100">
                      <Button
                        className="btn btn-danger btn-secondary float-end"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        className="btn-warning float-end mx-2"
                        onClick={fetchData}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <RetailerTable data={data} loading={loading} />
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          {totalCount === 0 ? null : (
                            <div className="d-flex justify-content-center ">
                              <Stack spacing={2}>
                                <Pagination
                                  count={totalPages}
                                  hidePrevButton
                                  hideNextButton
                                  onClick={handlePage}
                                />
                              </Stack>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewRetailer;
