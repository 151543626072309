import axios from "axios";
import { API_URL, CABEL_URL } from "../../Config/config"
import authService from "../../services/auth.service";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";
// const CABLE_URL = "https://cableapi.aparpower.com/api/";

//
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const RewardsFetch = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  body.user_id = user.user_id.toString();
  body.role_id = user.role_id;

  return await axiosApi
    .post(API_URL + "rewards/rewards", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      } else if (response.data.status === "fail") {
        return { status: false };
      }
    })
    .catch((err) => {
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const exportRewards = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer SKIP`;
  }
  body.isDownload = true;
  
  try {
    const report_name = body.report_name;
    const response = await axiosApi.post(CABEL_URL + "rewards/rewards", body, {
      responseType: "blob",
    });
    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      const filename = `${report_name}.xlsx`;
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        status: response.data.status,
        message: "File downloaded successfully",
        code: response.data.status,
      };
    } else {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      authService.autoLogout();
      window.location.href = "/login";
    } else if (error.response?.data?.status === "fail") {
      return {
        status: false,
        message: error.response.data.message,
        code: error.response.data.code,
      };
    } else {
      return {
        status: false,
        message: "Error downloading file",
        data: error,
      };
    }
  }
};

export default {
  RewardsFetch,
  exportRewards,
};
