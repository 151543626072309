import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import "../../pages/ViewExpiry/ViewExpiry.css";
import CustomModal from "../Modal/CustomModal";
import getSegment from "../../services/segment.service";
import moment from "moment";

const ExpiryTable = ({ data = [] }) => {
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);

  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };
  const fetchProfile = async (userId) => {
    const user_id = userId;
    setUserId(user_id);
    const params = { user_id: user_id };
    const response = await getSegment.GetUserDetails(params);

    if (response.status === true) {
      setModalData(response.data);
    }
  };
  const currentDate = new Date(); // Get the current date

  const currentMonthIndex = currentDate.getMonth(); // Get the index of the current month (0-11)
  const currentYear = currentDate.getFullYear().toString().substr(-2); // Get the last two digits of the current year

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthOrder = [];

  for (let i = currentMonthIndex; i < currentMonthIndex + 12; i++) {
    const monthIndex = i % 12; // Wrap around to the start of the year
    const year = parseInt(currentYear, 10) + Math.floor(i / 12); // Increment the year if necessary
    const monthName = `${monthNames[monthIndex]} ${year}`;
    monthOrder.push(monthName);
  }
  // Map each object in the data array to a table row
  const rows = data?.map((item, index) => (
    <tr key={index}>
      <td>
        <i
          className="fas fa-external-link-alt icon-table"
          onClick={async () => {
            await fetchProfile(item.user_id);
            profileToggleModal();
          }}
          title="View More"
        />
      </td>
      {monthOrder.map((month) => {
        const monthName = month.split(" ")[0].toLowerCase(); // Extract the lowercase month name from the monthOrder array
        return <td align="right">{item[monthName]}</td>;
      })}
      <td>
        {item.mechanic_profile ? item.mechanic_profile.app_status : "N/A"}
      </td>
      {/* <td>{item.mechanic_profile ? item.mechanic_profile.mechcode : "N/A"}</td> */}
      <td>
        {item.mechanic_profile ? item.mechanic_profile.mechanic_name : "N/A"}
      </td>
      <td>{item.mechanic_profile ? item.mechanic_profile.mobile : "N/A"}</td>
    </tr>
  ));
  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
                <th></th>
                {monthOrder.map((month) => (
                  <th>{month}</th>
                ))}
                <th>App Status</th>
                <th>Name</th>
                <th>Mobile</th>
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3}>
          <CustomModal
            isOpen={profileModal}
            toggle={profileToggleModal}
            user_id={userId}
            onConfirm={() => console.log("Confirmed!")}
            confirmLabel="Confirm"
            data={modalData}
          >
            {/* <p>Modal Content Here</p> */}
          </CustomModal>
        </Col>
      </Row>
    </>
  );
};

export default ExpiryTable;
