import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";
const API_URL1 = "https://devcableapi.aparpower.com/api/support";
// const API_URL1 = "https://cableapi.aparpower.com/api/support";

//
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getStates = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let body = {
    active_parent_id: user.user_id,
  };
  return await axiosApi
    .post(API_URL + "state/getAllStates", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getDistricts = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return await axiosApi
    .post(API_URL + "district/getAllDistrict", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getSegments = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .get(API_URL + "constant/segmentlist", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data.data,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};

const submitSupport = async (body) => {
  return await axiosApi
    .post(API_URL1, body)
    .then((response) => {
      if (response.status === 200) {
        return {
          status: true,
          message: "Request Sent Successfully",
          code: 200,
        };
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {
  getStates,
  getDistricts,
  getSegments,
  submitSupport,
};
