import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Service from "../../../src/services/userMaster.service";
import SOservice from "../../../src/services/salesOfficer.service";

const UserCloneModal = ({
  isOpen,
  toggle,
  user,
  user_id,
  mobile_number,
  role_id,
}) => {
  //   const {role_id} = JSON.parse(localStorage.getItem("user"));
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [email, setEmail] = useState("");
  const [mobileError, setMobileError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  console.log(mobileNumber, "mobileNumber");
  const handleNameChange = (event) => {
    const { value } = event.target;
    const regex = /^[A-Za-z]+$/; // regular expression to accept only characters
    if (value === "" || regex.test(value)) {
      setName(value);
    } else {
      setName("");
    }
  };

  function handleNamePaste(event) {
    if (document.activeElement === event.target) {
      event.preventDefault();
      const text = event.clipboardData.getData("text/plain");
      const regex = /^[a-zA-Z]*$/;
      if (regex.test(text)) {
        setName(text);
      }
    }
  }

  function handleMobilePaste(event) {
    event.preventDefault();

    const text = event.clipboardData.getData("text/plain");
    const regex = /^\d{10}$/;
    if (regex.test(text)) {
      if (event.nativeEvent.type === "paste") {
        console.log("mobile", event.nativeEvent);
        setMobileNumber(text);
      }
    }
  }

  const handleNamePress = (event) => {
    if (event.target.value.length > 50) {
    }

    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  };

  const handleMobilePress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleMobileChange = (event) => {
    const value = event?.target.value;
    if (value) setMobileNumber(value);
  };

  const handleEmailChange = (event) => {
    const value = event?.target.value;
    if (value) setEmail(value);
  };

  //   validation functions

  const checkMobileUniqueness = async () => {
    if (mobileNumber) {
      const mobileData = await SOservice.checkMobileUnique({
        mobile: mobileNumber,
      });
      if (mobileData.code === 200) {
        setMobileError(null);
      } else {
        setMobileError(mobileData.message);
        setMobileNumber("");
        toast.error(mobileData.message);
      }
    } else {
      toast.error("mobileNumberis required.");
    }
  };

  const checkEmailUniqueness = async () => {
    if (email) {
      const emailData = await SOservice.checkEmailUnique({ email_id: email });
      if (emailData.code === 200) {
        setEmailError("");
      } else {
        setEmailError(emailData.message);
        setEmail("");
        toast.error(emailData.message);
      }
    } else {
      toast.error("Email is required.");
    }
  };

  const handleClone = async () => {
    if (name && mobileNumber && email) {
      const data = {
        name: name,
        mobile: mobileNumber,
        email_id: email,
        user_id: user_id,
      };
      await Service.cloneUser(data).then((cloneData) => {
        console.log(cloneData, "cloneData");
        if (cloneData.status === true) {
          toast.success(cloneData?.message);
          goBack(role_id);
        } else {
          toast.error(cloneData?.message);
        }
      });
    } else {
      toast.error("Please fill all the fields.");
    }
  };

  const goBack = (role_id) => {
    console.log(role_id, "role_id");

    // role_id === 22
    //   ? history.push("/sales-officer-list")
    //   : role_id === 16
    //   ? history.push("/state-head-list")
    //   : role_id === 19
    //   ? history.push("/regional-list")
    //   : history.push("/zone-list");
    toggle({ name, mobile_number, role_id, user_id });
    window.location.reload();
  };

  useEffect(() => {
    let timer;
    if (mobileError === null && mobileNumber?.length === 10) {
      timer = setTimeout(() => {
        checkMobileUniqueness().then((res) => {
          if (res?.status === true) {
            setMobileError("Mobile Number Already Exists");
          }
        });
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [mobileError, mobileNumber]);

  useEffect(() => {
    let timer;
    if (emailError === null && email.length > 0) {
      timer = setTimeout(() => {
        checkEmailUniqueness();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [emailError, email]);

  return (
    <Row>
      <ToastContainer />
      <Col sm={6} md={4} xl={6} className="mt-4">
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered={true}>
          <Row className=" ">
            <div>
              <i
                onClick={toggle}
                className="ri-close-line align-middle icon mx-2 float-end"
              ></i>
            </div>
            <div className="d-flex justify-content-center mx-3">
              <CardTitle className="d-flex  font-size-22">
                <p className="text-black-50">Cloning</p>{" "}
                <p className="mx-1 text-info font-size-22">{user}</p>{" "}
                {mobile_number}
              </CardTitle>
            </div>

            <div className="d-flex justify-content-center">
              <ModalBody>
                <div className="row">
                  <div className="col-md-4">
                    {" "}
                    <Label>
                      Name <span className="required text-danger">*</span>
                    </Label>
                    <AvForm className="">
                      <FormGroup className="">
                        <AvField
                          name="name"
                          // label="Name"
                          type="text"
                          value={name}
                          placeholder="Enter Name"
                          onChange={handleNameChange}
                          onKeyPress={handleNamePress}
                          onPaste={handleNamePaste}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Name is required",
                            },
                          }}
                        />
                      </FormGroup>
                    </AvForm>
                  </div>
                  <div className="col-md-4">
                    <Label>
                      Mobile Number{" "}
                      <span className="required text-danger">*</span>
                    </Label>
                    <AvForm className="">
                      <FormGroup className="">
                        <AvForm>
                          <AvField
                            name={"mobileNumber"}
                            //   label="Mobile Number"
                            type="text"
                            value={mobileNumber}
                            placeholder="Enter Mobile Number"
                            onChange={(event) => handleMobileChange(event)}
                            onKeyPress={handleMobilePress}
                            onPaste={(event) => handleMobilePaste(event)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Mobile number is required",
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                errorMessage: mobileError,
                              },
                            }}
                          />
                        </AvForm>
                      </FormGroup>
                    </AvForm>
                  </div>
                  <div className="col-md-4">
                    <Label>
                      Email <span className="required text-danger">*</span>
                    </Label>
                    <AvForm className="">
                      <FormGroup className="">
                        <AvField
                          name="email"
                          // label="email"
                          type="text"
                          value={email}
                          placeholder="Enter email"
                          onChange={(event) => handleEmailChange(event)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Email is required",
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              title: "lnvalid Email Address",
                            },
                          }}
                        />
                      </FormGroup>
                    </AvForm>
                  </div>
                </div>
                <div className="row justify-content-end mt-3">
                  <div className="col-md-4">
                    <Button
                      className="btn btn-primary btn-block float-end"
                      onClick={handleClone}
                      color="warning"
                    >
                      Clone
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </div>
          </Row>
        </Modal>
      </Col>
    </Row>
  );
};

export default UserCloneModal;
