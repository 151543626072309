import {
  EVENT_LOG,
  EVENT_FAILURE,
  HIDE_LOADER,
  SHOW_LOADER,
} from "../actions/types";

export const initialState = {
  loading: false,
  eventData: [],
  eventError: false,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_LOG:
      return {
        ...state,
        eventData: action.payload,
      };
    case EVENT_FAILURE:
      return {
        ...state,
        eventError: action.payload,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default loading;
