import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import zoneService from "../../../services/userMaster.service";
import Spinner from "../../../components/Loader/Spinner";
import MasterListTable from "../../../components/Table/MasterListTable";
import { useHistory } from "react-router-dom";

const ZoneList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const body = {
    role_id: 18,
  };
  const getZoneList = async () => {
    setLoading(true);
    await zoneService.getUserList(body).then((res) => {
      setLoading(false);
      setData(res.data);
    });
  };

  const handleAddForm = () => {
    history.push("/add-zone-head");
  };
  useEffect(() => {
    getZoneList();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between float-end">
                    <Button className="btn btn-warning" onClick={handleAddForm}>
                      Add New
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <>
                          <h4 className="card-title pt-4">Zone Head List</h4>
                          <MasterListTable
                            data={data}
                            loading={loading}
                            role_id={body.role_id}
                          />
                          {/* {
                            <div className="d-flex justify-content-center ">
                              <Pagination
                                page={currentPage}
                                setPage={setCurrentPage}
                                totalPages={totalPages}
                              />
                            </div>
                          } */}
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ZoneList;
