import React from "react";
import { Table } from "reactstrap";
import moment from "moment";

const UserActivityTable = ({ data = [], loading }) => {
  // Map each object in the data array to a table row
  //   const Data = [
  //     {
  //       session_id: "1",
  //       user_name: "John Doe",
  //       mobile: "1234567890",
  //       session_start: "2021-01-01 00:00:00",
  //       session_end: "2021-01-01 00:00:00",
  //       activity: "Login",
  //     },
  //     {
  //       session_id: "2",
  //       user_name: "John Doe",
  //       mobile: "1234567890",
  //       session_start: "2021-01-01 00:00:00",
  //       session_end: "2021-01-01 00:00:00",
  //       activity: "Dashboard",
  //     },
  //     {
  //       session_id: "3",
  //       user_name: "John Doe",
  //       mobile: "1234567890",
  //       session_start: "2021-01-01 00:00:00",
  //       session_end: "2021-01-01 00:00:00",
  //       activity: "View Profile",
  //     },
  //   ];
  const rows = data.map((item, index) => (
    <tr key={index}>
      <td>{item.session_id}</td>
      <td>{item.name}</td>
      <td>{item.mobile}</td>
      <td>{moment(item.start).format("DD-MM-YYYY hh:mm:ss a")}</td>
      <td>
        {moment(item.end) < moment() ? (
          moment(item.end).format("DD-MM-YYYY hh:mm:ss a")
        ) : (
          <span className="text-success">Active</span>
        )}
      </td>
      <td>
        {moment(item.end) < moment()
          ? moment(item.end).diff(moment(item.start), "minutes")
          : moment().diff(moment(item.start), "minutes")}
      </td>
      <td>{item.activity.join(", ")}</td>
    </tr>
  ));

  // Render the table with the mapped rows and cells
  return (
    <div className="table-rep-plugin pt-4">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" striped bordered responsive>
          <thead>
            <tr>
              <th>Session Id</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Session Start</th>
              <th>Session End</th>
              <th>Duration(Minutes)</th>
              <th>Activity</th>

              {/* Add more headers for each property in the objects */}
            </tr>
          </thead>
          {data?.length === 0 && loading === false ? (
            <tbody>
              <tr>
                <td colSpan="9" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>{rows}</tbody>
          )}
        </Table>
      </div>
    </div>
  );
};

export default UserActivityTable;
