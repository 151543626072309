import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../services/salesOfficer.service";
import Service from "../../../services/userMaster.service";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useHistory } from "react-router-dom";

const EditSO = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);

  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [selectedPincodes, setSelectedPincodes] = useState([]);

  const [areaOptions, setAreaOptions] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const [ZoneHeadOption, setZoneHeadOption] = useState([]);
  const [selectedZoneHead, setSelectedZoneHead] = useState([]);

  const [regionalHeadOption, setRegionalHeadOption] = useState([]);
  const [selectedRegionalHead, setSelectedRegionalHead] = useState([]);

  const [stateHeadOption, setStateHeadOption] = useState([]);
  const [selectedStateHead, setSelectedStateHead] = useState([]);

  const [inputPincode, setInputPincode] = useState("");
  const [pincode, setPincode] = useState([]);

  const [isRegionalHeadDisabled, setIsRegionalHeadDisabled] = useState(true);
  const [isStateHeadDisabled, setIsStateHeadDisabled] = useState(true);

  const [isDistrictSelectDisabled, setIsDistrictSelectDisabled] =
    useState(true);
  const [isPincodeSelectDisabled, setIsPincodeSelectDisabled] = useState(true);
  const [isStateDisabled, setIsStateDisabled] = useState(true);

  // form data to create sales officer body for api
  const formData = {
    name: name,
    mobile: mobile,
    user_id: window.location.pathname.split("/")[2],

    active_zone_head_id: selectedZoneHead?.id,
    active_zone_head_name: selectedZoneHead?.value,
    active_regional_head_id: selectedRegionalHead?.id,
    active_regional_head_name: selectedRegionalHead?.value,
    active_state_head_id: selectedStateHead?.id,
    active_state_head_name: selectedStateHead?.value,

    state_id: [selectedStates?.id],
    stateName: [selectedStates?.label],

    district_id: selectedDistricts?.map((item) => item.district_id),
    district: selectedDistricts?.map((item) => item.value),

    pincode_id: selectedPincodes?.map((item) => item.id),
    // Array.from(new Set(pincode?.map((item)=>item.id).concat(selectedPincodes?.map((item) => item.value.toString()))))
    // pincode: selectedPincodes?.map((item) => item.value.toString()),
    pincode: Array.from(
      new Set(
        pincode
          ?.map((item) => item.value.toString())
          .concat(selectedPincodes?.map((item) => item.value.toString()))
      )
    ),
    city_id: selectedAreas?.map((item) => item.id),
    city: selectedAreas?.map((item) => item.value),

    action_by: {
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
      name: JSON.parse(localStorage.getItem("user"))?.name,
    },

    email_id: email,
    send_confirmation_email: true,
    status: "Active",
    role_id: 22,
  };

  console.log("formData", formData);
  const handleClose = () => {
    // history.push("/sales-officer-list");
    history.goBack();
  };
  const createOption = (label) => ({
    label,
    value: label,
  });

  const fetchUserByid = async () => {
    const id = window.location.pathname.split("/")[2];
    const body = {
      user_id: id,
      role_id: 22,
    };
    const response = await Service.getUserById(body);
    const value = response?.data?.stateName[0].toUpperCase();
    const label = response?.data?.stateName[0].toUpperCase();
    const state_id = response?.data?.state_id[0];
    setName(response?.data?.name);
    setMobile(response?.data?.mobile);
    setEmail(response?.data?.email_id);
    if (response?.data?.active_zone_head_id) {
      await fetchRegionalHead(response?.data?.active_zone_head_id);
      setSelectedZoneHead({
        // zone head
        id: response?.data?.active_zone_head_id,
        value: response?.data?.active_zone_head_name,
        label: response?.data?.active_zone_head_name,
      });
    }
    if (response?.data?.active_regional_head_id) {
      setIsRegionalHeadDisabled(false);
      await fetchStateHead(response?.data?.active_regional_head_id);
      setSelectedRegionalHead({
        // regional head
        id: response?.data?.active_regional_head_id,
        value: response?.data?.active_regional_head_name,
        label: response?.data?.active_regional_head_name,
      });
    }
    if (response?.data?.active_state_head_id) {
      setIsStateHeadDisabled(false);
      await fetchStates(response?.data?.active_state_head_id);
      setSelectedStateHead([
        {
          // state head
          id: response?.data?.active_state_head_id,
          value: response?.data?.active_state_head_name,
          label: response?.data?.active_state_head_name,
        },
      ]);
    }
    setIsStateDisabled(false);
    if (response?.data?.stateName?.length > 0) {
      await fetchDistricts(response?.data?.state_id[0]);
      setSelectedStates({
        // state
        value: value,
        label: label,
        id: state_id,
      });
    }
    setIsDistrictSelectDisabled(false);
    if (response?.data?.district_id) {
      await fetchPincodes(response?.data?.district);
      setSelectedDistricts(
        response?.data?.district.map((item) => ({
          // district
          district_id: item,
          value: item,
          label: item,
        }))
      );
    }
    if (response?.data?.pincode?.length > 0) {
      setIsPincodeSelectDisabled(false);

      await fetchAreas(response?.data?.pincode);
      setSelectedPincodes(
        response?.data?.pincode.map((item) => ({
          // pincode
          value: item,
          label: item,
        }))
      );
    }
    if (response?.data?.city) {
      setSelectedAreas(
        response?.data?.city.map((item) => ({
          // area
          id: item,
          value: item,
          label: item,
        }))
      );
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is a number or comma
    if (event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;
      const values = value.split(",").map((val) => parseInt(val.trim()));
      for (let i = 0; i < values.length; i++) {
        setPincode((prev) => [...prev, createOption(values[i])]);
      }
      setInputPincode("");
    } else if (/^[0-9,]$/.test(event.key)) {
      return true;
    }
    // else {
    //   event.preventDefault();
    //   return false;
    // }
  };
  const handleNameChange = (event) => {
    const { value } = event.target;
    const regex = /^[A-Za-z]+$/; // regular expression to accept only characters
    if (value === "" || regex.test(value)) {
      setName(value);
    }
  };
  const handleNamePress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  };
  const handleMobilePress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const handleMobileChange = (event) => {
    const value = event?.target.value;
    if (value) setMobile(value);
  };
  const handleEmailChange = (event) => {
    const value = event?.target.value;
    if (value) setEmail(value);
  };
  //   validation functions
  const checkMobileUniqueness = async () => {
    if (mobile) {
      const mobileData = await service.checkMobileUnique({ mobile: mobile });
      if (mobileData.code === 200) {
        setMobileError("");
      } else {
        setMobileError(mobileData.message);
        toast.error(mobileData.message);
      }
    } else {
      toast.error("mobile is required.");
    }
  };
  const checkEmailUniqueness = async () => {
    if (email) {
      const emailData = await service.checkEmailUnique({ email_id: email });
      if (emailData.code === 200) {
        setEmailError("");
      } else {
        setEmailError(emailData.message);
        toast.error(emailData.message);
      }
    } else {
      toast.error("Email is required.");
    }
  };

  // fetch functions on onchange to fetch states, districts, pincodes, areas, zonehead, regionalhead, statehead
  const handleStateChange = async (selectedOption) => {
    setSelectedPincodes([]);
    setPincodeOptions([]);
    setSelectedAreas([]);
    setSelectedDistricts([]);
    setSelectedStates(selectedOption);
    setIsDistrictSelectDisabled(false);
    setIsPincodeSelectDisabled(true);
    const state_ids = selectedOption?.id;
    // await fetchDistricts(state_ids);
    const district_ids = [];
    await fetchPincodes(district_ids, state_ids);
    // await fetchAreas(district_ids, state_ids);
    if (selectedOption.length === 0) {
      setDistrictOptions([]);
      setPincodeOptions([]);
      setSelectedPincodes([]);
      setAreaOptions([]);
      setSelectedAreas([]);
      setSelectedDistricts([]);
      setIsDistrictSelectDisabled(true);
      setIsPincodeSelectDisabled(true);
    }
  };

  const handleDistrictChange = async (selectedOption) => {
    setIsPincodeSelectDisabled(false);
    setSelectedDistricts(selectedOption);
    // formData.district_id = selectedDistricts.map((item) => item.district_id);
    formData.district = selectedDistricts.map((item) => item.value);
    const district_ids = selectedOption?.map(
      (district) => district.district_id
    );
    formData.district_id = district_ids;
    const state_ids = selectedStates?.id;
    await fetchPincodes(district_ids, state_ids);
    await fetchAreas(district_ids, state_ids);
    if (selectedOption.length === 0) {
      setSelectedPincodes([]);
      setSelectedAreas([]);
      setIsPincodeSelectDisabled(true);
    }
  };

  const handlePincodeChange = (selectedOption) => {
    setSelectedPincodes(selectedOption);
    formData.pincode_id = selectedOption?.map((item) => item.value);
    formData.pincode = selectedOption?.map((item) => item.value);
  };

  const handleAreaChange = (selectedOption) => {
    setSelectedAreas(selectedOption);
    formData.city = selectedOption?.map((item) => item.value);
    formData.city_id = selectedOption?.map((item) => item.id);
  };

  const handleZoneHead = async (selectedOption) => {
    setSelectedRegionalHead([]);
    setSelectedStateHead([]);
    setIsStateHeadDisabled(true);
    setIsRegionalHeadDisabled(true);
    setSelectedZoneHead(selectedOption);
    const zoneHeadId = selectedOption.id;

    await fetchRegionalHead(zoneHeadId).then(() => {
      setIsRegionalHeadDisabled(false);
    });
  };

  const handleStateHead = async (selectedOption) => {
    setSelectedStates([]);
    setSelectedStateHead(selectedOption);
    console.log(selectedOption);
    // formData.active_state_head_id = selectedOption?.map((item) => item.id);
    // formData.active_state_head_name = selectedOption?.map((item) => item.value);
    const state_head_id = selectedOption?.id;
    await fetchStates(state_head_id).then((res) => {
      if (res) {
      }
      setIsStateDisabled(false);
    });
    if (selectedOption.length === 0) {
      setIsStateDisabled(true);
    }
  };

  const handleRegionalHead = async (selectedOption) => {
    setSelectedStateHead([]);
    setSelectedRegionalHead(selectedOption);
    const regionalHeadId = selectedOption?.id;
    await fetchStateHead(regionalHeadId).then(() => {
      setIsStateHeadDisabled(false);
    });
  };

  // fetch functions to fetch states, districts, pincodes, areas, zonehead, regionalhead, statehead
  const fetchStates = async (state_head_id) => {
    const stateparams = {
      active_parent_id: state_head_id,
    };

    const stateData = await service.getStates(stateparams);
    const options = stateData?.data?.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setStateOptions(options);
    // setSelectedStates(options);
  };

  const fetchDistricts = async (stateIds) => {
    const params = {
      state_id: [stateIds],
    };
    const districtData = await service.getDistricts(params);
    const options = districtData.data.map((district) => ({
      district_id: district.id,
      value: district.name,
      label: district.name,
    }));
    setDistrictOptions(options);
    setSelectedDistricts(options);
    params.district_id = options?.map((district) => district.district_id);
    await fetchAreas(params.district_id, params.state_id);
  };

  const fetchPincodes = async (districtIds, stateIds) => {
    const params = {
      state_id: [stateIds],
      district_id: districtIds?.length > 0 ? districtIds : [],
    };
    const pinData = await service.getPincodes(params);
    if (pinData?.status === true) {
      const options = pinData?.data.map((pin) => ({
        value: pin.pincode,
        label: pin.pincode,
      }));
      setPincodeOptions(options);
      setSelectedPincodes(options);
    } else {
      toast.error("No pincode Found");
    }
  };

  const fetchAreas = async (district_ids, state_ids) => {
    const params = {
      state_id: [state_ids],
      district_id: district_ids,
    };
    if (state_ids !== 0 && district_ids?.length !== 0) {
      const cityData = await service.getCities1(params);
      const options = cityData?.data.map((city) => ({
        id: city.id,
        value: city.name,
        label: city.name,
      }));

      setAreaOptions(options);
      setSelectedAreas(options);
    } else {
      //   toast.error("please select state and district first. ");
    }
  };

  const fetchZoneHead = async () => {
    const body = {
      role_id: 18,
      parent_id: "",
    };
    const zoneHeadData = await Service.getAllUserList(body);
    const options = zoneHeadData.data.map((zoneHead) => ({
      value: zoneHead.name,
      label: zoneHead.name,
      id: zoneHead.userid,
    }));
    setZoneHeadOption(options);
  };

  const fetchRegionalHead = async (parent_ids) => {
    const body = {
      role_id: 19,
      parent_id: parent_ids,
    };
    await Service.getAllUserList(body).then((res) => {
      if (res?.data.length !== 0) {
        setIsRegionalHeadDisabled(false);
        const options = res?.data?.map((regionalHead) => ({
          value: regionalHead.name,
          label: regionalHead.name,
          id: regionalHead.userid,
        }));
        setRegionalHeadOption(options);
      } else {
        toast.error("No Regional Head Found");
      }
    });
  };

  const fetchStateHead = async (parent_ids) => {
    const body = {
      role_id: 16,
      parent_id: parent_ids,
    };
    await Service.getAllUserList(body).then((res) => {
      if (res?.data.length !== 0) {
        setIsStateHeadDisabled(false);
        const options = res.data.map((stateHead) => ({
          value: stateHead.name,
          label: stateHead.name,
          id: stateHead.userid,
        }));
        setStateHeadOption(options);
      } else {
        toast.error("No State Head Found");
      }
    });
  };
  //   function to handle submit and reset form
  const handleSubmit = async (event, errors, values) => {
    if (
      formData.name !== "" &&
      formData.mobileNumber !== null &&
      formData.email !== "" &&
      formData.stateName.length !== 0 &&
      // formData.district.length !== 0 &&
      formData.pincode.length !== 0 &&
      formData.active_regional_head_id !== undefined &&
      formData.active_zone_head_id !== undefined &&
      formData.active_state_head_id !== undefined
    ) {
      await Service.updateUser(formData).then((res) => {
        if (res?.status === true) {
          toast.success("User Updated Successfully");
          history.push("/sales-officer-list");
        }
      });
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const reset = () => {
    setDistrictOptions([]);
    setPincodeOptions([]);
    setSelectedPincodes([]);
    setName("");
    setMobile("");
    setEmail("");
    setSelectedStates([]);
    setSelectedStateHead([]);
    setSelectedRegionalHead([]);
    setSelectedZoneHead([]);
    setStateHeadOption([]);
    setRegionalHeadOption([]);
  };

  useEffect(() => {
    // fetchStates();
    fetchUserByid();
    fetchZoneHead();
  }, []);

  useEffect(() => {
    let timer;
    if (mobileError === null && mobile.length === 10) {
      timer = setTimeout(() => {
        checkMobileUniqueness();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [mobileError, mobile]);

  useEffect(() => {
    let timer;
    if (emailError === null && email.length > 0) {
      timer = setTimeout(() => {
        checkEmailUniqueness();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [emailError, email]);

  if (!selectedStates) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <button
                className="btn btn-secondary float-left mb-3"
                type="submit"
                onClick={handleClose}
                color="primary"
              >
                Back
              </button>
              <Card>
                <CardBody>
                  <div className="pb-4">Edit-Sales Officer</div>
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <Label>
                        Name <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvField
                            name="name"
                            // label="Name"
                            type="text"
                            value={name}
                            placeholder="Enter Name"
                            onChange={handleNameChange}
                            onKeyPress={handleNamePress}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Name is required",
                              },
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </div>
                    <div className="col-md-4">
                      <Label>
                        Mobile Number{" "}
                        <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvForm>
                            <AvField
                              name={"mobile"}
                              //   label="Mobile Number"
                              type="text"
                              value={mobile}
                              placeholder="Enter Mobile Number"
                              onChange={(event) => handleMobileChange(event)}
                              onKeyPress={handleMobilePress}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Mobile number is required",
                                },
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  errorMessage: mobileError,
                                },
                              }}
                            />
                          </AvForm>
                        </FormGroup>
                      </AvForm>
                    </div>
                    <div className="col-md-4">
                      <Label>
                        Email <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvField
                            name="email"
                            // label="email"
                            type="text"
                            value={email}
                            placeholder="Enter email"
                            onChange={(event) => handleEmailChange(event)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Email is required",
                              },
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                title: "lnvalid Email Address",
                              },
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          Zone Head{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div>
                          <Select
                            options={ZoneHeadOption}
                            value={selectedZoneHead}
                            onChange={(e) => handleZoneHead(e)}
                            placeholder="Select Zone Head"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            // controlShouldRenderValue={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          Regional Head{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div>
                          <Select
                            options={regionalHeadOption}
                            value={selectedRegionalHead}
                            onChange={(e) => handleRegionalHead(e)}
                            placeholder="Select Regional Head"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isRegionalHeadDisabled}
                          />
                          {isRegionalHeadDisabled === true ? (
                            <span className="text-danger">
                              Please select zone head first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          State Head{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div>
                          <Select
                            // isMulti
                            options={stateHeadOption}
                            value={selectedStateHead}
                            onChange={(e) => handleStateHead(e)}
                            placeholder="Select State Head"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isStateHeadDisabled}
                            // styles={customStyles}
                          />

                          {isStateHeadDisabled === true ? (
                            <span className="text-danger">
                              Please select regional head first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          State <span className="required text-danger">*</span>
                        </Label>
                        <div>
                          <Select
                            options={stateOptions}
                            value={selectedStates}
                            onChange={(e) => handleStateChange(e)}
                            placeholder="Select State"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isStateDisabled}

                            // controlShouldRenderValue={false}
                          />

                          {isStateDisabled === true ? (
                            <span className="text-danger">
                              Please select state head first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="">
                        <Label>
                          District{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div
                        //   className={`${
                        //     selectedDistricts.length === 0
                        //       ? "height-auto"
                        //       : "height-fixed"
                        //   }`}
                        >
                          <Select
                            isMulti
                            options={districtOptions}
                            value={selectedDistricts}
                            onChange={(e) => handleDistrictChange(e)}
                            placeholder="Select District"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isDistrictSelectDisabled}
                          />
                          {isDistrictSelectDisabled === true ? (
                            <span className="text-danger">
                              Please select state first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          Pincode{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div
                          className={`${
                            selectedPincodes.length === 0
                              ? "height-auto"
                              : "height-fixed"
                          }`}
                        >
                          <Select
                            isMulti
                            options={pincodeOptions}
                            value={selectedPincodes}
                            onChange={(e) => handlePincodeChange(e)}
                            placeholder="Select Pincode"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isPincodeSelectDisabled}
                            // styles={customStyles}
                          />

                          {isPincodeSelectDisabled === true ? (
                            <span className="text-danger">
                              Please select district first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {/* <div className="col-md-4">
                      <div className="">
                        <Label>Area</Label>
                        <div
                        //   className={`${
                        //     selectedAreas?.length === 0 ? "height-auto" : "h-50"
                        //   }`}
                        >
                          <Select
                            isMulti
                            options={areaOptions}
                            value={selectedAreas}
                            onChange={(e) => handleAreaChange(e)}
                            placeholderButton="colors"
                            placeholder="Select Area"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-8">
                      <div className="">
                        <Label>Pincode</Label>
                      </div>
                      <CreatableSelect
                        inputValue={inputPincode}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(newValue) => setPincode(newValue)}
                        onInputChange={(newValue) => setInputPincode(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type single pincode or type comma separated pincodes and press enter..."
                        value={pincode}
                      />
                      {/* {stateOptions.length === 0 ? null : (
                        <CustomSelect options={stateOptions} />
                      )} */}
                    </div>
                  </div>
                  <div className="row mt-3 float-end">
                    <div className="col-12">
                      <Button
                        onClick={handleSubmit}
                        className="btn btn-warning mx-3"
                      >
                        Submit
                      </Button>
                      <Button
                        type="submit"
                        onClick={fetchUserByid}
                        className="btn btn-danger"
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditSO;
