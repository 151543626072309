import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from '../src/store/index';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const app = (
    <Provider store={store}>
        <BrowserRouter>
        <ToastContainer />
            <App layout={{layoutType:"horizontal"}}/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();