import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import Select from "react-select";
//Import Breadcrumb
import redemptionService from "../../services/redemption.service";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange } from "react-date-range";
import Pagination from "../../components/Table/Pagination";
import RedemptionSoDbtTable from "../../components/Table/RedemptionSoDbtTable";

const Redemption = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);
  const user_id = user ? user.user_id : null;
  const role = user ? user.role_id : null;

  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date(); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [soList, setSOList] = useState([]);
  const [selectedSO, setSelectedSO] = useState(null);

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  let body = {
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    offset: currentPage,
    limit: pageSize,
    user_id: role === 22 ? user_id : selectedSO?.value,
    regional_mapping: master ? master.regional_mapping : null,
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const submitDates = async () => {
    dispatch(showLoader());
    setIsOpenFirstmonth(false);

    if (soList.lenght !== 0) {
      const redemption_data = await redemptionService.GetSODbtStatus(body);
      if (redemption_data.status === true) {
        setTotalPages(redemption_data.data.pagination.totalPages);
        setData(redemption_data.data.data);
        dispatch(hideLoader());
      } else if (redemption_data.status === false) {
        setData([]);
        dispatch(hideLoader());
      }
    } else {
      dispatch(hideLoader());
    }
  };

  const Reset = () => {
    body.start_date = moment(initialDateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    body.end_date = moment(initialDateRange[0].endDate).format("YYYY-MM-DD");
    body.user_id = role === 22 ? user_id : soList[0]?.value;
    setDateRange(initialDateRange);
    setCurrentPage(1);
    submitDates();
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };

  const fetchSO = async () => {
    const params = { user_id: user_id };
    const list = await redemptionService.GetSOList(params);
    if (list.status === true) {
      setSOList(list.data.data);
      setSelectedSO(list.data.data[0]);
      body.user_id = list.data.data[0]?.value;
      submitDates();
    }
  };

  const handleSO = async (e) => {
    setSelectedSO(e);
  };

  useEffect(() => {
    fetchSO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    submitDates();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content mt-n5">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        {isOpenfirstMonth === false ? (
                          <div>
                            <label className="form-label">Select Dates: </label>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {`${new Date(body.start_date).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )} - ${new Date(body.end_date).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}`}
                            </button>
                          </div>
                        ) : (
                          <div className="">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={new Date()}
                                minDate={new Date(moment("2021-01-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                      {role === 22 ? null : (
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="">
                            <label className="form-label">Select SO: </label>
                            <Select
                              options={soList}
                              value={selectedSO}
                              onChange={(e) => handleSO(e)}
                              placeholder="Select options"
                              noOptionsMessage={() => "No options found"}
                              isSearchable
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="margin-top d-flex">
                          <Button
                            className="btn-warning"
                            onClick={() => submitDates()}
                          >
                            Search
                          </Button>
                          <Button
                            className="btn btn-danger btn-secondary margin-right"
                            onClick={() => Reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <>
                          <h4 className="card-title pt-4">
                            {`Redemption status in SO area between ${moment(
                              body.start_date
                            ).format("MMM DD, YYYY")} - ${moment(
                              body.end_date
                            ).format("MMM DD, YYYY")}`}
                          </h4>
                          <RedemptionSoDbtTable data={data} loading={loading} />
                          <div className="d-flex justify-content-center ">
                            <Pagination
                              page={currentPage}
                              setPage={setCurrentPage}
                              totalPages={totalPages}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Redemption;
