import axios from "axios";
import { CABEL_URL } from "../Config/config";
import authService from "./auth.service";

const axiosApi = axios.create({
    baseURL: CABEL_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const GetRewardList = async (body) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    if(token){
        axiosApi.defaults.headers.common["Authorization"] = `Bearer SKIP`;
    }
    body.user_id = user.user_id.toString();
    body.role_id = user.role_id;


    return await axiosApi
        .post(CABEL_URL + "rewards/rewards", body)
        .then((response) => {
            if (response.data.status === "success") {
              return { status: true, data: response.data };
            } else if (response.data.status === "fail") {
              return { status: false };
            }
          })
          .catch((err) => {
            if (err?.response?.status === 403) {
              authService.autoLogout();
              window.location.href = "/login";
            }
          });
}
export default { GetRewardList };