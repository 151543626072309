import { EVENT_LOG, EVENT_FAILURE, SHOW_LOADER, HIDE_LOADER } from "./types";

export const showLoader = () => {
  return {
    type: SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  };
};
