import React, { useState, useRef } from "react";
import { Col, Row, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const XLSXFileUploadModal = ({ isOpen, toggle, modalHeader, handleFileUpload }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('No File Selected');
  const [hasError, setHasError] = useState(false);
  const fileInput = useRef();
  
  const handleClose = async () => {
    setFile(null);
    setFileName('No File Selected');
    setHasError(false);
    toggle();
  };
  
  const handleUploadClick = async () => {
    if (!file) {
      setHasError(true);
    } else {
      const response = await handleFileUpload(file);
      console.log(response);
      setHasError(false);
      handleClose();
    }
  };
  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : 'No File Selected');
    setHasError(false);
  };

  const triggerFileInput = () => {
    fileInput.current.click();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalHeader toggle={handleClose}>{modalHeader}</ModalHeader>
        <ModalBody>
          <Row className="mb-4">
            <Col md={6} sm={6} xs={6}>
              <Label for="xlsx_input_file" className="col-form-label">Upload XLSX File</Label>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <Input
                id="xlsx_input_file"
                innerRef={fileInput}
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".xlsx"
                aria-required="true"
                aria-label="XLSX File Input"
              />
              {/* Here we are displaying the file's name */}
              <p aria-live="polite">{fileName}</p> 
              <Button onClick={triggerFileInput}>Choose File</Button>
            </Col>
          </Row>
          {hasError && <div style={{ color: "red" }}>File is required.</div>}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="success" onClick={handleUploadClick}>Upload</Button>
          <Button type="button" color="light" onClick={handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default XLSXFileUploadModal;