import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CloseIcon from '@mui/icons-material/Close';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CatalogueModal = ({
  isOpen,
  toggle,
  modalHeader,
  handleAPI,
  reportName,
  setReportName,
  closeModal,
  products
}) => {
  
  const [reportNameError, setReportNameError] = useState("");

  const handleSubmit = async () => {
    if (!reportName) {
      setReportNameError("Report Name is required.");
    } else {
      setReportNameError("");
      handleAPI(reportName);
      handleClose();
    }
  };
  const handleClose = async () => {
    setReportName("");
    setReportNameError("");
    toggle();
  };

  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
    if (e.target.value.trim() !== "") {
      setReportNameError("");
    } else {
      setReportNameError("Report Name is required.");
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const customStyles = {
    modal: {
      margin: 'auto',
      maxWidth: '75vw', // Adjust the value as needed
      width: '65vw',
    },
    modalBody: {
      display:'flex',
      justifyContent: 'center',
      alignItem:'center',
      flexDirection: 'column',
      // margin: '0 auto',
      height: '85vh',
      padding: '10px',
    },
    modalImages: {
        width: '100%',
        height: '100%',
        objectFit: "contain"
      },
  };

  return (
    <>
      <Modal style={customStyles.modal} isOpen={isOpen} toggle={toggle} backdrop="static">
        <div style={{display:'flex',justifyContent:'flex-end', alignItems:'flex-end'}}>
            <CloseIcon onClick={closeModal} sx={{color: 'red', cursor: 'pointer', marginRight: '10px', marginTop: '10px', fontSize: '30px'}} />
        </div>
        {/* <ModalHeader>{products.rewardname}</ModalHeader> */}
        <ModalBody style={customStyles.modalBody}>
          <Row className="mb-4">
            <Col
              md={1}
              sm={1}
              xs={1}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItem: "center",
              }}
            >
              <Carousel responsive={responsive} showDots={true} >
                <div
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                >
                  <img style={customStyles.modalImages}
                    src={products.image}
                    alt=""
                  />
                </div>
                {/* <div
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                >
                  <img style={customStyles.modalImages}
                    src={products.image}
                    alt=""
                  />
                </div> */}
                {/* <div
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                >
                  <img style={customStyles.modalImages}
                    src={products.image}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                >
                  <img style={customStyles.modalImages}
                    src={products.image}
                    alt=""
                  />
                </div> */}
              </Carousel>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                  marginTop: '20px'
                }}
              >
                <h4>{products.rewardname}</h4>
                
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <p>Reward Code : {products.rewardcode}</p>
              </div>
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <p>Program : {products.is_rlp}</p>
              </div> */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <p>Reward Type : {products.reward_type}</p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <p>Points : {products.points}</p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <p>
                Description : {products.description}
                </p>
              </div>
            </Col>
          </Row>
          
        </ModalBody>
      </Modal>
    </>
  );
};

export default CatalogueModal;
