import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../services/salesOfficer.service";
import Service from "../../../services/userMaster.service";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useHistory } from "react-router-dom";

const AddRegionlHead = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [mobileError, setMobileNumberError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [selectedPincodes, setSelectedPincodes] = useState([]);

  const [ZoneHeadOption, setZoneHeadOption] = useState([]);
  const [selectedZoneHead, setSelectedZoneHead] = useState([]);

  const [inputPincode, setInputPincode] = useState("");
  const [pincode, setPincode] = useState([]);

  const [isPincodeSelectDisabled, setIsPincodeSelectDisabled] = useState(true);
  const [isStateDisabled, setIsStateDisabled] = useState(true);

  // form data to create sales officer body for api
  const formData = {
    name: name,
    mobile: mobileNumber,

    active_zone_head_id: selectedZoneHead?.id,
    active_zone_head_name: selectedZoneHead?.value,
    active_regional_head_id: "",
    active_regional_head_name: "",
    active_state_head_id: "",
    active_state_head_name: "",

    state_id: selectedStates?.map((state) => state.id),
    stateName: selectedStates?.map((state) => state.value),

    district_id: [],
    district: [],

    pincode_id: selectedPincodes?.map((item) => item.value),
    // Array.from(new Set(pincode?.map((item)=>item.id).concat(selectedPincodes?.map((item) => item.value.toString()))))
    // pincode: selectedPincodes?.map((item) => item.value.toString()),
    pincode: Array.from(
      new Set(
        pincode
          ?.map((item) => item.value.toString())
          .concat(selectedPincodes?.map((item) => item.value.toString()))
      )
    ),
    city_id: [],
    city: [],

    email_id: email,
    send_confirmation_email: true,
    status: "Active",
    role_id: 16,
  };
  console.log(formData);

  const handleClose = () => {
    history.push("/regional-list");
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    // Check if the pressed key is a number or comma
    if (event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;
      const values = value.split(",").map((val) => parseInt(val.trim()));
      for (let i = 0; i < values.length; i++) {
        setPincode((prev) => [...prev, createOption(values[i])]);
      }
      setInputPincode("");
    } else if (/^[0-9,]$/.test(event.key)) {
      return true;
    }
    //  else {
    //   event.preventDefault();
    //   return false;
    // }
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    const regex = /^[A-Za-z]+$/; // regular expression to accept only characters
    if (value === "" || regex.test(value)) {
      setName(value);
    } else {
      setName("");
    }
  };

  function handleNamePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(text)) {
      setName(text);
    }
  }
  function handleMobilePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const regex = /^\d{10}$/;
    if (regex.test(text)) {
      setMobileNumber(text);
    }
  }
  const handleNamePress = (event) => {
    if (event.target.value.length > 50) {
    }

    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  };

  const handleMobilePress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const handleMobileChange = (event) => {
    const value = event?.target.value;
    if (value) setMobileNumber(value);
  };

  const handleEmailChange = (event) => {
    const value = event?.target.value;
    if (value) setEmail(value);
  };

  //   validation functions
  const checkMobileUniqueness = async () => {
    if (mobileNumber) {
      const mobileData = await service.checkMobileUnique({
        mobile: mobileNumber,
      });
      if (mobileData.code === 200) {
        setMobileNumberError(null);
      } else {
        setMobileNumberError(mobileData.message);
        setMobileNumber("");
        console.log(setMobileNumber);
        toast.error(mobileData.message);
      }
    } else {
      toast.error("mobileNumber is required.");
    }
  };

  const checkEmailUniqueness = async () => {
    if (email) {
      const emailData = await service.checkEmailUnique({ email_id: email });
      if (emailData.code === 200) {
        setEmailError("");
      } else {
        setEmailError(emailData.message);
        setEmail("");
        toast.error(emailData.message);
      }
    } else {
      toast.error("Email is required.");
    }
  };
  // fetch functions on onchange to fetch states, districts, pincodes, areas, zonehead, regionalhead, statehead
  const handleStateChange = async (selectedOption) => {
    setSelectedPincodes([]);
    setPincodeOptions([]);
    setSelectedStates(selectedOption);
    setIsPincodeSelectDisabled(true);
    console.log(selectedOption);
    const state_ids = selectedOption?.map((state) => state.id);
    const district_ids = [];
    await fetchPincodes(district_ids, state_ids);
    if (selectedOption.length === 0) {
      setPincodeOptions([]);
      setSelectedPincodes([]);
      setIsPincodeSelectDisabled(true);
    }
  };

  const handlePincodeChange = (selectedOption) => {
    setSelectedPincodes(selectedOption);
    formData.pincode_id = selectedOption?.map((item) => item.value);
    formData.pincode = selectedOption?.map((item) => item.value);
  };

  const handleZoneHead = async (selectedOption) => {
    setSelectedZoneHead(selectedOption);
    const zonehead_id = selectedOption?.id;

    await fetchStates(zonehead_id).then(() => {
      setIsStateDisabled(false);
    });
  };

  // fetch functions to fetch states, districts, pincodes, areas, zonehead, regionalhead, statehead
  const fetchStates = async (parent_id) => {
    const stateparams = {
      active_parent_id: parent_id,
    };

    const stateData = await service.getStates(stateparams);
    console.log(stateData);
    const options = stateData?.data?.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setStateOptions(options);
  };

  const fetchPincodes = async (districtIds, stateIds) => {
    const params = {
      state_id: stateIds,
      district_id: districtIds?.length > 0 ? districtIds : [],
    };
    const pinData = await service.getPincodes(params);
    if (pinData?.status === true) {
      setIsPincodeSelectDisabled(false);
      const options = pinData?.data.map((pin) => ({
        value: pin.pincode,
        label: pin.pincode,
      }));
      setPincodeOptions(options);
      setSelectedPincodes(options);
    } else {
      toast.error("No pincode Found");
    }
  };

  const fetchZoneHead = async () => {
    const body = {
      role_id: 18,
      parent_id: "",
    };
    const zoneHeadData = await Service.getAllUserList(body);
    const options = zoneHeadData?.data?.map((zoneHead) => ({
      value: zoneHead.name,
      label: zoneHead.name,
      id: zoneHead.userid,
    }));
    setZoneHeadOption(options);
  };

  //   function to handle submit and reset form
  const handleSubmit = async (event, errors, values) => {
    if (
      formData.name !== "" &&
      formData.mobileNumber !== null &&
      formData.email !== "" &&
      formData.stateName.length !== 0 &&
      formData.pincode.length !== 0 &&
      formData.active_zone_head_id !== undefined
    ) {
      await Service.addUser(formData).then((res) => {
        console.log(res, "res");
        if (res?.status === true) {
          toast.success("User Added Successfully");

          history.push("/regional-list");
        }
      });
    } else {
      toast.error("Please fill all the fields");
    }
  };
  const reset = () => {
    setPincodeOptions([]);
    setSelectedPincodes([]);
    setName("");
    setMobileNumber("");
    setEmail("");
    setSelectedStates([]);
    setSelectedZoneHead([]);
  };

  useEffect(() => {
    // fetchStates();
    fetchZoneHead();
  }, []);

  useEffect(() => {
    let timer;
    if (mobileError === null && mobileNumber.length === 10) {
      timer = setTimeout(() => {
        checkMobileUniqueness().then((res) => {
          if (res?.status === true) {
            setMobileNumberError("Mobile Number Already Exists");
          }
        });
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [mobileError, mobileNumber]);

  useEffect(() => {
    let timer;
    if (emailError === null && email.length > 0) {
      timer = setTimeout(() => {
        checkEmailUniqueness();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [emailError, email]);

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <button
                className="btn btn-secondary float-left mb-3"
                type="submit"
                onClick={handleClose}
                color="primary"
              >
                Back
              </button>
              <Card>
                <CardBody>
                  <div className="pb-4">Add-Regional Head</div>
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <Label>
                        Name <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvField
                            name="name"
                            // label="Name"
                            type="text"
                            value={name}
                            placeholder="Enter Name"
                            onChange={handleNameChange}
                            onKeyPress={handleNamePress}
                            onPaste={handleNamePaste}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Name is required",
                              },
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </div>
                    <div className="col-md-4">
                      <Label>
                        Mobile Number{" "}
                        <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvForm>
                            <AvField
                              name={"mobileNumber"}
                              //   label="Mobile Number"
                              type="text"
                              value={mobileNumber}
                              placeholder="Enter Mobile Number"
                              onChange={(event) => handleMobileChange(event)}
                              onKeyPress={handleMobilePress}
                              onPaste={handleMobilePaste}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Mobile number is required",
                                },
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  errorMessage: mobileError,
                                },
                              }}
                            />
                          </AvForm>
                        </FormGroup>
                      </AvForm>
                    </div>
                    <div className="col-md-4">
                      <Label>
                        Email <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvField
                            name="email"
                            // label="email"
                            type="text"
                            value={email}
                            placeholder="Enter email"
                            onChange={(event) => handleEmailChange(event)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Email is required",
                              },
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                title: "lnvalid Email Address",
                              },
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          Zone Head{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div>
                          <Select
                            options={ZoneHeadOption}
                            value={selectedZoneHead}
                            onChange={(e) => handleZoneHead(e)}
                            placeholder="Select Zone Head"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            // controlShouldRenderValue={false}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          State <span className="required text-danger">*</span>
                        </Label>
                        <div
                        //   className={`${
                        //     selectedStates.length === 0
                        //       ? "height-auto"
                        //       : "height-fixed"
                        //   }`}
                        >
                          <Select
                            options={stateOptions}
                            value={selectedStates}
                            onChange={(e) => handleStateChange(e)}
                            placeholder="Select State"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isStateDisabled}
                            isMulti
                            // controlShouldRenderValue={false}
                          />
                          {isStateDisabled === true ? (
                            <span className="text-danger">
                              Please select zone head first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          Pincode{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div
                          className={`${
                            selectedPincodes.length === 0
                              ? "height-auto"
                              : "height-fixed"
                          }`}
                        >
                          <Select
                            isMulti
                            options={pincodeOptions}
                            value={selectedPincodes}
                            onChange={(e) => handlePincodeChange(e)}
                            placeholder="Select Pincode"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isPincodeSelectDisabled}
                            // styles={customStyles}
                          />

                          {isPincodeSelectDisabled === true ? (
                            <span className="text-danger">
                              Please select state first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="">
                        <Label>Pincode</Label>
                      </div>
                      <CreatableSelect
                        inputValue={inputPincode}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(newValue) => setPincode(newValue)}
                        onInputChange={(newValue) => setInputPincode(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type single pincode or type comma separated pincodes and press enter..."
                        value={pincode}
                      />
                      {/* {stateOptions.length === 0 ? null : (
                        <CustomSelect options={stateOptions} />
                      )} */}
                    </div>
                  </div>
                  <div className="row mt-3"></div>
                  <div className="row mt-3 float-end">
                    <div className="col-12">
                      <Button
                        onClick={handleSubmit}
                        className="btn btn-warning mx-3"
                      >
                        Submit
                      </Button>
                      <Button
                        type="submit"
                        onClick={reset}
                        className="btn btn-danger"
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddRegionlHead;
