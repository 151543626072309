import React, { useState } from "react";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const ReportModal = ({
  isOpen,
  toggle,
  modalHeader,
  handleAPI,
  reportName,
  setReportName,
}) => {
  const [reportNameError, setReportNameError] = useState("");

  const handleSubmit = async () => {
    if (!reportName) {
      setReportNameError("Report Name is required.");
    } else {
      setReportNameError("");
      handleAPI(reportName);
      handleClose();
    }
  };
  const handleClose = async () => {
    setReportName("");
    setReportNameError("");
    toggle();
  };

  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
    if (e.target.value.trim() !== "") {
      setReportNameError("");
    } else {
      setReportNameError("Report Name is required.");
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalBody>
          <Row className="mb-4">
            <Col md={3} sm={3} xs={4}>
              <Label for="report_name" className=" col-form-label">
                Report Name
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
              </Label>
            </Col>
            <Col>
              {" "}
              <Input
                type="text"
                placeholder="Enter Report Name"
                id="report_name"
                value={reportName}
                onChange={handleReportNameChange}
              />
              {reportNameError && (
                <div style={{ color: "red" }}>{reportNameError}</div>
              )}
            </Col>
          </Row>

          <ModalFooter>
            <Button
              type="button"
              className="btn-warning"
              onClick={handleSubmit}
            >
              Download
            </Button>
            <Button type="button" color="light" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportModal;
