import { RESET_MASTER, SET_MASTER } from "../actions/actionTypes";

const INIT_STATE = {
  setList: "",
};

const master = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MASTER:
      return {
        ...state,
        setList: action.payload,
      };
    case RESET_MASTER:
      return {
        ...state,
        setList: action.payload,
      };

    default:
      return state;
  }
};

export default master;
