import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const TermCondition = () => {
  return (
    <div>
      <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
        <Row className="justify-content-center">
          <Col lg={12}>
            <div style={{ height: "600px", overflow: "auto" }}>
              <Card>
                <CardHeader>
                  <h3>Terms and Conditions</h3>
                </CardHeader>
                <CardBody>
                  <div>
                    <h5>Effective Date: Jun 01, 2023</h5>
                    <p>
                      Website and Mobile Application Covered: aparpower.com and
                      cableapi.aparpower.com and Apar E-power Application THE
                      AGREEMENT: The use of this Mobile Application and Website
                      and services on this Mobile Application and Website
                      provided by T7E Aftermarket Connect Pvt Ltd (hereinafter
                      referred to as "Owner") are subject to the following Terms
                      & Conditions (hereinafter the "Terms of Service"), all
                      parts and sub-parts of which are specifically incorporated
                      by reference here together with the Privacy Policy.
                      Following are the Terms of Service governing your use of
                      Apar E-power (the "Mobile application and Mobile
                      Application and Website"), all pages on the Mobile
                      Application and Website and any services provided by or on
                      this Mobile Application and Website ("Services"). By
                      accessing either directly or through the Android
                      Playstore, the Mobile application and Website you engage
                      in our "Service" and agree to be bound by the Terms of
                      Service including those additional terms and conditions
                      and policies referenced herein and/or available by
                      hyperlink. These Terms of Service apply to all users of
                      the site, including without limitation vendors, buyers,
                      customers, merchants, browsers and/ or contributors of
                      content. You acknowledge and agree that the Mobile
                      Application and Website may use your personal information
                      in the manner described in our Privacy Policy which sets
                      forth how information collected about you is collected,
                      used and stored.
                    </p>
                    <h5>1.DEFINITIONS</h5>
                    <p>
                      The parties referred to in these Terms of Service shall be
                      defined as follows: Owner, Us, We: The Owner, as the
                      creator, operator, and publisher of the Mobile Application
                      and Website, makes the Mobile Application and Website, and
                      certain Services on it, available to users. T7E
                      Aftermarket Connect Pvt Ltd, Owner, Us, We, Our, Ours and
                      other first-person pronouns will refer to the Owner, as
                      well as all employees and affiliates of the Owner. You,
                      the User, the Client: You, as the user of the Mobile
                      Application and Website, will be referred to throughout
                      these Terms of Service with second-person pronouns such as
                      You, Your, Yours, or as User or Client. For the purpose of
                      these Terms of Service, the term "User" or "you" shall
                      mean any natural or legal person who person is accessing
                      the Mobile Application and Website. The term "Your" shall
                      be construed accordingly. Parties: Collectively, the
                      parties to these Terms of Service (the Owner and You) will
                      be referred to as Parties.
                    </p>
                    <h5>2.ASSENT & ACCEPTANCE </h5>
                    <p>
                      By using the Mobile Application and Website, You warrant
                      that You have read and reviewed these Terms of Service and
                      that You agree to be bound by it. If You do not agree to
                      be bound by these Terms of Service, please leave the
                      Mobile Application and Website immediately. The Owner only
                      agrees to provide use of this Mobile Application and
                      Website and Services to You if You assent to these Terms
                      of Service. Further, based on the Services obtained by a
                      User, additional terms and conditions in respect of the
                      specific Services may apply, which shall be deemed an
                      agreement between the Users and the Owner.
                    </p>
                    <h5>3.AGE RESTRICTION</h5>
                    <p>
                      You must be at least 18 (eighteen) years of age to use
                      this Mobile Application and Website or any Services
                      contained herein. By using this Mobile Application and
                      Website, You represent and warrant that You are at least
                      18 years of age and may legally agree to these Terms of
                      Service. The Owner assumes no responsibility or liability
                      for any misrepresentation of Your age.
                    </p>
                    <h5>4.ABOUT THE SITE</h5>
                    <p>
                      The Mobile Application and Website is an online repository
                      of technical, educational, informative and entertainment
                      content in video, audio or textual format for consumption
                      by auto-service technicians and by any other professional
                      or individual seeking to deal with such persons who are
                      involved in automotive after-sales and service. We reserve
                      the right to refuse service on the Mobile Application and
                      Website at our sole discretion to anyone for any reason at
                      any time. The Mobile Application and Website does not
                      screen or censor the users who register on and access the
                      Mobile Application and Website. You assume all risks
                      associated with dealing with other users with whom you
                      come in contact through the Mobile Application and
                      Website. You agree to use the Mobile Application and
                      Website only for lawful purposes without infringing the
                      rights or restricting the use of this Mobile Application
                      and Website by any third party.
                    </p>
                    <h5>5.LICENSE TO USE MOBILE APPLICATION AND WEBSITE</h5>
                    <p>
                      The Owner may provide You with certain information as a
                      result of Your use of the Mobile Application and Website
                      or Services. Such information may include, but is not
                      limited to, documentation, data, or information developed
                      by the Owner, and other materials which may assist in Your
                      use of the Mobile Application and Website or Services
                      ("Owner Materials"). Subject to these Terms of Service,
                      the Owner grants You a non-exclusive, limited,
                      non-transferable and revocable license to use the Owner
                      Materials solely in connection with Your use of the Mobile
                      Application and Website and Services. The Owner Materials
                      may not be used for any other purpose and this license
                      terminates upon Your cessation of use of the Mobile
                      Application and Website or Services or at the termination
                      of these Terms of Service. You agree not to collect
                      contact information of other Users from the Mobile
                      Application and Website or download or copy any
                      information by means of unsolicited access so as to
                      communicate directly with them or for any reason
                      whatsover. Any unauthorized use by you shall terminate the
                      permission or license granted to you by the Mobile
                      Application and Website and You agree that you shall not
                      bypass any measures used by the Owner to prevent or
                      restrict access to the Mobile Application and Website
                    </p>
                    <h5>6.INTELLECTUAL PROPERTY</h5>
                    <p>
                      You agree that the Mobile Application and Website and all
                      Services provided by the Owner are the property of the
                      Owner, including all copyrights, trademarks, trade
                      secrets, patents and other intellectual property ("Owner
                      IP"). You agree that the Owner owns all right, title and
                      interest in and to the Owner IP and that You will not use
                      the Owner IP for any unlawful or infringing purpose. You
                      agree not to reproduce or distribute the Owner IP in any
                      way, including electronically or via registration of any
                      new trademarks, trade names, service marks or Uniform
                      Resource Locators (URLs), without express written
                      permission from the Owner. In order to make the Mobile
                      Application and Website and Services available to You, You
                      hereby grant the Owner a royalty-free, non-exclusive,
                      worldwide license to copy, display, use, broadcast,
                      transmit and make derivative works of any content You
                      publish, upload or otherwise make available to the Mobile
                      Application and Website ("Your Content"). The Owner claims
                      no further proprietary rights in Your Content. If You feel
                      that any of Your intellectual property rights have been
                      infringed or otherwise violated by the posting of
                      information or media by another of Our users, please
                      contact Us and let Us know.
                    </p>
                    <h5>7.USER OBLIGATIONS</h5>
                    <p>
                      As a user of the Mobile Application and Website or
                      Services, You may be asked to register with Us. When You
                      do so, You will choose a user identifier, which may be
                      Your mobile or handphone number or another term, as well
                      as a password. You may also provide personal information,
                      including, but not limited to, Your name. You are
                      responsible for ensuring the accuracy of this information.
                      This identifying information will enable You to use the
                      Mobile Application and Website and Services. You must not
                      share such identifying information with any third party
                      and if You discover that Your identifying information has
                      been compromised, You agree to notify Us immediately in
                      writing. Email notification will suffice. You are
                      responsible for maintaining the safety and security of
                      Your identifying information as well as keeping Us
                      apprised of any changes to Your identifying information.
                      The billing information You provide us, including credit
                      card, billing address and other payment information, is
                      subject to the same confidentiality and accuracy
                      requirements as the rest of Your identifying information.
                      Providing false or inaccurate information, or using the
                      Mobile Application and Website or Services to further
                      fraud or unlawful activity is grounds for immediate
                      termination of these Terms of Service. The Owner reserves
                      the right to refuse service, terminate accounts, or remove
                      or edit content in its sole discretion.
                    </p>
                  </div>
                  <div>
                    <h5>8.PAYMENT & FEES</h5>
                    <p>
                      Should You register for any of the paid Services on this
                      Mobile Application and Website or purchase any product or
                      service on this Mobile Application and Website, You agree
                      to pay Us the specific monetary amounts required for that
                      product or those Services. These monetary amounts ("Fees")
                      will be described to You during Your account registration
                      and/or confirmation process. The final amount required for
                      payment will be shown to You immediately prior to
                      purchase. We reserve the right to refuse service or refuse
                      to sell the products on the Mobile Application and Website
                      at our sole discretion to anyone for any reason at any
                      time.
                    </p>
                    <h5>9.ACCEPTABLE USE</h5>
                    <p>
                      You agree not to use the Mobile Application and Website or
                      Services for any unlawful purpose or any purpose
                      prohibited under this clause. You agree not to use the
                      Mobile Application and Website or Services in any way that
                      could damage the Mobile Application and Website, Services
                      or general business of the Owner. You further agree not to
                      use the Mobile Application and Website or Services: To
                      harass, abuse, or threaten others or otherwise violate any
                      person's legal rights; To violate any intellectual
                      property rights of the Owner or any third party; To upload
                      or otherwise disseminate any computer viruses or other
                      software that may damage the property of another; To
                      perpetrate any fraud; To engage in or create any unlawful
                      gambling, sweepstakes, or pyramid scheme; To publish or
                      distribute any obscene or defamatory material; To publish
                      or distribute any material that incites violence, hate or
                      discrimination towards any group; To unlawfully gather
                      information about others. You are prohibited from using
                      the site or its content: for any unlawful purpose; to
                      solicit others to perform or participate in any unlawful
                      acts; to infringe on any third party's intellectual
                      property or proprietary rights, or rights of publicity or
                      privacy, whether knowingly or unknowingly; to violate any
                      local, federal or international law, statute, ordinance or
                      regulation; to harass, abuse, insult, harm, defame,
                      slander, disparage, intimidate, or discriminate based on
                      gender, sexual orientation, religion, ethnicity, race,
                      age, national origin, or disability; to submit false or
                      misleading information or any content which is defamatory,
                      libelous, threatening, unlawful, harassing, indecent,
                      abusive, obscene, or lewd and lascivious or pornographic,
                      or exploits minors in any way or assists in human
                      trafficking or content that would violate rights of
                      publicity and/or privacy or that would violate any law; to
                      upload or transmit viruses or any other type of malicious
                      code that will or may be used in any way that will affect
                      the functionality or operation of the Service or of any
                      related Mobile Application and Website, other Mobile
                      Application and Websites, or the Internet; to collect or
                      track the personal information of others; to damage,
                      disable, overburden, or impair the Mobile Application and
                      Website or any other party's use of the Mobile Application
                      and Website; to spam, phish, pharm, pretext, spider,
                      crawl, or scrape; for any obscene or immoral purpose; or
                      to interfere with or circumvent the security features of
                      the Service or any related Mobile Application and Website,
                      other Mobile Application and Websites, or the Internet; to
                      personally threaten or has the effect of personally
                      threatening other Users. We reserve the right to terminate
                      your use of the Service or any related Mobile Application
                      and Website for violating any of the prohibited uses. The
                      Company has the full authority to review all content
                      posted by Users on the Mobile Application and Website. You
                      acknowledge that the Mobile Application and Website is not
                      responsible or liable and does not control the content of
                      any information that may be posted to the Mobile
                      Application and Website by You or other User of the Mobile
                      Application and Website and you are solely responsible for
                      the same. You agree that You shall not upload, post, or
                      transmit any content that you do not have a right to make
                      available (such as, the intellectual property of another
                      party). You agree to comply with all applicable laws,
                      statutes and regulations concerning your use of the Mobile
                      Application and Website and further agree that you will
                      not transmit any information, data, text, files, links,
                      software, chats, communication or other materials that is
                      abusive, invasive of another's privacy, harassing,
                      defamatory, vulgar, obscene, unlawful, false, misleading,
                      harmful, threatening, hateful or racially or otherwise
                      objectionable, including without limitation material of
                      any kind or nature that encourages conduct that could
                      constitute a criminal offense, give rise to civil
                      liability or otherwise violate any applicable local,
                      state, provincial, national, or international law or
                      regulation, or encourage the use of controlled substances.
                      We may, but have no obligation to, monitor, edit or remove
                      content that we determine in our sole discretion are
                      unlawful, offensive, threatening, libelous, defamatory,
                      pornographic, obscene or otherwise objectionable or
                      violates any party's intellectual property or these Terms
                      of Service. You may not use our products for any illegal
                      or unauthorized purpose nor may you, in the use of the
                      Service, violate any laws in your jurisdiction (including
                      but not limited to copyright laws).
                    </p>
                    <h5>10.COMMUNICATION</h5>
                    <p>
                      You understand that each time use the Mobile Application
                      and Website in any manner, you agree to these Terms. By
                      agreeing to these Terms, you acknowledge that you are
                      interested in availing and purchasing the Services that
                      you have selected and consent to receive communications
                      via phone or electronic records from the Mobile
                      Application and Website including e-mail messages telling
                      you about products and services offered by the Mobile
                      Application and Website (or its affiliates and partners)
                      and understanding your requirements. Communication can
                      also be by posting any notices on the Mobile Application
                      and Website. You agree that the communications sent to You
                      by the Mobile Application and Website shall not be
                      construed as spam or bulk under any law prevailing in any
                      country where such communication is received.
                    </p>
                    <h5>11.AFFILIATE MARKETING & ADVERTISING</h5>
                    <p>
                      The Owner, through the Mobile Application and Website and
                      Services, may engage in affiliate marketing whereby the
                      Owner receives a commission on or percentage of the sale
                      of goods or services on or through the Mobile Application
                      and Website. The Owner may also accept advertising and
                      sponsorships from commercial businesses or receive other
                      forms of advertising compensation.
                    </p>
                    <h5>12.PRIVACY INFORMATION</h5>
                    <p>
                      Through Your Use of the Mobile Application and Website and
                      Services, You may provide Us with certain information. By
                      using the Mobile Application and Website or the Services,
                      You authorize the Owner to use Your information in India
                      and any other country where We may operate. Information We
                      May Collect or Receive: When You register for an account,
                      You provide Us with a valid email address and may provide
                      Us with additional information, such as Your name or
                      billing information. Depending on how You use Our Mobile
                      Application and Website or Services, We may also receive
                      information from external applications You use to access
                      Our Mobile Application and Website, or We may receive
                      information through various web technologies, such as
                      cookies, log files, clear gifs, web beacons or others. How
                      We Use Information: We use the information gathered from
                      You to ensure Your continued good experience on Our Mobile
                      Application and Website, including through email
                      communication. We may also track certain of the passive
                      information received to improve Our marketing and
                      analytics, and for this, We may work with third-party
                      providers. How You Can Protect Your Information: If You
                      would like to disable Our access to any passive
                      information We receive from the use of various
                      technologies, You may choose to disable cookies in Your
                      web browser. Please be aware that the Owner will still
                      receive information about You that You have provided, such
                      as Your email address. If You choose to terminate Your
                      account, the Owner will store information about You for
                      the following number of days: 730. After that time, it
                      will be deleted.
                    </p>
                    <h5>13.ASSUMPTION OF RISK</h5>
                    <p>
                      The Mobile Application and Website and Services are
                      provided for communication purposes only. You acknowledge
                      and agree that any information posted on Our Mobile
                      Application and Website is not intended to be legal
                      advice, medical advice, or financial advice, and no
                      fiduciary relationship has been created between You and
                      the Owner. You further agree that Your purchase of any of
                      the products on the Mobile Application and Website is at
                      Your own risk. The Owner does not assume responsibility or
                      liability for any advice or other information given on the
                      Mobile Application and Website.
                    </p>
                    <h5>14.SALE OF GOODS/SERVICES</h5>
                    <p>
                      The Owner may sell goods or services or allow third
                      parties to sell goods or services on the Mobile
                      Application and Website. The Owner undertakes to be as
                      accurate as possible with all information regarding the
                      goods and services, including product descriptions and
                      images. However, the Owner does not guarantee the accuracy
                      or reliability of any product information and You
                      acknowledge and agree that You purchase such products at
                      Your own risk. We will make reimbursements for returns
                      without undue delay, and not later than: 30 days after the
                      day we received back from you any goods supplied; or (if
                      earlier) 30 days after the day you provide evidence that
                      you have returned the goods; or if there were no goods
                      supplied, 30 days after the day on which we are informed
                      about your decision to cancel this contract. We will make
                      the reimbursement using the same means of payment as you
                      used for the initial transaction, unless you have
                      expressly agreed otherwise; in any event, you will not
                      incur any fees as a result of the reimbursement.
                    </p>
                    <h5>15.REVERSE ENGINEERING & SECURITY</h5>
                    <p>
                      You agree not to undertake any of the following actions:
                      Reverse engineer, or attempt to reverse engineer or
                      disassemble any code or software from or on the Mobile
                      Application and Website or Services; Violate the security
                      of the Mobile Application and Website or Services through
                      any unauthorized access, circumvention of encryption or
                      other security tools, data mining or interference to any
                      host, user or network.
                    </p>
                    <h5>16.DATA LOSS </h5>
                    <p>
                      The Owner does not accept responsibility for the security
                      of Your account or content. You agree that Your use of the
                      Mobile Application and Website or Services is at Your own
                      risk.
                    </p>
                    <h5>17.INDEMNIFICATION</h5>
                    <p>
                      You agree to defend and indemnify the Owner and any of its
                      affiliates (if applicable) and hold Us harmless against
                      any and all legal claims and demands, including reasonable
                      attorney's fees, which may arise from or relate to Your
                      use or misuse of the Mobile Application and Website or
                      Services, Your breach of these Terms of Service, or Your
                      conduct or actions. You agree that the Owner shall be able
                      to select its own legal counsel and may participate in its
                      own defense, if the Owner wishes.
                    </p>
                    <h5>18.SPAM POLICY</h5>
                    <p>
                      You are strictly prohibited from using the Mobile
                      Application and Website or any of the Owner's Services for
                      illegal spam activities, including gathering email
                      addresses and personal information from others or sending
                      any mass commercial emails.
                    </p>
                    <h5>19.THIRD-PARTY LINKS & CONTENT</h5>
                    <p>
                      The Owner may occasionally post links to third party
                      Mobile Application and Websites or other services. You
                      agree that the Owner is not responsible or liable for any
                      loss or damage caused as a result of Your use of any third
                      party services linked to from Our Mobile Application and
                      Website.
                    </p>
                    <h5>20.MODIFICATION & VARIATION</h5>
                    <p>
                      The Owner may, from time to time and at any time without
                      notice to You, modify these Terms of Service. You agree
                      that the Owner has the right to modify these Terms of
                      Service or revise anything contained herein. You further
                      agree that all modifications to these Terms of Service are
                      in full force and effect immediately upon posting on the
                      Mobile Application and Website and that modifications or
                      variations will replace any prior version of these Terms
                      of Service, unless prior versions are specifically
                      referred to or incorporated into the latest modification
                      or variation of these Terms of Service. To the extent any
                      part or sub-part of these Terms of Service is held
                      ineffective or invalid by any court of law, You agree that
                      the prior, effective version of these Terms of Service
                      shall be considered enforceable and valid to the fullest
                      extent. You agree to routinely monitor these Terms of
                      Service and refer to the Effective Date posted at the top
                      of these Terms of Service to note modifications or
                      variations. You further agree to clear Your cache when
                      doing so to avoid accessing a prior version of these Terms
                      of Service. You agree that Your continued use of the
                      Mobile Application and Website after any modifications to
                      these Terms of Service is a manifestation of Your
                      continued assent to these Terms of Service. In the event
                      that You fail to monitor any modifications to or
                      variations of these Terms of Service, You agree that such
                      failure shall be considered an affirmative waiver of Your
                      right to review the modified Agreement. 21) ENTIRE
                      AGREEMENT This Agreement constitutes the entire
                      understanding between the Parties with respect to any and
                      all use of this Mobile Application and Website. This
                      Agreement supersedes and replaces all prior or
                      contemporaneous agreements or understandings, written or
                      oral, regarding the use of this Mobile Application and
                      Website.
                    </p>
                    <h5>22.SERVICE INTERRUPTIONS</h5>
                    <p>
                      The Owner may need to interrupt Your access to the Mobile
                      Application and Website to perform maintenance or
                      emergency services on a scheduled or unscheduled basis.
                      You agree that Your access to the Mobile Application and
                      Website may be affected by unanticipated or unscheduled
                      downtime, for any reason, but that the Owner shall have no
                      liability for any damage or loss caused as a result of
                      such downtime.
                    </p>
                    <h5>23.TERM, TERMINATION & SUSPENSION</h5>
                    <p>
                      The Owner may terminate these Terms of Service with You at
                      any time for any reason, with or without cause. The Owner
                      specifically reserves the right to terminate these Terms
                      of Service if You violate any of the terms outlined
                      herein, including, but not limited to, violating the
                      intellectual property rights of the Owner or a third
                      party, failing to comply with applicable laws or other
                      legal obligations, and/or publishing or distributing
                      illegal material. If You have registered for an account
                      with Us, You may also terminate these Terms of Service at
                      any time by contacting Us and requesting termination.
                      Please keep in mind that any outstanding fees will still
                      be due even after termination of Your account. At the
                      termination of these Terms of Service, any provisions that
                      would be expected to survive termination by their nature
                      shall remain in full force and effect.
                    </p>
                    <h5>24.NO WARRANTIES</h5>
                    <p>
                      You agree that Your use of the Mobile Application and
                      Website and Services is at Your sole and exclusive risk
                      and that any Services provided by Us are on an "As Is"
                      basis. The Owner hereby expressly disclaims any and all
                      express or implied warranties of any kind, including, but
                      not limited to the implied warranty of fitness for a
                      particular purpose and the implied warranty of
                      merchantability. The Owner makes no warranties that the
                      Mobile Application and Website or Services will meet Your
                      needs or that the Mobile Application and Website or
                      Services will be uninterrupted, error-free, or secure. The
                      Owner also makes no warranties as to the reliability or
                      accuracy of any information on the Mobile Application and
                      Website or obtained through the Services. You agree that
                      any damage that may occur to You, through Your computer
                      system, or as a result of loss of Your data from Your use
                      of the Mobile Application and Website or Services is Your
                      sole responsibility and that the Owner is not liable for
                      any such damage or loss. All information, software,
                      products, services and related graphics are provided on
                      this site is "as is" and "as available" basis with without
                      warranty of any kind, either expressed or implied. The
                      Mobile Application and Website disclaims all warranties,
                      expressed or implied including, without limitation, all
                      implied warranties of merchantability, fitness for a
                      particular purpose, title and non-infringement or arising
                      from a course of dealing, usage, or trade practice.
                      Company makes no representation about the suitability of
                      the information, software, products, and services
                      contained on this Mobile Application and Website for any
                      purpose, and the inclusion or offering of any products or
                      services on this Mobile Application and Website does not
                      constitute any endorsement or recommendation of such
                      products or services. The Mobile Application and Website
                      makes no warranty that the use of the Mobile Application
                      and Website will be uninterrupted, timely, secure, without
                      defect or error free. You expressly agree that use of the
                      site is at your own risk. The Mobile Application and
                      Website shall not be responsible for any content found on
                      the Mobile Application and Website. Your use of any
                      information or materials on this site or otherwise
                      obtained through use of this Mobile Application and
                      Website is entirely at your own risk, for which we shall
                      not be liable. It shall be your own responsibility to
                      ensure that any products, services or information
                      available through this Mobile Application and Website meet
                      your specific requirements. The Mobile Application and
                      Website assumes no responsibility for the accuracy,
                      currency, completeness or usefulness of information,
                      views, opinions or advice in any material contained on the
                      Mobile Application and Website. Any information of third
                      parties or advertisers is made available without doing any
                      changes and so the Mobile Application and Website cannot
                      guarantee accuracy and is not liable to any
                      inconsistencies arising thereof. All postings, messages,
                      advertisements, photos, sounds, images, text, files, video
                      or other materials posted on, transmitted through, or
                      linked from the Mobile Application and Website, are solely
                      the responsibility of the person from whom such Content
                      originated and the Mobile Application and Website does not
                      control, and is not responsible for Content available on
                      the Mobile Application and Website. There may be instances
                      when incorrect information is published inadvertently on
                      our Mobile Application and Website or in the Service such
                      as typographical errors, inaccuracies or omissions that
                      may relate to product descriptions, pricing, promotions,
                      offers, product shipping charges, transit times and
                      availability. Any errors, inaccuracies or omissions, may
                      be corrected at our discretion at any time and we may
                      change or update information or cancel orders if any
                      information in the Service or on any related Mobile
                      Application and Website is inaccurate at any time without
                      prior notice (including after you have submitted your
                      order). We undertake no obligation to update, amend or
                      clarify information in the Service or on any related
                      Mobile Application and Website, including without
                      limitation, pricing information, except as required by
                      law. No specified update or refresh date applied in the
                      Service or on any related Mobile Application and Website,
                      should be taken to indicate that all information in the
                      Service or on any related Mobile Application and Website
                      has been modified or updated. The Mobile Application and
                      Website shall not be responsible for any interaction
                      between you and the other users of the Mobile Application
                      and Website. Under no circumstances will the Mobile
                      Application and Website be liable for any goods, services,
                      resources or content available through such third party
                      dealings or communications, or for any harm related
                      thereto. The Mobile Application and Website is under no
                      obligation to become involved in any disputes between you
                      and other users of the Mobile Application and Website or
                      between you and any other third parties. You agree to
                      release the Mobile Application and Website from any and
                      all claims, demands, and damages arising out of or in
                      connection with such dispute. You agree and understand
                      that while the Mobile Application and Website has made
                      reasonable efforts to safeguard the Mobile Application and
                      Website, it cannot and does not ensure or make any
                      representations that the Mobile Application and Website or
                      any of the information provided by You cannot be hacked by
                      any unauthorised third parties. You specifically agree
                      that the Mobile Application and Website shall not be
                      responsible for unauthorized access to or alteration of
                      Your transmissions or data, any material or data sent or
                      received or not sent or received, or any transactions
                      entered into through the Mobile Application and Website.
                      You hereby agree and confirm that the Mobile Application
                      and Website shall not be held liable or responsible in any
                      manner whatsoever for such hacking or any loss or damages
                      suffered by you due to unauthorized access of the Mobile
                      Application and Website by third parties or for any such
                      use of the information provided by You or any spam
                      messages or information that You may receive from any such
                      unauthorised third party (including those which are
                      although sent representing the name of the Mobile
                      Application and Website but have not been authorized by
                      the Mobile Application and Website) which is in violation
                      or contravention of this Terms of Service or the Privacy
                      Policy. You specifically agree that the Mobile Application
                      and Website is not responsible or liable for any
                      threatening, defamatory, obscene, offensive or illegal
                      content or conduct of any other party or any infringement
                      of another's rights, including intellectual property
                      rights. You specifically agree that the Mobile Application
                      and Website is not responsible for any content sent using
                      and/or included in the Mobile Application and Website by
                      any third party. The Mobile Application and Website has no
                      liability and will make no refund in the event of any
                      delay, cancellation, strike, force majeure or other causes
                      beyond their direct control, and they have no
                      responsibility for any additional expense omissions delays
                      or acts of any government or authority. You will be solely
                      responsible for any damages to your computer system or
                      loss of data that results from the download of any
                      information and/or material. Some jurisdictions do not
                      allow the exclusion of certain warranties, so some of the
                      above exclusions may not apply to you. In no event shall
                      the Mobile Application and Website be liable for any
                      direct, indirect, punitive, incidental, special,
                      consequential damages or any damages whatsoever including,
                      without limitation, damages for loss of use, data or
                      profits, arising out of or in any way connected with the
                      use or performance of the site, with the delay or
                      inability to use the site or related services, the
                      provision of or failure to provide Services, or to deliver
                      the products or for any information, software, products,
                      services and related graphics obtained through the site,
                      or any interaction between you and other participants of
                      the Mobile Application and Website or otherwise arising
                      out of the use of the Mobile Application and Website,
                      damages resulting from use of or reliance on the
                      information present, whether based on contract, tort,
                      negligence, strict liability or otherwise, even if the
                      Mobile Application and Website or any of its
                      affiliates/suppliers has been advised of the possibility
                      of damages. If, despite the limitation above, the Company
                      is found liable for any loss or damage which arises out of
                      or in any way connected with the use of the Mobile
                      Application and Website and/ or provision of Services,
                      then the liability of the Company will in no event exceed,
                      50% (Fifty percent) of the amount you paid to the Company
                      in connection with such transaction(s) on this Mobile
                      Application and Website. You accept all responsibility
                      for, and hereby agree to indemnify and hold harmless the
                      company from and against, any actions taken by you or by
                      any person authorized to use your account, including
                      without limitation, disclosure of passwords to third
                      parties. By using the Mobile Application and Website, you
                      agree to defend, indemnify and hold harmless the
                      indemnified parties from any and all liability regarding
                      your use of the site or participation in any site's
                      activities. If you are dissatisfied with the Mobile
                      Application and Website, or the Services or any portion
                      thereof, or do not agree with these terms, your only
                      recourse and exclusive remedy shall be to stop using the
                      site.
                    </p>
                    <h5>25.LIMITATION ON LIABILITY</h5>
                    <p>
                      The Owner is not liable for any damages that may occur to
                      You as a result of Your use of the Mobile Application and
                      Website or Services, to the fullest extent permitted by
                      law. The maximum liability of the Owner arising from or
                      relating to these Terms of Service is limited to the
                      lesser of Rs. 1000 (Rupees One Thousand only) or the
                      amount You paid to the Owner in the last six (6) months.
                      This section applies to any and all claims by You,
                      including, but not limited to, lost profits or revenues,
                      consequential or punitive damages, negligence, strict
                      liability, fraud, or torts of any kind.
                    </p>
                    <h5>26.GENERAL PROVISIONS:</h5>
                    <p>
                      a) LANGUAGE: All communications made or notices given
                      pursuant to these Terms of Service shall be in Hindi
                      language. b) JURISDICTION, VENUE & GOVERNING LAW: Through
                      Your use of the Mobile Application and Website or
                      Services, You agree that the laws of the India shall
                      govern any matter or dispute relating to or arising out of
                      these Terms of Service, as well as any dispute of any kind
                      that may arise between You and the Owner, with the
                      exception of its conflict of law provisions. In case any
                      litigation specifically permitted under these Terms of
                      Service is initiated, the Parties agree to submit to the
                      exclusice jurisdiction of the courts at Mumbai, India. The
                      Parties agree that this choice of law, venue, and
                      jurisdiction provision is not permissive, but rather
                      mandatory in nature. You hereby waive the right to any
                      objection of venue, including assertion of the doctrine of
                      forum non conveniens or similar doctrine. c) ASSIGNMENT:
                      This Agreement, or the rights granted hereunder, may not
                      be assigned, sold, leased or otherwise transferred in
                      whole or part by You. Should these Terms of Service, or
                      the rights granted hereunder, by assigned, sold, leased or
                      otherwise transferred by the Owner, the rights and
                      liabilities of the Owner will bind and inure to any
                      assignees, administrators, successors and executors. d)
                      SEVERABILITY: If any part or sub-part of these Terms of
                      Service is held invalid or unenforceable by a court of law
                      or competent arbitrator, the remaining parts and sub-parts
                      will be enforced to the maximum extent possible. In such
                      condition, the remainder of these Terms of Service shall
                      continue in full force. e) NO WAIVER: In the event that We
                      fail to enforce any provision of these Terms of Service,
                      this shall not constitute a waiver of any future
                      enforcement of that provision or of any other provision.
                      Waiver of any part or sub-part of these Terms of Service
                      will not constitute a waiver of any other part or
                      sub-part. f) HEADINGS FOR CONVENIENCE ONLY: Headings of
                      parts and sub-parts under these Terms of Service are for
                      convenience and organization, only. Headings shall not
                      affect the meaning of any provisions of these Terms of
                      Service. g) NO AGENCY, PARTNERSHIP OR JOINT VENTURE: No
                      agency, partnership, or joint venture has been created
                      between the Parties as a result of these Terms of Service.
                      No Party has any authority to bind the other to third
                      parties. h) FORCE MAJEURE: The Owner is not liable for any
                      failure to perform due to causes beyond its reasonable
                      control including, but not limited to, acts of God, acts
                      of civil authorities, acts of military authorities, riots,
                      embargoes, acts of nature and natural disasters, and other
                      acts which may be due to unforeseen circumstances. i)
                      ELECTRONIC COMMUNICATIONS PERMITTED: Electronic
                      communications are permitted to both Parties under these
                      Terms of Service, including e-mail or fax. For any
                      questions or concerns, please email Us at the following
                      address: techsupport@t7e.in.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TermCondition;
