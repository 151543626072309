import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  ZONE_HEAD,
  REGIONAL_HEAD,
  STATE_HEAD,
  SALES_OFFICE,
  SET_MASTER,
  RESET_MASTER,
} from "./actionTypes";

import masterService from "../services/master.service";

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width, layoutType) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

export const changeSidebarTheme = (theme, layoutType) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: { theme, layoutType },
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

const zoneHead = async (user_ids) => {
  const response = await masterService.GetMasterList(user_ids);
  if (response.status === true) {
    //   console.log(response.data.Role_id)
    return {
      type: ZONE_HEAD,
      payload: { zoneList: response.data },
    };
  }
};

const regionalHead = async (user_ids) => {
  const response = await masterService.GetMasterList(user_ids);
  if (response.status === true) {
    //   console.log(response.data.Role_id)
    return {
      type: REGIONAL_HEAD,
      payload: { regionalList: response.data },
    };
  }
};

const stateHead = async (user_ids) => {
  const response = await masterService.GetMasterList(user_ids);
  if (response.status === true) {
    //   console.log(response.data.Role_id)
    return {
      type: STATE_HEAD,
      payload: { stateHeadList: response.data },
    };
  }
};

const salesOfficer = async (user_ids) => {
  const response = await masterService.GetMasterList(user_ids);
  if (response.status === true) {
    //   console.log(response.data.Role_id)
    return {
      type: SALES_OFFICE,
      payload: { SOList: response.data },
    };
  }
};

const setMaster = (body) => {
  return {
    type: SET_MASTER,
    payload: { body },
  };
};

const resetMaster = () => {
  return {
    type: RESET_MASTER,
    payload: {},
  };
};

export default {
  zoneHead,
  stateHead,
  salesOfficer,
  regionalHead,
  setMaster,
  resetMaster,
};
