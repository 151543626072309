import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/NewLogin";
import Logout from "../pages/Authentication/Logout";

import Segment from "../pages/Segment/Segment";
import Error404 from "../pages/utility/Error404";
import Error500 from "../pages/utility/Error500";
import Redemption from "../pages/Redemption/Redemption";
import Profile from "../pages/Profile";

import viewTransaction from "../pages/ViewTransaction/ViewTransactions";
import ZoneList from "../pages/Master/Zone/ZoneList";
import RegionalList from "../pages/Master/Reginal/RegionalList";
import StateHeadList from "../pages/Master/State/StateList";
import SOList from "../pages/Master/Sales/SOList";
import EditSO from "../pages/Master/Sales/EditSO";
import EditStateHead from "../pages/Master/State/EditStateHead";
import EditZoneHead from "../pages/Master/Zone/EditZoneHead";
import AddZoneHead from "../pages/Master/Zone/AddZoneHead";
import AddSO from "../pages/Master/Sales/AddSO";
import AddStateHead from "../pages/Master/State/AddStateHead";
import EditRegionalHead from "../pages/Master/Reginal/EditRegional";
import eTransfer from "../pages/Etransfer/Etransfer";
import viewExpiry from "../pages/ViewExpiry/ViewExpiry";
import AddRegionalHead from "../pages/Master/Reginal/AddRegional";
import ElectricicanDashboard from "../pages/Dashboard/index";
import RetailerDashboard from "../pages/Dashboard/retailer";
import ViewRedemption from "../pages/Redemption/ViewRedemption";
import UserActivity from "../pages/UserActivity";
import Electrcian from "../pages/Electrician/Electrician";
import ViewRetailer from "../pages/Retailer/Retailer";
import { support } from "jquery";
import Support from "../pages/Support/Support";
import TermCondition from "../pages/T&C/TermCondition";
import UploadPage from '../pages/Upload/UploadPage';
import Catalogue from "../pages/Catalogue/Catalogue";


const authProtectedRoutes = [
  { path: "/zone-list", component: ZoneList },
  { path: "/regional-list", component: RegionalList },
  { path: "/state-head-list", component: StateHeadList },
  { path: "/sales-officer-list", component: SOList },

  { path: "/edit-zone-head/:user_id", component: EditZoneHead },
  { path: "/edit-regional-head/:user_id", component: EditRegionalHead },
  { path: "/edit-state-head/:user_id", component: EditStateHead },
  { path: "/edit-sales-officer/:user_id", component: EditSO },

  { path: "/add-zone-head", component: AddZoneHead },
  { path: "/add-regional-head", component: AddRegionalHead },
  { path: "/add-state-head", component: AddStateHead },
  { path: "/add-sales-officer", component: AddSO },

  { path: "/view-transaction", component: viewTransaction },
  { path: "/e-transfer", component: eTransfer },
  { path: "/view-expiry", component: viewExpiry },

  { path: "/redemption", component: Redemption },
  // { path: "/view-redemption", component: ViewRedemption },
  { path: "/view-redemption", component: ViewRedemption },
  { path: "/segment", component: Segment },
  { path: "/profile", component: Profile },
  { path: "/electric-dashboard", component: ElectricicanDashboard },
  { path: "/retailer-dashboard", component: RetailerDashboard },
  { path: "/user-activity", component: UserActivity },
  { path: "/view-electrician", component: Electrcian },
  { path: "/view-retailer", component: ViewRetailer },
  { path: "/upload", component: UploadPage },
  { path: "/reward-catalogue", component: Catalogue },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/electric-dashboard" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
  { path: "/term&condition_apar_power", component: TermCondition },
  { path: "/term&condition_apar_epower", component: TermCondition },
  { path: "/support", component: Support },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login },
  // { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },
];

export { authProtectedRoutes, publicRoutes };
