import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState, KeyboardEventHandler } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../services/salesOfficer.service";
import Select from "react-select";
import "./Zone.css";

const AddZoneHead = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [selectedPincodes, setSelectedPincodes] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [isDistrictSelectDisabled, setIsDistrictSelectDisabled] =
    useState(true);

  const [isPincodeSelectDisabled, setIsPincodeDistrictSelectDisabled] =
    useState(true);

  const formData = {
    name: name,
    mobile: mobile,
    email: email,
    state: selectedStates,
    area: selectedAreas,
    district: selectedDistricts,
    pincode: selectedPincodes,
  };

  const handleMobileChange = (event) => {
    const value = event?.target.value;
    if (value) setMobile(value);
  };

  const handleEmailChange = (event) => {
    const value = event?.target.value;
    if (value) setEmail(value);
  };

  const checkMobileUniqueness = async () => {
    if (mobile) {
      const mobileData = await service.checkMobileUnique({ mobile: mobile });
      if (mobileData.code === 200) {
        setMobileError("");
      } else {
        setMobileError(mobileData.message);
        toast.error(mobileData.message);
      }
    } else {
      toast.error("mobile is required.");
    }
  };

  const checkEmailUniqueness = async () => {
    if (email) {
      const emailData = await service.checkEmailUnique({ email_id: email });
      if (emailData.code === 200) {
        setEmailError("");
      } else {
        setEmailError(emailData.message);
        toast.error(emailData.message);
      }
    } else {
      toast.error("Email is required.");
    }
  };

  const handleStateChange = async (selectedOption) => {
    setSelectedStates(selectedOption);
    setIsDistrictSelectDisabled(false);
    setIsPincodeDistrictSelectDisabled(false);
    const state_ids = selectedOption?.map((state) => state.id);
    await fetchDistricts(state_ids);
    const district_ids = selectedDistricts?.map(
      (district) => district.district_id
    );
    await fetchPincodes(district_ids, state_ids);
    await fetchAreas(district_ids, state_ids);
    if (selectedOption.length === 0) {
      setDistrictOptions([]);
      setPincodeOptions([]);
      setSelectedPincodes([]);
      setAreaOptions([]);
      setSelectedAreas([]);
      setSelectedDistricts([]);
      setIsDistrictSelectDisabled(true);
      setIsPincodeDistrictSelectDisabled(true);
    }
  };

  const handleDistrictChange = async (selectedOption) => {
    setSelectedDistricts(selectedOption);
    const district_ids = selectedOption?.map(
      (district) => district.district_id
    );
    const state_ids = selectedStates?.map((state) => state.id);
    await fetchPincodes(district_ids, state_ids);
    await fetchAreas(district_ids, state_ids);
  };

  const handlePincodeChange = (selectedOption) => {
    setSelectedPincodes(selectedOption);
  };

  const handleAreaChange = (selectedOption) => {
    setSelectedAreas(selectedOption);
  };

  const handleSubmit = async (event, errors, values) => {};

  const fetchStates = async () => {
    const stateparams = {
      active_zone_head_id: "",
      active_regional_head_id: "",
      active_state_head_id: "",
    };

    const stateData = await service.getStates(stateparams);
    const options = stateData?.data?.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setStateOptions(options);
  };

  const fetchDistricts = async (stateIds) => {
    const params = {
      state_id: stateIds?.length > 0 ? stateIds : [],
    };
    const districtData = await service.getDistricts(params);
    const options = districtData.data.map((district) => ({
      district_id: district.id,
      value: district.name,
      label: district.name,
    }));

    setDistrictOptions(options);
    setSelectedDistricts(options);
    //   toast.error("please select state first. ");
  };

  const fetchPincodes = async (districtIds, stateIds) => {
    const params = {
      state_id: stateIds?.length > 0 ? stateIds : [],
      district_id: districtIds?.length > 0 ? districtIds : [],
    };
    const pinData = await service.getPincodes(params);
    if (pinData?.status === true) {
      const options = pinData?.data.map((pin) => ({
        value: pin.pincode,
        label: pin.pincode,
      }));
      setPincodeOptions(options);
      setSelectedPincodes(options);
    } else {
      toast.error("No pincode Found");
    }
  };

  const fetchAreas = async (district_ids, state_ids) => {
    const params = {
      state_id: state_ids,
      district_id: district_ids,
    };
    if (state_ids.length !== 0 && district_ids.length !== 0) {
      const cityData = await service.getCities1(params);
      const options = cityData?.data.map((city) => ({
        value: city.name,
        label: city.name,
      }));

      setAreaOptions(options);
      setSelectedAreas(options);
    } else {
      //   toast.error("please select state and district first. ");
    }
  };

  const reset = () => {
    setDistrictOptions([]);
    setPincodeOptions([]);
    setSelectedPincodes([]);
    setName("");
    setMobile("");
    setEmail("");
    setSelectedStates([]);
  };

  useEffect(() => {
    if (selectedDistricts || selectedStates) {
      fetchAreas(selectedDistricts, selectedStates);
    }
  }, [selectedDistricts, selectedStates]);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    let timer;
    if (mobileError === null && mobile.length === 10) {
      timer = setTimeout(() => {
        checkMobileUniqueness();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [mobileError, mobile]);

  useEffect(() => {
    let timer;
    if (emailError === null && email.length > 0) {
      timer = setTimeout(() => {
        checkEmailUniqueness();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [emailError, email]);

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="pb-4">Add-ZoneHead</div>
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <Label>
                        Name <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvField
                            name="name"
                            // label="Name"
                            type="text"
                            value={name}
                            placeholder="Enter Name"
                            onChange={(event) => setName(event.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Name is required",
                              },
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </div>
                    <div className="col-md-4">
                      <Label>
                        Mobile Number{" "}
                        <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvForm>
                            <AvField
                              name={"mobile"}
                              //   label="Mobile Number"
                              type="text"
                              value={mobile}
                              placeholder="Enter Mobile Number"
                              onChange={(event) => handleMobileChange(event)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Mobile number is required",
                                },
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  errorMessage: mobileError,
                                },
                              }}
                            />
                          </AvForm>
                        </FormGroup>
                      </AvForm>
                    </div>
                    <div className="col-md-4">
                      <Label>
                        Email <span className="required text-danger">*</span>
                      </Label>
                      <AvForm className="">
                        <FormGroup className="">
                          <AvField
                            name="email"
                            // label="email"
                            type="text"
                            value={email}
                            placeholder="Enter email"
                            onChange={(event) => handleEmailChange(event)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Email is required",
                              },
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                title: "lnvalid Email Address",
                              },
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          State <span className="required text-danger">*</span>
                        </Label>
                        <div
                        //   className={`${
                        //     selectedStates.length === 0
                        //       ? "height-auto"
                        //       : "height-fixed"
                        //   }`}
                        >
                          <Select
                            isMulti
                            options={stateOptions}
                            value={undefined}
                            onChange={(e) => handleStateChange(e)}
                            placeholder="Select State"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            // controlShouldRenderValue={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          District{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div
                          className={`${
                            selectedDistricts.length === 0
                              ? "height-auto"
                              : "height-fixed"
                          }`}
                        >
                          <Select
                            isMulti
                            options={districtOptions}
                            value={selectedDistricts}
                            onChange={(e) => handleDistrictChange(e)}
                            placeholder="Select District"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isDistrictSelectDisabled}
                          />
                          {isDistrictSelectDisabled === true ? (
                            <span className="text-danger">
                              Please select state first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="">
                        <Label>
                          Pincode{" "}
                          <span className="required text-danger">*</span>
                        </Label>
                        <div
                          className={`${
                            selectedPincodes.length === 0
                              ? "height-auto"
                              : "height-fixed"
                          }`}
                        >
                          <Select
                            isMulti
                            options={pincodeOptions}
                            value={selectedPincodes}
                            onChange={(e) => handlePincodeChange(e)}
                            placeholder="Select Pincode"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                            isRequired
                            isDisabled={isPincodeSelectDisabled}
                            // styles={customStyles}
                          />

                          {isPincodeSelectDisabled === true ? (
                            <span className="text-danger">
                              Please select state first{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="">
                        <Label>Area</Label>
                        <div
                          className={`${
                            selectedAreas?.length === 0
                              ? "height-auto"
                              : "height-fixed"
                          }`}
                        >
                          <Select
                            isMulti
                            options={areaOptions}
                            value={selectedAreas}
                            onChange={(e) => handleAreaChange(e)}
                            placeholderButton="colors"
                            placeholder="Select Area"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 float-end">
                    <div className="col-12">
                      <Button
                        onClick={handleSubmit}
                        className="btn btn-warning mx-3"
                      >
                        Submit
                      </Button>
                      <Button
                        type="submit"
                        onClick={reset}
                        className="btn btn-danger"
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddZoneHead;
